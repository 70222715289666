import React, { Component } from 'react';
import http from '../../service/httpService';
import auth from '../../service/authService';
import { UserProfile } from '../../service/api';
import ErrorMoal from '../modal/error-model';
import SuccessMoal from '../modal/success-model';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { setcustomerId }  from '../../utils/appConstants';
import { setbasecountryId }  from '../../utils/appConstants';
import { setaccessCountryId } from '../../utils/appConstants';
import csvExUnitRedirect from '../../utils/csvExUnitRedirect';
import { FormattedMessage } from 'react-intl';
import { readArchivesFromCompressedResponse } from '../../utils/helpers';

class VerifyOTP extends Component {
	constructor(props) {
		super(props);
		this.state = {
			OTP: '',
			isVerified: false,
			errors: {
				message: ''
			},
			error_modal:{
				open:false,
				message:""
			},
			success_modal: {
				open:false,
				message:""
			},
			loaderIconVisible:false,
			userData: {},
			navigateRoute:''
		};
		this.handleOTPChange = this.handleOTPChange.bind(this);
		this.handleSignInSubmitAction = this.handleSignInSubmitAction.bind(this);
		this.onSigninclickHandler = this.onSigninclickHandler.bind(this);
		this._reCaptchaRef = React.createRef();
		this.verifyCallback = this.verifyCallback.bind(this);
	}

	componentWillMount = () =>{
		document.body.style.backgroundColor = "#0d3968";
		$("body").addClass("loginBg");
		this.updateLoginDimensions(); 
	}
	updateLoginDimensions() {
		var windowHeight = $(window).height(); 
		var welcomTextH = $(".welcomText").height();
		var bgAdminH = $(".bgAdmin").height() - 25; 
		//Sign In & Sign Up Modal
		var welcomTextHbgAdminH = ((welcomTextH) + (bgAdminH)); 
		var loginScrollbarHeightS = ((windowHeight) -(welcomTextHbgAdminH));   
		$(".LoginBtnWrapper").css("max-height", loginScrollbarHeightS); 
	}
	setLoader = (status) => {
		this.setState({
			loaderIconVisible: status
		});
	}

	handleOTPChange(event) {
		this.setState({ OTP: event.target.value });
		event.preventDefault();
	}

	handleSignInSubmitAction(event) {
		console.log('Your OTP is: ' + this.state.OTP);
		event.preventDefault();
	}

	nextPath(path) {
		$('.modal-backdrop').hide();
		this.props.history.push(path);
	}

	OTPblurevent = (event) => {
		let inputdataemailobj = event.target.value;
		var pattern = new RegExp(/^[0-9]*$/i);
		if(!pattern.test(inputdataemailobj)) {			
			this.setState({
				errors: {
					message: 'Please enter valid OTP.'										
				}
			})												
		} 
		return false;	
	}

	validateForm() {
		this.setLoader(true);

		let fields = this.state;
		let errors = {};
		let formIsValid = true;
  		  
		if (!fields["OTP"]) {			
		  formIsValid = false;
		  errors["OTP"] = "Please enter your OTP.";
		}
  
		if (typeof fields["OTP"] !== "undefined") {
		  //regular expression for OTP validation
		  var pattern = new RegExp(/^[0-9]*$/i);
		  if (!pattern.test(fields["OTP"])) {
			formIsValid = false;
			errors["OTP"] = "Please enter valid OTP.";
		  }
		}
      
		this.setState({
		  errors: errors
		});
		return formIsValid;
	}

	onSigninclickHandler(e) {
		if (this.validateForm()) {
			//Sample Data - Need to be changed when we integrate API
			let postData ={
				username: 'sivakumarpu@hcl.com',
				password: 'Hcl@2019',
			}

		return new Promise((resolve, reject)=>{
			auth.login(postData, false).then(res => {		  
				let { status } = res;
				if(status === 200) { 	 
					this.setState({
						isFormValidated: true,
						loaderIconVisible: false,
						success_modal:{
							open:false,
							message: `You have successfully logged in "${postData.username}".`
						}
					}); 					
					localStorage.setItem("customerId", res.data.customerId);
					localStorage.setItem("basecountry", res.data.basecountry);
					localStorage.setItem("accesscountry", res.data.accesscountry);
					setcustomerId(res.data.customerId ? res.data.customerId : 1);
					setbasecountryId(res.data.basecountry ? res.data.basecountry : "");
					setaccessCountryId(res.data.accesscountry ? res.data.accesscountry : "");					
					localStorage.setItem("user", postData.username);
					this.getCustomerID(res.data.customerId, postData.username);
				}
				else{
					reject({ res : {
						data : res.data
					}});					 					 			
				}
				}).catch(err=>{
					reject(err);
					this.setLoader(false);
				});
			});  
		}
		else {
			this.setState({
				loaderIconVisible: false,
				errors:{
					message: 'Please enter valid OTP'
				}
			});
		} 
	}

	getCustomerID = (customerID, emailID) => {
		let custGroupIDList = "";
		var setcustGroupIDList="";
		 http({
			method: "GET",			
			url: `${UserProfile.get_Cust_GroupID}`,	
			headers: {			
				'customer_id': customerID,
				'email': emailID,	
				'Content-Type': 'application/json',
			},data:{},
		}).then(response => {
			custGroupIDList=response.data.data;
			custGroupIDList.forEach(p => {
				setcustGroupIDList?setcustGroupIDList=setcustGroupIDList + "," + p.customer_group_id : setcustGroupIDList=p.customer_group_id			
			});

			localStorage.setItem("custGroupID", setcustGroupIDList);
			this.getUserStartLevelID(setcustGroupIDList);
			let accessFeatures = JSON.parse(localStorage.getItem("allFeatures"));
			if (accessFeatures[0].feature_category == "Main Menu Apps") {
				this.props.history.push(`/mainmenu`);
			}
			else {
				/** starts:pwp for exporbar csv redirect*/
				const unitBrandId = localStorage.getItem("csvUnitBrandId");
				const unitId = localStorage.getItem("csvUnitID");
				if(unitId !== null && unitBrandId !== null){
					csvExUnitRedirect(unitId, unitBrandId, this.props.history.push)
				}
				else {
					this.props.history.push(`/dashboardLocations`);
				}
				/**ends:pwp */
			}
		}).catch(err => {
			console.log(err);
		});
	}

	getUserStartLevelID = async(values) => {
		let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
		let loggedinUser = loginUser.username;
		await http({
			method: "GET",			
			url: `${UserProfile.get_Orgnanization_View + localStorage.getItem("customerId")}`,	
			headers: {				
				"Cgids":values,
				'Content-Type': 'application/json',
				'useremail': loggedinUser					
			},data:{},
		}).then(async response => {	
			// if (response.data.fetchFromS3) {
			// 	await $.getJSON(response.data.presignedS3URL, (data) => {
			// 		localStorage.setItem("custLevelID", data.start_level_name);
			// 	})
			// }
			// else {
			// 	localStorage.setItem("custLevelID", response.data.start_level_name);
			// }
			let orgJSON = await readArchivesFromCompressedResponse(response.data);
			localStorage.setItem("custLevelID", orgJSON.start_level_name);	
		}).catch(err => {
			console.log(err);
		});
	}

	closeModel = () => {
		if(this.state.navigateRoute === 'Navigate'){
			this.props.history.push('/registerAccount',{OTP:this.state.OTP})
		}
		this.setState({
			loaderIconVisible : false,
			error_modal:{
				open:false,
				message:""
			},
			success_modal: {
				open:false,
				message:""	
			}
	   });
		 }
		 
	onSuccessCloseModal = () => {
		this.setState({ 
			loaderIconVisible : false,
			success_modal:{
				open:false
			}
		})
		this.props.history.push(`/`);
	}

	componentDidMount() {
		if (auth.getJwt())
			return this.props.history.push('/dashBoardLocations');
		$("body").removeClass("modal-open");
	}

	verifyCallback = (recaptchaToken) => {
		if (recaptchaToken) {
			this.setState({
				isVerified: true,
				errors: {
					...this.state.errors
				}
			});
		}
	}

	render() {
		const { error_modal, success_modal, loaderIconVisible } = this.state;	
		return (
			<div className="loginBg">
				<div id="adminWrapper">   
					{/* <!-- Loading Progress --> */}
					{loaderIconVisible && <div id="loader" className={"loadingProgress showloader"}>
						<div className="loadingProgressIn"></div>
					</div>}
					{/* <!-- //Loading Progress --> 
					<!-- Fixed Header navbar --> */}
					<nav className="navbar fixed-top navbar-expand-lg navbar-dark bgAdmin">   
                		<Link className="navbar-brand welbiltLogo" title="Welbilt - Bringing innovation to the table" to="#">&nbsp;</Link>  
        			</nav>
					{/* <!-- //Fixed Header navbar -->
					<!-- //Fixed Header navbar --> */}
					<div id="page-wrapper">
					<div className="welcomText"><FormattedMessage id='KC0006'/></div>
						{/* <!-- Login Form --> */}
						<div class="LoginBtnWrapper">
							<div id="loginBtnFormID">
								<div class="loginButton">
									<ul>
										<li>&nbsp;</li>
										<li>
											<div className="loginIWrapper">
												<div className="existingLoginForm">
													<ul>
														<li className="form-group">
															<div className="input-group">
																<input className="form-control" placeholder="Enter OTP" type="text" name="otp" value={this.state.OTP} onChange={this.handleOTPChange} onBlur={this.OTPblurevent} /></div>
															<div className="errorMsg">{this.state.errors.message}</div>
														</li>
														<li className="form-group pass">
															<button className="btn btn-secondary existingSingINBtn" type="submit" id="existingSignInButton" onClick={this.onSigninclickHandler}>Verify OTP</button>
														</li>
													</ul>
												</div>
											</div>
										</li>
										<li>&nbsp;</li>
									</ul>
									<ul>
										<li>
										</li>
										<li>
											<div className="loginIWrapper">
											<Link to="/" title="Login"></Link>
											<Link to="/" title="Language" className="languageSel"></Link>
											</div>
										</li> 
										<li>&nbsp;</li>
									</ul>
								</div>
							</div>
						</div>
						{/* <!-- //Login Form -->   */}
					</div>
				</div>
				<ErrorMoal open={error_modal.open} message={error_modal.message} onCloseModel={this.closeModel}/>
				<SuccessMoal open={success_modal.open} message={success_modal.message} onSuccessModel={this.onSuccessCloseModal}/>
			</div>
		)
	}
};

export default VerifyOTP;
