import React, { PureComponent } from 'react';
import http from '../../../service/httpService';
import { BaseService, ReportManagement, UnitInfo } from '../../../service/api';
import { format, addDays } from 'date-fns';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import NumberFormat from 'react-number-format';
import { NewDropDownListLink } from '../../controls/newDropDownListLink';
import { CUSTOMER_ID, STANDARDIZED_BRAND, BRAND_ID_VALUE} from '../../../utils/appConstants';
import { UserBrandList } from '../../controls/userBrandList';
import { FormattedMessage } from 'react-intl';
import { reactIntl } from '../../../locale/locale-provider';
import { compileConfiguration } from '../../DashboardRenderers/configuration-compiler';
import { apiDateFormat, encriptData, getProductionDateRange } from '../../../utils/dashboardRenderer';
import { invokePostMethod } from '../../DashboardRenderers/dashboardRenderer';
import { productionConfiguration } from './productionConfiguration';
import _ from 'lodash';

am4core.options.commercialLicense = true;
am4core.useTheme(am4themes_animated);

var moment = require('moment');
var todaysDate = new Date();
let todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
let weekDateFormatted;

class ProductionLineChart extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      productionData: [],
      totalProduction: 0,
      trend: -1,
      filterType: "Month",
      sd: '',
      ed: '',
      startDate: '',
      brandsAvailable: false,
      endDate: '',
      sdmindate: todaysDate,
      sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
      edmaxdate: todaysDate,
      edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
      legendText: "",
      showBlueDot: false,
      datapickflag: false,
      loading: true
    }
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
  }

  //Productions    
  handleChangeStart(date) {
    this.setState({
      startDate: date,
      datapickflag:true
    }, () => {
      let selectedtext = 'Date Range';
      var e = { target: { text: selectedtext }, nodeText: selectedtext };
      this.onDropDownHandler(e);
    });
  }

  handleChangeEnd(date) {
    this.setState({
      endDate: date,
      datapickflag:true
    }, () => {
      let selectedtext = 'Date Range';
      var e = { target: { text: selectedtext }, nodeText: selectedtext };
      this.onDropDownHandler(e);
    });
  }

  async componentDidMount() {
    const brandListArr = await JSON.parse(localStorage.getItem('availableUnitsBrand'));
    if (brandListArr && brandListArr.length > 0) {
      this.setState({
        brandsAvailable: true
      });
    }
  }

  onBrandSelection(e, type = '') {
    let brandid = 0;
    let legendText = "";
    
    brandid =  BRAND_ID_VALUE[e.target.text];
    legendText = e.target.text === "CONVOTHERM" ? 'KC0093' : e.target.text === "CREM" ? "KC0176"
      : (e.target.text === "MERCO" || e.target.text === "FRYMASTER-UHCTHD") ? "KC0096" : "KC0095";
    this.setState({
      brand_id: brandid,
      brand_name: e.target.text,
      legendText: legendText
    });
    this.fetchData(brandid, type);
    if (e.target.text === "CREM") {
      this.getExcludeProductDetails(brandid);
    } else {
      localStorage.setItem('excludedProductList', false);
    }
    this.setState({
      brand_id: brandid,
    })
  }

  getExcludeProductDetails = async (brandid) => {
    const response = await http.get(UnitInfo.excludeProduct,
      {
        headers: {
          'Content-Type': 'application/json',
          'cid': CUSTOMER_ID,
          'brandid': brandid,
        },
        data: {}
      })
    let excludeProduct = response.data && response.data[0]
    let checklength = excludeProduct && excludeProduct.excludedProductList && excludeProduct.excludedProductList.length ? excludeProduct.excludedProductList.length :0;
    if (checklength > 0) {
      this.setState({
        showBlueDot: true,
      })
    }
  }

  getDates = (fromDate, toDate, range = 'day') => {
    const dateArray = [];
    let startDate = new Date(fromDate)
    const endDate = new Date(toDate)
    while (startDate <= endDate) {
      dateArray.push(startDate);
      startDate = moment(new Date(startDate)).add(1, range)
    }
    return dateArray;
  }

  createCountObject = (dateValue, count) => {
    const countObject = new Object();
    countObject.count = Number(count);
    countObject.date = dateValue;
    return countObject;
  }

  prepareProductionData = async (text, dateRange, data) => {
    try {
      let finalArray = []
      if( text == 'Month' || text == 'Week') {
        dateRange.startDate = moment(dateRange.startDate).add(1,'day');
        dateRange.endDate = moment(dateRange.endDate).subtract(1,'day');
      }
      if (text == 'Date Range' || text == 'Month' || text == 'Week') {
        const days = this.getDates(dateRange.startDate, dateRange.endDate, 'day');
        days.map(oneDate => {
          const dateValue = new Date(oneDate)
          const newDate = dateValue.getFullYear() + '-' + ('0' + (dateValue.getMonth()+1)).slice(-2) + '-' + ('0' + dateValue.getDate()).slice(-2);
          const countObject = data.find(oneData => oneData.date == newDate)
          finalArray.push(this.createCountObject(newDate, countObject ? countObject.count : 0));
        })
      }
      if (text == 'Quarter' || text == 'Year') {
        const months = this.getDates(dateRange.startDate, dateRange.endDate, 'month');
        months.map(oneMonth => {
          const monthValue = new Date(oneMonth);
          const newDate = monthValue.getFullYear() + '-' + ('0' + (monthValue.getMonth()+1)).slice(-2) + '-' + '01';
          const countObject = data.find(oneData => `${oneData.date}-01` == newDate)
          finalArray.push(this.createCountObject(newDate, countObject ? countObject.count : 0));
        })
      }
      return finalArray
    } catch (error) {
      console.log("Catch in Prepare Production Data: ", error)
      throw error;
    }
  }

  findTrend = async (response) => {
    try {
      let trend = -1;
      let currentCount = 0;
      let prevCount = 0;
      if(response?.production && response.production.length) {
        currentCount = response.production.reduce((acc, value) => acc + value.count, 0)
      }
      if(response?.trend && response.trend.length) {
        prevCount = response.trend[0].count
      }
      if(currentCount > prevCount) trend = 1;
      if(currentCount < prevCount) trend = 0;
      return { trend: trend, totalProduction: currentCount};
    } catch(error) {
      console.log("Catch in Find Trend: ", error);
      throw error;
    }
  }

  getUnits = async (params, brand_id) => {
    try {
      const url = UnitInfo.list
      let headers = {
        'Content-Type': 'application/json',
        'cgid': params.cgid,
        'cid': params.cid,
        'brandid': params.brandid || brand_id
      }
      const response = await http.get(url, { headers, data: {}})
      const units = response?.data?.units?.length ? response.data.units : []
      return units;
    } catch(error) {
      console.log("Catch in Get Units: ", error)
      throw error
    }
  }

  getBatchProductionData = async (productionDBData, batchWeightDBData, units) => {
    const dbData = JSON.parse(JSON.stringify(productionDBData))
    const newResult = dbData.map(prodData => {
      let unitModel = units.find(unit => unit.UNITID === prodData.serialNo);
      let batchValue = batchWeightDBData.find(data => unitModel.MODEL_NAME == data.modelName)?.batchWeight || 0;
      prodData.count = prodData.count * batchValue
      return prodData
    })
    const productionGroupData = _.groupBy(newResult,'date')
    
    const result = []
    for(let i = 0; i < Object.keys(productionGroupData).length; i++) {
      const key = Object.keys(productionGroupData)[i]
      const batchWeight = await productionGroupData[key].reduce((acc, value) => {
        return acc + value.count
      }, 0)
      const resultOne = productionGroupData[key][0]
      resultOne.count = Number(batchWeight?.toFixed(2) || 0)
      result.push(resultOne)
    }
    return result
  }

  getReportData = async (text, startDate, date, brand_id) => {
    this.setState({ loading: true })
    const header = this.getHeaders(text, startDate, date, brand_id);

    // Data Standardization Changes
    if (STANDARDIZED_BRAND.includes(brand_id) && productionConfiguration[brand_id]) {

      // Reponse
      const response = { totalProduction: 0, productionData: [], trend: -1 }

      // Get Units
      const result = await this.getUnits(header, brand_id)
      const units = result.map(data => data.UNITID)
      let models = result.map(data => data.MODEL_NAME)
      models = _.uniq(models)
      if(units.length == 0) {
        this.setState({
          totalProduction: response.totalProduction,
          trend: response.trend,
          loading: false
        }, () => {
          this.renderChartData(response.productionData, response.trend, this.state.legendText, text);
        });
        return true;
      }

      // Get Configuration
      const productionConf = productionConfiguration[brand_id].configuration
      const id = productionConfiguration[brand_id].id
      const url = productionConfiguration[brand_id].url
      const toolTip = productionConfiguration[brand_id].toolTip || "KC0095";
      this.setState({
        legendText: toolTip
      });

      let dateRange = { startDate: "", endDate: "", prevStartDate: "", prevEndDate: ""}
      if (text == "Date Range") {
        dateRange.startDate = moment(header.startdate).format(apiDateFormat);
        dateRange.endDate = moment(header.enddate).format(apiDateFormat);
        if(dateRange.endDate == todaysDateFormatted) dateRange.endDate = moment(header.enddate).add(1, 'day').format(apiDateFormat);
      } else {
        const finalDate = getProductionDateRange(text, header.reqdate)
        dateRange.startDate = finalDate.currentRange.fromDate
        dateRange.endDate = finalDate.currentRange.toDate
        dateRange.prevStartDate = finalDate.prevRange.fromDate
        dateRange.prevEndDate = finalDate.prevRange.toDate
      }

      let dateFormat = ""
      if(text == "Week" || text == "Month" || text == "Date Range") dateFormat = "%Y-%m-%d"
      if (text == "Quarter" || text == "Year") dateFormat = "%Y-%m"

      // Prepare Data
      let productionData = {
        data: {
          text: text,
          dateFormat: dateFormat,
          units: units,
          models: models,
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
          prevStartDate: dateRange.prevStartDate || "",
          prevEndDate: dateRange.prevEndDate || ""
        }
      }

      // Get Compiled Configuration
      const compiledConfiguration = await compileConfiguration(productionConf, productionData);
      compiledConfiguration.execute = compiledConfiguration?.execute?.filter((item) => item.hasOwnProperty("if") ? item.if : true)
      const encrptedConfig = await encriptData(JSON.stringify(compiledConfiguration))

      // Call Common Widget API
      const productionResponse = await invokePostMethod(url, { id: id, configuration: encrptedConfig });


      // Production Data
      let productionDBData = productionResponse?.data?.data?.data?.production || []
      const batchWeightDBData = productionResponse?.data?.data?.data?.batchWeight || []
      const trend = productionResponse?.data?.data?.data?.trend || []
      if (productionDBData.length) {
        // ICE O MATIC - ADD BATCH WEIGHT
        if(batchWeightDBData.length) productionDBData = await this.getBatchProductionData(productionDBData, batchWeightDBData, result)
        response.productionData = await this.prepareProductionData(text, dateRange, productionDBData)
      }
      // Total Production & Trend
      if (text == 'Date Range' && response.productionData.length) {
        response.totalProduction = response.productionData.reduce((acc, value) => acc + value.count, 0)
      }
      if (text != 'Date Range' && productionResponse?.data?.data?.data) {
        const trendResult = await this.findTrend(productionResponse?.data?.data?.data)
        response.trend = trendResult.trend.toString()
        response.totalProduction = trendResult.totalProduction
        console.log("Response Trend: ", response.trend)
      }
      if(!Number.isInteger(response.totalProduction)){
        response.totalProduction = response.totalProduction.toFixed(2);
      }
      this.setState({
        totalProduction: response.totalProduction,
        trend: response.trend,
        loading: false
      }, () => {
        this.renderChartData(response.productionData, response.trend, this.state.legendText, text);
      });

      return true;
    }
    let widgetId = null;
    let url;
    if (brand_id === 1) {
      widgetId = 75;
      url = `${BaseService.root}${ReportManagement.widgetAPI}/${widgetId}/${brand_id}`;
    }
    else if (brand_id === 6) {
      widgetId = 76;
      url = `${BaseService.root}${ReportManagement.widgetAPI}/${widgetId}/${brand_id}`;
    }
    else if (brand_id === 3) {
      widgetId = 74;
      url = `${BaseService.root}${ReportManagement.widgetAPI}/${widgetId}/${brand_id}`;
    }
    else if (brand_id === 2) {
      widgetId = 77;
      url = `${BaseService.root}${ReportManagement.widgetAPI}/${widgetId}/${brand_id}`;
    }
    else if (brand_id === 11) {
      widgetId = 103;
      url = `${BaseService.root}${ReportManagement.widgetAPI}/${widgetId}/${brand_id}`;
    }
    else if(brand_id === 9)
    {
        widgetId = 113;
        url = `${BaseService.root}${ReportManagement.widgetAPI}/${widgetId}/${brand_id}`;
    }
    else {
      url = `${ReportManagement.countroot}`
    }

    this.getData(url, header, brand_id).then(response => {
      this.setState({
        totalProduction: response.totalProduction,
        trend: response.trend,
        loading: false
      }, () => {
        this.renderChartData(response.productionData, response.trend, this.state.legendText, text);
      });
    }).catch(err => {
      //this.renderChartData([]);
    });
  }

  fetchData(brand_id, text) {
    this.setState({ filterType: text })
    if (text === "Date Range") {
      todaysDateFormatted = format(this.state.endDate, 'YYYY-MM-DD');
      weekDateFormatted = format(this.state.startDate, 'YYYY-MM-DD');
    }
    else {
      todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
      weekDateFormatted = format(addDays(todaysDate, - 6), 'YYYY-MM-DD');
    }

    if ((todaysDateFormatted !== "Invalid Date" && weekDateFormatted !== "Invalid Date")) {
      this.getReportData(text, weekDateFormatted, todaysDateFormatted, brand_id);
    }
  }

  onDropDownHandler(e) {
    const filterType = e.nodeText;
    this.setState({
      filterType,
      datapickflag: filterType === 'Date Range',
    });
    this.fetchData(this.state.brand_id, filterType);
  }

  getHeaders(type = 'Month', startDateFormatted = '', endDateFormatted = '', brand_id = '') {
    let filterType;
    let groupBy;
    switch (type) {
      case 'Week':
        filterType = brand_id === 4 ? 'custom' : 'weekly';
        groupBy = 'date_created';
        break;
      case 'Year':
        filterType = 'yearly';
        groupBy = 'month';
        break;
      case 'Day':
        filterType = 'daily';
        groupBy = 'date_created';
        break;
      case 'Quarter':
        filterType = 'quarterly';
        groupBy = 'month';
        break;
      case 'Date Range':
        filterType = 'custom';
        groupBy = 'date_created';
        break;
      default:
        filterType = 'monthly';
        groupBy = 'date_created';
    }

    let header;

    let cgids = "";

    if (this.props.orgid && this.props.orgid.length > 0) {
      cgids = this.props.orgid;
    }
    if (this.props.cgid && this.props.cgid.length > 0) {
      cgids = this.props.cgid;
    }

    if (filterType === 'custom' || filterType === 'weekly') {
      startDateFormatted = format(startDateFormatted, 'YYYY-MM-DD');
      endDateFormatted = format(endDateFormatted, 'YYYY-MM-DD');

      if (brand_id === 4) {
        header = {
          'cgid': cgids,
          'cid': CUSTOMER_ID === "-1" ? this.props.cid : CUSTOMER_ID,
          'brandid': brand_id,
          'model': this.props.model,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'startdate': startDateFormatted,
          'enddate': endDateFormatted,
          'filterType': filterType,
          'groupby': groupBy
        };
      }
      else {
        header = {
          'cgid': cgids,
          'cid': CUSTOMER_ID === "-1" ? this.props.cid : CUSTOMER_ID,
          'productmodel': this.props.model,
          'Content-Type': 'application/json',
          'startdate': startDateFormatted,
          'enddate': endDateFormatted,
          'filterType': filterType,
          'showtrend': filterType === 'weekly' ? 'true' : 'false'
        };
      }
    }
    else {
      startDateFormatted = format(todaysDate, 'YYYY-MM-DD');

      if (brand_id === 4) {
        header = {
          'cgid': cgids,
          'cid': CUSTOMER_ID === "-1" ? this.props.cid : CUSTOMER_ID,
          'brandid': brand_id,
          'model': this.props.model,
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'reqdate': startDateFormatted,
          'filterType': filterType,
          'groupby': groupBy
        };
      }
      else {
        header = {
          'cgid': cgids,
          'cid': CUSTOMER_ID === "-1" ? this.props.cid : CUSTOMER_ID,
          'productmodel': this.props.model,
          'Content-Type': 'application/json',
          'reqdate': startDateFormatted,
          'filterType': filterType,
          'showtrend': 'true'
        };
      }
    }
    if (this.props.tags && this.props.tags.length > 0) {
      header['tags'] = this.props.tags.join(',');
    }
    return header;
  }

  getData(url, header, brand_id) {
    return new Promise((resolve, reject) => {
      http.get(url,
        {
          headers: header,
          data: {}
        })
        .then(response => {
          if (brand_id === 4) {
            resolve({
              productionData: response.data.productionData ? response.data.productionData : [],
              totalProduction: response.data.totalProduction ? response.data.totalProduction : 0,
              trend: response.data.trend ? response.data.trend : -1,
            })
          }
          else {
            resolve({
              productionData: response.data.productionData ? response.data.productionData : [],
              totalProduction: response.data.content ? response.data.content[0] ? response.data.content[0].value : 0 : 0,
              trend: response.data.content ? response.data.content[2] ? response.data.content[2].value : -1 : -1,
            })
          }
        })
        .catch(error => {
          reject({
            productionData: [],
            totalProduction: 0,
            trend: -1
          })
        });
    });
  }

  renderChartData = (chartData, trend, legendText, type) => {
    if (chartData && chartData.length > 0) {
      let chart = am4core.create("revenueChartdivAllLocations", am4charts.XYChart);
      let separator = localStorage.getItem("thousandSeparator");
      chartData.forEach((item) => {
        // Tooltip to show data with . as thousand separator
        item.tooltipNum = separator === "." ? item.count.toString().replace(',', separator) : item.count;
        item.dateText = (type === "Quarter" || type === "Year") ? format(item.date, 'MMMM YYYY')
          : format(item.date, 'M/D/YYYY');
      });

      chart.data = chartData && chartData.length > 0 ? chartData : [];
      this.chart = chart;
      chart.zoomOutButton.disabled = true;
      chart.cursor = new am4charts.XYCursor();
      chart.hiddenState.properties.opacity = 1; // this creates initial fade-in

      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "date";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.minGridDistance = (type === "Day" || type === "Month") ? 1 : 6;
      categoryAxis.renderer.labels.template.disabled = true;
      categoryAxis.renderer.grid.template.disabled = true;
      categoryAxis.renderer.inside = false;
      categoryAxis.renderer.line.strokeOpacity = 0;
      categoryAxis.renderer.line.strokeWidth = 0;
      categoryAxis.renderer.line.stroke = am4core.color("#FFF");
      categoryAxis.tooltipText = "{dateText}";
      categoryAxis.renderer.tooltipLocation = 0;
      // categoryAxis.tooltip.disabled = true;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.grid.template.location = 0;
      valueAxis.renderer.labels.template.disabled = true;
      valueAxis.renderer.grid.template.disabled = true;
      valueAxis.min = 0;
      valueAxis.tooltip.disabled = true;

      //Configure Ticks
      if (chart.data.length > 0) {
        categoryAxis.renderer.ticks.template.disabled = false;
        categoryAxis.renderer.ticks.template.length = 5;
        categoryAxis.renderer.ticks.template.strokeOpacity = 1;
        categoryAxis.renderer.ticks.template.stroke = am4core.color("#d9d9d9");
        categoryAxis.renderer.ticks.template.strokeWidth = 1;
      }

      let series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.categoryX = "date";
      series.dataFields.valueY = "count";
      series.dataFields.tootltipY = "tooltipNum";
      series.name = reactIntl.formatMessage({id: legendText}); 
      series.tooltipText = reactIntl.formatMessage({id: legendText})+ ": [bold]{tootltipY}[/]"; 

      series.tooltip.label.adapter.add("textOutput", function (text, target) {
        if (target.dataItem.dataContext && target.dataItem.dataContext.tooltipNum) {
          return reactIntl.formatMessage({ id: legendText }) + `: [bold]${target.dataItem.dataContext.tooltipNum}[/]`;
        } else {
          return reactIntl.formatMessage({ id: legendText }) + `: `;
        }
      });
      // series.name = legendText;
      // series.tooltipText = legendText + ": [bold]{tootltipY}[/]";

      let gradient = new am4core.LinearGradient();
      gradient.rotation = 90;
      if (trend === '1') {
        gradient.addColor(am4core.color("#1f6387"));
        gradient.addColor(am4core.color("#293b47"));
        series.fill = gradient;
        series.stroke = am4core.color("#0ea1e9");
      }
      else if (trend === '0') {
        gradient.addColor(am4core.color("#616366"));
        gradient.addColor(am4core.color("#424447"));
        series.fill = gradient;
        series.stroke = am4core.color("#919396");
      }
      series.strokeWidth = 1;
      series.fillOpacity = 0.8;

      // Series Tooltip Test and bg color
      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fill = am4core.color("#FFFFFF");
      series.tooltip.label.fill = am4core.color("#000000");
    }
    else {
      if (this.chart) {
        this.chart.dispose();
      }
    }
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    let { totalProduction, trend, loading, brandsAvailable } = this.state;
    if (totalProduction === undefined) {
      totalProduction = 0;
    }

    let newNumberFormat =  <NumberFormat value={totalProduction} displayType={'text'} thousandSeparator={true} />
    if (localStorage.getItem("thousandSeparator") === "."){
      newNumberFormat =  <NumberFormat value={totalProduction} displayType={'text'} thousandSeparator={"."} decimalSeparator={","} />
    }
    return (
      <div className="colmDi brandDropdown">
        <h4><FormattedMessage id='KC0043' />
        &nbsp;{this.state.showBlueDot ? <span class="blueDot" title={reactIntl.formatMessage({id:"KC1037"})}></span> : ""}
          <NewDropDownListLink translation={true} datapickflag={this.state.datapickflag} startDatepara={this.state.startDate} minDateDisable={this.state.sdmindate} maxDateDisable={this.state.sdmaxdate} endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.endDate} handleChangeStartProps={(date) => this.handleChangeStart(date)} handleChangeEndProps={(date) => this.handleChangeEnd(date)} OnChange={(e) => this.onDropDownHandler(e)} filterType={this.state.filterType} maxDaysRequired={30}/>
          { brandsAvailable && <UserBrandList onBrandSelection={(e) => this.onBrandSelection(e, this.state.filterType)} brandLabelRequired={true}/> }
        </h4>
        <div className="colmDiVal">
          { brandsAvailable && loading && <li className="spinner"></li> } 
          { !loading && <div className="valH1Div">            
            {newNumberFormat} &nbsp;
          <span className={trend === '1' ? "valH1Span valHigh" : trend === '0' ? "valH1Span valLow" : "valH1Span"}><FormattedMessage id={this.state.legendText}/></span></div> }
          {/* <div className={this.state.filterType === "Date Range" ? "displaynone" : "valH5Div"}>
              prior {this.state.filterType.toLowerCase()}: 50
          </div> */}
        </div>
        <div id="revenueChartdivAllLocations" ></div>
      </div>
    );
  }
}

export default ProductionLineChart;
