export const productionConfiguration = {
    2: {
        id: "mercoProduction",
        url: "/reportmanagement/report-management/dashboard-service/v1/widget-data",
        configuration: {
            "execute": [
                {
                    "if": "{{data.text != 'Day'}}",
                    "database": "docdb",
                    "query": {
                        "collection": "events",
                        "operation": [
                            {
                                "name": "aggregate",
                                "options": [
                                    [
                                        {
                                            $match: {

                                                serialNo: { $in: "{{ data.units }}" },
                                                'eventData.event.value': 'holdStart',
                                                deviceTimestamp: {
                                                    $gte: "{{_serverScope.currentStartValue | dataType: date}}",
                                                    $lt: "{{_serverScope.currentEndValue | dataType: date}}"
                                                }
                                            }
                                        },
                                        {
                                            $group: {
                                                _id: {
                                                    $dateToString: {
                                                        format: "{{data.dateFormat}}",
                                                        date: '$deviceTimestamp'
                                                    }
                                                },
                                                count: { $sum: 1 }
                                            }
                                        },
                                        {
                                            $project: { date: '$_id', count: '$count' }
                                        },
                                        {
                                            $sort: { date: 1 }
                                        }
                                    ]
                                ]
                            }
                        ]
                    },
                    "outputProperty": "production"
                },
                {
                    "if": "{{data.text = 'Day'}}",
                    "database": "docdb",
                    "query": {
                        "collection": "events",
                        "operation": [
                            {
                                "name": "aggregate",
                                "options": [
                                    [
                                        {
                                            $match: {

                                                serialNo: { $in: "{{ data.units }}" },
                                                'eventData.event.value': 'holdStart',
                                                deviceTimestamp: {
                                                    $gte: "{{_serverScope.currentStartValue | dataType: date}}",
                                                    $lt: "{{_serverScope.currentEndValue | dataType: date}}"
                                                }
                                            }
                                        },
                                        {
                                            $group: {
                                                _id: null,
                                                count: { $sum: 1 }
                                            }
                                        }
                                    ]
                                ]
                            }
                        ]
                    },
                    "outputProperty": "production"
                },
                {
                    "if": "{{data.text != 'Date Range'}}",
                    "database": "docdb",
                    "query": {
                        "collection": "events",
                        "operation": [
                            {
                                "name": "aggregate",
                                "options": [
                                    [
                                        {
                                            $match: {

                                                serialNo: { $in: "{{ data.units }}" },
                                                'eventData.event.value': 'holdStart',
                                                deviceTimestamp: {
                                                    $gte: "{{_serverScope.prevStartValue | dataType: date}}",
                                                    $lt: "{{_serverScope.prevEndValue | dataType: date}}"
                                                }
                                            }
                                        },
                                        {
                                            $group: {
                                                _id: null,
                                                count: { $sum: 1 }
                                            }
                                        }
                                    ]
                                ]
                            }
                        ]
                    },
                    "outputProperty": "trend"
                }
            ],
            "executionData": {
                "_serverScope": {
                    currentStartValue: "{{data.startDate}}",
                    currentEndValue: "{{data.endDate}}",
                    prevStartValue: "{{data.prevStartDate}}",
                    prevEndValue: "{{data.prevEndDate}}"
                }
            }
        }
    },
    12: {
        id: "xpresschefProduction",
        url: "/reportmanagement/report-management/dashboard-service/v1/widget-data",
        configuration: {
            "execute": [
                {
                    "if": "{{data.text != 'Day'}}",
                    "database": "docdb",
                    "query": {
                        "collection": "events",
                        "operation": [
                            {
                                "name": "aggregate",
                                "options": [
                                    [
                                        {
                                            $match: {

                                                serialNo: { $in: "{{ data.units }}" },
                                                "eventData.event.value": "cookingStop",
                                                "eventData.processDurationInSeconds.value": {
                                                    "$exists": true
                                                },
                                                deviceTimestamp: {
                                                    $gte: "{{_serverScope.currentStartValue | dataType: date}}",
                                                    $lt: "{{_serverScope.currentEndValue | dataType: date}}"
                                                }
                                            }
                                        },
                                        {
                                            $group: {
                                                _id: {
                                                    $dateToString: {
                                                        format: "{{data.dateFormat}}",
                                                        date: '$deviceTimestamp'
                                                    }
                                                },
                                                count: { $sum: 1 }
                                            }
                                        },
                                        {
                                            $project: { date: '$_id', count: '$count' }
                                        },
                                        {
                                            $sort: { date: 1 }
                                        }
                                    ]
                                ]
                            }
                        ]
                    },
                    "outputProperty": "production"
                },
                {
                    "if": "{{data.text = 'Day'}}",
                    "database": "docdb",
                    "query": {
                        "collection": "events",
                        "operation": [
                            {
                                "name": "aggregate",
                                "options": [
                                    [
                                        {
                                            $match: {

                                                serialNo: { $in: "{{ data.units }}" },
                                                "eventData.event.value": "cookingStop",
                                                "eventData.processDurationInSeconds.value": {
                                                    "$exists": true
                                                },
                                                deviceTimestamp: {
                                                    $gte: "{{_serverScope.currentStartValue | dataType: date}}",
                                                    $lt: "{{_serverScope.currentEndValue | dataType: date}}"
                                                }
                                            }
                                        },
                                        {
                                            $group: {
                                                _id: null,
                                                count: { $sum: 1 }
                                            }
                                        }
                                    ]
                                ]
                            }
                        ]
                    },
                    "outputProperty": "production"
                },
                {
                    "if": "{{data.text != 'Date Range'}}",
                    "database": "docdb",
                    "query": {
                        "collection": "events",
                        "operation": [
                            {
                                "name": "aggregate",
                                "options": [
                                    [
                                        {
                                            $match: {

                                                serialNo: { $in: "{{ data.units }}" },
                                                "eventData.event.value": "cookingStop",
                                                "eventData.processDurationInSeconds.value": {
                                                    "$exists": true
                                                },
                                                deviceTimestamp: {
                                                    $gte: "{{_serverScope.prevStartValue | dataType: date}}",
                                                    $lt: "{{_serverScope.prevEndValue | dataType: date}}"
                                                }
                                            }
                                        },
                                        {
                                            $group: {
                                                _id: null,
                                                count: { $sum: 1 }
                                            }
                                        }
                                    ]
                                ]
                            }
                        ]
                    },
                    "outputProperty": "trend"
                }
            ],
            "executionData": {
                "_serverScope": {
                    currentStartValue: "{{data.startDate}}",
                    currentEndValue: "{{data.endDate}}",
                    prevStartValue: "{{data.prevStartDate}}",
                    prevEndValue: "{{data.prevEndDate}}"
                }
            }
        }
    },
    13: {
        id: "iceOMaticProduction",
        toolTip : "KC2524",
        url: "/reportmanagement/report-management/dashboard-service/v1/widget-data",
        configuration: {
            "execute": [
                {
                    "database": "docdb",
                    "query": {
                        "collection": "iceOMaticBatchWeights",
                        "operation": [
                            {
                                "name": "aggregate",
                                "options": [
                                    [
                                        {
                                            $match: {
                                                MODEL_NAME: { $in: "{{ data.models }}" }
                                            }
                                        }, {
                                            $project: {
                                                _id: 0,
                                                modelFamily: "$MODEL_FAMILY",
                                                modelName: "$MODEL_NAME",
                                                batchWeight: "$BATCH_WEIGHT"
                                            }
                                        }
                                    ]
                                ]
                            }
                        ]
                    },
                    "outputProperty": "batchWeight"
                },
                {
                    "if": "{{data.text != 'Day'}}",
                    "database": "docdb",
                    "query": {
                        "collection": "events",
                        "operation": [
                            {
                                "name": "aggregate",
                                "options": [
                                    [
                                        {
                                            $match: {

                                                serialNo: { $in: "{{ data.units }}" },
                                                "eventData.event.value": "harvest",
                                                deviceTimestamp: {
                                                    $gte: "{{_serverScope.currentStartValue | dataType: date}}",
                                                    $lt: "{{_serverScope.currentEndValue | dataType: date}}"
                                                }
                                            }
                                        },
                                        {
                                            $group: {
                                                _id: {
                                                    date: {
                                                        $dateToString: {
                                                            format: "{{data.dateFormat}}",
                                                            date: '$deviceTimestamp'
                                                        }
                                                    },
                                                    serialNo: '$serialNo'
                                                },
                                                modelName: { $first: '$equipmentType'},
                                                count: { $sum: 1}
                                            }
                                        },
                                        {
                                            $project: { date: '$_id.date', serialNo: '$_id.serialNo', count: '$count', modelName: '$modelName', _id: 0 }
                                        },
                                        {
                                            $sort: { date: 1 }
                                        }
                                    ]
                                ]
                            }
                        ]
                    },
                    "outputProperty": "production"
                },
                {
                    "if": "{{data.text = 'Day'}}",
                    "database": "docdb",
                    "query": {
                        "collection": "events",
                        "operation": [
                            {
                                "name": "aggregate",
                                "options": [
                                    [
                                        {
                                            $match: {

                                                serialNo: { $in: "{{ data.units }}" },
                                                "eventData.event.value": "harvest",
                                                deviceTimestamp: {
                                                    $gte: "{{_serverScope.currentStartValue | dataType: date}}",
                                                    $lt: "{{_serverScope.currentEndValue | dataType: date}}"
                                                }
                                            }
                                        },
                                        {
                                            $group: {
                                                _id: '$serialNo',
                                                count: { $sum: 1},
                                                modelName: { $first: '$equipmentType'},
                                            }
                                        },
                                        {
                                            $project: { serialNo: '$_id', count: '$count', modelName: '$modelName', _id: 0 }
                                        },
                                    ]
                                ]
                            }
                        ]
                    },
                    "outputProperty": "production"
                },
                {
                    "if": "{{data.text != 'Date Range'}}",
                    "database": "docdb",
                    "query": {
                        "collection": "events",
                        "operation": [
                            {
                                "name": "aggregate",
                                "options": [
                                    [
                                        {
                                            $match: {

                                                serialNo: { $in: "{{ data.units }}" },
                                                "eventData.event.value": "harvest",
                                                deviceTimestamp: {
                                                    $gte: "{{_serverScope.prevStartValue | dataType: date}}",
                                                    $lt: "{{_serverScope.prevEndValue | dataType: date}}"
                                                }
                                            }
                                        },
                                        {
                                            $group: {
                                                _id: '$serialNo',
                                                count: { $sum: 1},
                                                modelName: { $first: '$equipmentType'},
                                            }
                                        },
                                        {
                                            $project: { serialNo: '$_id', count: '$count', modelName: '$modelName', _id: 0 }
                                        }
                                    ]
                                ]
                            }
                        ]
                    },
                    "outputProperty": "trend"
                }
            ],
            "executionData": {
                "_serverScope": {
                    currentStartValue: "{{data.startDate}}",
                    currentEndValue: "{{data.endDate}}",
                    prevStartValue: "{{data.prevStartDate}}",
                    prevEndValue: "{{data.prevEndDate}}"
                }
            }
        }
    }
}