import { format } from 'date-fns';
import { secondsToDaysHM, formatTimeWithTimezoneDate, getDateFromISOString } from "../configuration-compiler-helper";
const moment = require('moment');

const _ = require('lodash');
const startOfWeek = require('date-fns/start_of_week');
const startOfMonth = require('date-fns/start_of_month');
const startOfQuarter = require('date-fns/start_of_quarter');
const endOfWeek = require('date-fns/end_of_week');
const endOfMonth = require('date-fns/end_of_month');
const endOfQuarter = require('date-fns/end_of_quarter');
const getMonth = require('date-fns/get_month');

const typeNumber = "number";
const is24Hours = localStorage.getItem("timeFormat") === "24";
const days = moment().daysInMonth();
const daysInMonth = Array.from({ length: days }, (_, i) => { return i + 1 });
const hours24 = Array.from({ length: 24 }, (_, i) => { return `${i}-${i + 1}` });
const hours12 = Array.from({ length: 24 }, (_, i) => { return (i > 12) ? `${i - 12}-${i - 11} pm` : (i === 12) ? `12-1 pm` : `${i}-${i + 1} am` });
const months = Array.from({ length: 12 }, (_, i) => { return moment().month(i).format('MMMM') });

const xAxis = {
    hourly24: hours24,
    hourly12: hours12,
    Year: months,
    Quarter: months,
    Month: daysInMonth,
    Week: daysInMonth
}

const noDataResponse = {
    chartData: {
        data: [],
        messages: [
            {
                "title": "KC2570",
                "subTitleMessage": "0days 0h 0m"
            },
            {
                "title": "KC2571",
                "subTitleMessage": "0days 0h 0m"
            }
        ],
        setMaxPrecision: true,
    }
};

const secondsToDuration = (seconds, skipZeroDay = false) => {
    let average = "<1m";
    if (seconds >= 60) {
        average = secondsToDaysHM(seconds, skipZeroDay);
    }
    return average;
};

const findCategory = (_widgetScope) => {
    const todaysDate = new Date();
    let categoryStarts = null;
    let categoryEnds = null;
    if (_widgetScope.dateFilterValue.value === "Week") {
        let startDateMonth = getMonth(startOfWeek(todaysDate, { weekStartsOn: 1 }));
        let endDateMonth = getMonth(endOfWeek(todaysDate, { weekStartsOn: 1 }));
        let todayMonth = getMonth(todaysDate);

        if (startDateMonth < todayMonth && endDateMonth === todayMonth) {
            categoryStarts = format(startOfMonth(todaysDate), 'D');
            categoryEnds = format(endOfWeek(todaysDate, { weekStartsOn: 1 }), 'D');
        }
        else if (startDateMonth === todayMonth && endDateMonth > todayMonth) {
            categoryStarts = format(startOfWeek(todaysDate, { weekStartsOn: 1 }), 'D');
            categoryEnds = format(endOfMonth(todaysDate), 'D');
        }
        else {
            categoryStarts = format(startOfWeek(todaysDate, { weekStartsOn: 1 }), 'D');
            categoryEnds = format(endOfWeek(todaysDate, { weekStartsOn: 1 }), 'D');
        }
    }
    else if (_widgetScope.dateFilterValue.value === "Quarter") {
        categoryStarts = format(startOfQuarter(todaysDate), 'MMMM');
        categoryEnds = format(endOfQuarter(todaysDate), 'MMMM');
    }
    return { categoryStarts, categoryEnds }
}

const clientFunctions = {

    totalIceProduction: {
        transformResponse: ({ _clientResponse }) => {
            const current = _clientResponse?.data?.data?.current ? _clientResponse.data.data.current : 0;
            const previous = _clientResponse?.data?.data?.previous ? _clientResponse.data.data.previous : 0;
            const batchWeight = _clientResponse?.data?.data?.batchWeight[0]?.batchWeight ? _clientResponse.data.data.batchWeight[0].batchWeight : 1;

            return {
                current: Number((current * batchWeight).toFixed(2)),
                previous: Number((previous * batchWeight).toFixed(2))
            }
        }
    },

    avgProductionPerDay: {
        transformResponse: ({ _clientResponse, _widgetScope }) => {
            const current = _clientResponse?.data?.data?.current ? _clientResponse.data.data.current : 0;
            const previous = _clientResponse?.data?.data?.previous ? _clientResponse.data.data.previous : 0;
            const batchWeight = _clientResponse?.data?.data?.batchWeight[0]?.batchWeight ? _clientResponse.data.data.batchWeight[0].batchWeight : 1;
            const days = _widgetScope.dateFilterDays

            return {
                current: days > 0 ? Number(((current * batchWeight)/days).toFixed(2)) : 0,
                previous: days > 0 ? Number(((previous * batchWeight)/days).toFixed(2)) : 0
            }
        }
    },

    iceProduction: {
        transformResponse: ({ _clientResponse, _widgetScope }) => {
            let productData = _clientResponse?.data?.data?.productData || [];
            const batchWeight = _clientResponse?.data?.data?.batchWeight[0]?.batchWeight || 0;

            if (productData.length === 0) {
                return { chartData: { data: [], subTitleMessage: "0", setMaxPrecision: false } };
            }

            let totalCooks = 0, average = 0;
            const xAxis = {
                hourly24: hours24,
                hourly12: hours12,
                Year: months,
                Quarter: months,
                Month: daysInMonth,
                Week: daysInMonth
            }

            productData = productData.map(item => {
                item.cooks = (item.cooks * batchWeight);
                return item
            })

            totalCooks = _.sumBy(productData, 'cooks');
            //Get average cooks per day
            if (typeof (totalCooks) === typeNumber) {
                average = totalCooks / _widgetScope.dateFilterDays;
                average = Number(average.toFixed(2));
            }

            if (_widgetScope.dateFilterValue.value !== "Day") {
                let dateFormat = (_widgetScope.dateFilterValue.value === "Week" || _widgetScope.dateFilterValue.value === "Month" || _widgetScope.dateFilterValue.value === "Custom") ? "D" : (_widgetScope.dateFilterValue.value === "Year" || _widgetScope.dateFilterValue.value === "Quarter") ? "MMMM" : "";
                productData = productData.map(item => {
                    let day = item.key.split('-')[2] || 1; //Setting default date to 1 for quarter and year type filter
                    let month = item.key.split('-')[1];
                    day = day && day.length === 1 ? '0' + day : day;
                    month = month && month.length === 1 ? '0' + month : month;
                    let date = item.key.split('-')[0] + '-' + month + '-' + day;
                    item.key = (moment(date).format(dateFormat));
                    return {
                        "x": item.key,
                        "y": item.cooks,
                        "alpha": 1
                    };
                });

            } else {
                productData = productData.map(item => {
                    item.key = item.key + 1;

                    if (is24Hours) {
                        item.key = (item.key === 24 ? "23-24" : `${item.key - 1}-${item.key}`);
                    }else{
                        item.key = (item.key === 12 ? "11-12 am" : item.key === 24 ? "11-12 pm" : item.key > 12 ? item.key === 13 ? "12-1 pm" : `${item.key - 13}-${item.key - 12} pm` : `${item.key - 1}-${item.key} am`);
                    }
                    return {
                        "x": item.key,
                        "y": item.cooks,
                        "alpha": 1
                    };
                });

            }

            let dataSet = [];
            let baseX  = xAxis[_widgetScope.dateFilterValue.value];
            if (_widgetScope.dateFilterValue.value === "Day") {
                baseX = is24Hours ? xAxis.hourly24 : xAxis.hourly12;
            }
            dataSet = baseX.map(item => {
                return {
                    x: item,
                    y: Number(productData.find(res => res.x == item)?.y?.toFixed(2)) || 0,
                    alpha: 1
                }
            })

            const { categoryStarts, categoryEnds } = findCategory(_widgetScope);
            return {
                chartData: {
                    data: dataSet,
                    subTitleMessage: average,
                    setMaxPrecision: true,
                    categoryStarts: categoryStarts,
                    categoryEnds: categoryEnds,
                    average
                }
            };
        }
    },
    freezeHarvestCycles: {
        transformResponse: ({ _clientResponse, _widgetScope }) => {
            const { duration = [] } = _clientResponse?.data?.data || {};
            let freezeData = [], harvestData = [];
            const scaling = {
                Day :{ divider: 60, unit: 'minutes', x: "Time", y: "Time (Minutes)"},
                Week: { divider: 3600, unit: 'hours', x: "Time", y: "Time (Hours)"},
                Month: { divider: 3600, unit: 'hours', x:"Time", y: "Time (Hours)"},
                Quarter: { divider: 86400, unit: 'days', x: "Time", y: "Time (Days)"},
                Year: { divider: 86400, unit: 'days', x: "Time", y: "Time (Days)"}
            }

            if (duration.length === 0) {
                return noDataResponse;
            }

            const freezeDuration = _.sumBy(duration, 'freeze') || 0;
            const harvestDuration = _.sumBy(duration, 'harvest') || 0;
            const freezeCount = _.sumBy(duration, 'freezeCount') || 1;
            const harvestCount = _.sumBy(duration, 'harvestCount') || 1;

            const freezeAverage = secondsToDuration(freezeDuration / freezeCount, false);
            const harvestAverage = secondsToDuration(harvestDuration / harvestCount, false);

            if (_widgetScope.dateFilterValue.value !== "Day") {
                let dateFormat = (_widgetScope.dateFilterValue.value === "Week" || _widgetScope.dateFilterValue.value === "Month" || _widgetScope.dateFilterValue.value === "Custom") ? "D" : (_widgetScope.dateFilterValue.value === "Year" || _widgetScope.dateFilterValue.value === "Quarter") ? "MMMM" : "";
                for (let item of duration) {
                    let day = item.key.split('-')[2] || 1; //Setting default date to 1 for quarter and year type filter
                    let month = item.key.split('-')[1];
                    day = day && day.length === 1 ? '0' + day : day;
                    month = month && month.length === 1 ? '0' + month : month;
                    let date = item.key.split('-')[0] + '-' + month + '-' + day;
                    let key = (moment(date).format(dateFormat));
                    freezeData.push({
                        x: key,
                        y: item.freeze || 0,
                    });
                    harvestData.push({
                        x: key,
                        y: item.harvest || 0,
                    });
                }
            } else {
                for (let item of duration) {

                    let key = item.key + 1;
                    
                    if (is24Hours) {
                        key = (key === 24 ? "23-24" : `${key - 1}-${key}`);
                    } else{
                        key = (key === 12 ? "11-12 am" : key === 24 ? "11-12 pm" : key > 12 ? key === 13 ? "12-1 pm" : `${key - 13}-${key - 12} pm` : `${key - 1}-${key} am`);
                    }

                    freezeData.push({
                        x: key,
                        y: item.freeze || 0,
                    });

                    harvestData.push({
                        x: key,
                        y: item.harvest || 0,
                    });
                }
            }

            let baseX  = xAxis[_widgetScope.dateFilterValue.value];
            if (_widgetScope.dateFilterValue.value === "Day") {
                baseX = is24Hours ? xAxis.hourly24 : xAxis.hourly12;
            }
            const freezeDataSet = baseX.map((x) => {
                const freeze_data =  freezeData.find(({ x: xValue }) => xValue == x);
                const tooltip = secondsToDaysHM(freeze_data?.y, true);
                const duration = (freeze_data?.y / scaling[_widgetScope.dateFilterValue.value].divider)?.toFixed(2) ?? 0;
                return {
                x: String(x),
                y: duration,
                value: 'Freeze',
                yUnit: scaling[_widgetScope.dateFilterValue.value].unit,
                tooltip 
              }});

              const harvestDataSet = baseX.map((x) => {
                const harvest_data = harvestData.find(({ x: xValue }) => xValue == x);
                const tooltip = secondsToDaysHM(harvest_data?.y, true);
                const duration = (harvest_data?.y / scaling[_widgetScope.dateFilterValue.value].divider)?.toFixed(2) ?? 0;
                return {
                x: String(x),
                y: duration,
                value: 'Harvest',
                yUnit: scaling[_widgetScope.dateFilterValue.value].unit,
                tooltip
              }});


            const { categoryStarts, categoryEnds } = findCategory(_widgetScope);
            const response = {
                chartData: {
                    data: [ ...freezeDataSet, ...harvestDataSet ],
                    messages: [
                        {
                            "title": "KC2570",
                            "subTitleMessage": freezeAverage
                        },
                        {
                            "title": "KC2571",
                            "subTitleMessage": harvestAverage
                        }
                    ],
                    setMaxPrecision: true,
                    categoryStarts: categoryStarts,
                    categoryEnds: categoryEnds,
                    axesTitle: {
                        x: scaling[_widgetScope.dateFilterValue.value].x,
                        y: scaling[_widgetScope.dateFilterValue.value].y
                    }
                }
            };
            return response;
        }
    },
    freezeVsHarvest: {
        transformResponse: ({ _clientResponse }) => {
            const stack = {
                "freeze": "#ff3b84",
                 "harvest": "#eacd61"
            }
            const current = _clientResponse?.data?.data?.result?.length ? _clientResponse.data.data.result : [];

            const transformedData = current.map((currentItem) => {
                const response = {
                    productID: currentItem.event.replace(/^./, currentItem.event[0].toUpperCase()),
                    counts: currentItem.count,
                    color: stack[currentItem.event],
                };
                return response
            });
            return {
                data: {
                    value: transformedData
                }
            }
        }
    },
    statesVsTime: {
        transformResponse: async ({ _rootScope, _clientResponse, _widgetScope }) => {

            let { data = [], systemBootData = [] } = _clientResponse.data.data;
            systemBootData = await handleSystemBootData(systemBootData, _rootScope)
            let newData = [...data, ...systemBootData]
            if (newData.length) newData.sort(data => data.fromDate)

            const requestToDate = moment(_widgetScope.dateFilterValue?.toDate).endOf('day');
            const requestFromDate = moment(_widgetScope.dateFilterValue?.fromDate).startOf('day');
            const result = await handleStatesVsTime(newData, requestToDate, requestFromDate);
            return {
                data: {
                    value: result,
                    streamData: result
                }
            }
        }
    },
    sumpAndControlTemp:{
        transformResponse: ({ _clientResponse }) => {
            let productData = _clientResponse?.data?.data?.data || [];
            if (productData.length === 0) {
                return { data: { value: [], streamData: []} };
            }
            const transformedData = productData.map(item => {
                const { timestamp } = item;
                return {
                    sump: item["Sump"],
                    controlBoard: item["Control Board"],
                    x: timestamp.replace('Z', '')
                };
            });
            return {
                data: { 
                    value: [],
                    streamData: transformedData
                }
            }
        }
    },
    iceOMaticStates: {
        transformResponse: async ({ _clientResponse, _widgetScope, _rootScope }) => {
            let result = [{
                productID: "Making Ice",
                name: "makingIce",
                color: "#0d81b9",
                count: 0,
                counts: 0
            }, {
                productID: "Bin Full",
                name: "binFull",
                color: "#00ad41",
                count: 0,
                counts: 0
            }, {
                productID: "Unit Off",
                name: "unitOff",
                color: "#ffffff",
                count: 0,
                counts: 0
            }, {
                productID: "Standby",
                name: "standby",
                color: "#eacd61",
                count: 0,
                counts: 0
            }];

            let current = _clientResponse?.data?.data?.data?.length ? _clientResponse.data.data.data : [];

            let offData = _clientResponse?.data?.data?.systemBootData?.length ? _clientResponse.data.data.systemBootData : [];
            offData = await handleSystemBootData(offData, _rootScope)
            let data = [...current, ...offData];

            const requestToDate = moment(_widgetScope.dateFilterValue?.toDate).endOf('day');
            const requestFromDate = moment(_widgetScope.dateFilterValue?.fromDate).startOf('day');

            data = await handleData(data, requestToDate, requestFromDate);
            data = await getDuration(data);

            data = _.groupBy(data, 'eventType')
            const keys = Object.keys(data)
            for (let i = 0; i < keys.length; i++) {
                let typeData = data[keys[i]];
                let duration = 0
                for (let j = 0; j < typeData.length; j++) {
                    duration = duration + typeData[j].duration
                }

                // Set Duration
                for (let k = 0; k < result.length; k++) {
                    if (result[k].name == keys[i]) result[k].count = duration
                }
            }
            // Find Average
            const totalDuration = result.reduce((acc, data) => acc + data.count, 0);

            if (totalDuration > 0) {
                for (let i = 0; i < result.length; i++) {
                    if (result[i]?.count > 0 && totalDuration > 0) {
                        let avg = (result[i].count / totalDuration) * 100
                        result[i].counts = Number(toFixed(avg,1))
                    }
                }
            } else {
                result = []
            }
            return {
                data: {
                    value: result,
                    streamData: result
                }
            }
        }
    },
    liveDiagnostic: {
        // add live diagnostic widget functions here
    }
};

const toFixed = (n, fixed) => `${n}`.match(new RegExp(`^-?\\d+(?:\.\\d{0,${fixed}})?`))[0];

const getDuration = async (data) => {
    for (let i = 0; i < data.length; i++) {
        if (data[i].eventType == "unitOff") {
            data[i].duration = moment.duration(moment(data[i].toDate).diff(moment(data[i].fromDate))).asSeconds();
        }
    }
    return data;
}

const handleSystemBootData = async (data, _rootScope) => {
    data = _.sortBy(data, 'fromDate');
    for (let i = 0; i < data.length; i++) {
        if (data[i].fromDate) data[i].fromDate = formatTimeWithTimezoneDate(data[i].fromDate, _rootScope.unitDetails.TimeZone_Standard, 'YYYY-MM-DD HH:mm:ss')
        if (data[i].toDate) data[i].toDate = formatTimeWithTimezoneDate(data[i].toDate, _rootScope.unitDetails.TimeZone_Standard, 'YYYY-MM-DD HH:mm:ss')
    }
    const result = [];
    for (let i = 0; i < data.length - 1; i++) {
        if (data[i].toDate && data[i + 1].fromDate) {
            const event = {
                fromDate: data[i].toDate,
                toDate: data[i + 1].fromDate,
                eventType: "unitOff"
            }
            result.push(event)
        }
    }
    return result
}


const handleStatesVsTime = async (newData, requestToDate, requestFromDate) => {
    let result = newData.length ? await handleData(newData, requestToDate, requestFromDate) : [];
    result = result.length ? await mergeMakingIce(newData) : [];
    let value = [];
    for (let index = 0; index < result.length; index++) {
        const element = result[index];
        value.push({
            x: {
                from: element?.fromDate ? getDateFromISOString(element.fromDate) : "",
                to: element?.toDate ? getDateFromISOString(element.toDate) : "",
            },
            y: "",
            stack: element.eventType,
        })
    }
    return value;
}

const handleData = async (newData, requestToDate, requestFromDate) => {
    for (let i = 0; i < newData.length; i++) {

        // Validate Event
        if(newData[i].fromDate <= requestFromDate) {
            newData[i].fromDate = requestFromDate
        }
        if(newData[i].toDate >= requestToDate) {
            newData[i].toDate = requestToDate
        }

        // Define Event Type
        if (newData[i].eventType == 'freeze' || newData[i].eventType == 'harvest') {
            newData[i].eventType = 'makingIce'
        } else if (newData[i].eventType == 'offState') {
            newData[i].eventType = 'standby'
        }
    }
    return newData
}

const mergeMakingIce = async (data) => {
    const result = []
    data.forEach((item, i) => {
        if (data[i + 1] && data[i + 1].eventType == 'makingIce' && item.eventType == 'makingIce') {
            let diff = moment.duration(moment(data[i + 1].fromDate).diff(moment(item.toDate))).asSeconds();
            if (diff < 60) {
                data[i + 1].fromDate = item.fromDate
                item.toRemove = true
            }
        }
    })
    for (let i = 0; i < data.length; i++) {
        if (!data[i].toRemove) result.push(data[i])
    }
    return result;
}

export default clientFunctions;