import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import http from '../../../service/httpService';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { TableHeader } from '../../controls/tableHeader';
import { MenuUploadAPI } from '../../../service/api';
import { CUSTOMER_ID, BRAND_ID, BASECOUNTRY_ID } from '../../../utils/appConstants';
import _ from 'lodash';
import ErrorMoal from '../../modal/error-model';
import SuccessMoal from '../../modal/success-model';
import * as moment from 'moment';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import NoRecordsFound from '../../controls/noRecordsFound';
import { Paginator } from '../../controls/paginator';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import { injectIntl, FormattedMessage } from 'react-intl';
import { HOVER_TEXT_SCHEDULED, HOVER_TEXT_QUEUED, HOVER_TEXT_UPDATING, HOVER_TEXT_PENDING, HOVER_TEXT_EXPIRED, HOVER_TEXT_COMPLETED, HOVER_TEXT_CANCELED } from '../../../utils/appConstants';
import { datePickerLocale } from '../../../locale/constant';
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { searchVisible, searchText, clearSearchText } from '../../../actions/advanceSearch/advanceSearchAction';
import { isEqualObj, searchData_function } from '../../globalSearchBar/selectOption';
import GoToPagination from '../../controls/goToPagination';

let todaysDate = new Date();
let Yesterdaydate = new Date(todaysDate.setDate(todaysDate.getDate()));
let sixMonthsFromNow = new Date(todaysDate.setMonth(todaysDate.getMonth() - 6));
sixMonthsFromNow = sixMonthsFromNow.setDate(sixMonthsFromNow.getDate() - 1);
let savestartDate = new Date(todaysDate.setMonth(todaysDate.getMonth() + 5));
let firstTimeDate = savestartDate;
firstTimeDate = firstTimeDate.setDate(firstTimeDate.getDate() - 1);
let saveendDate = Yesterdaydate;

class menuUpdateStatus extends Component {
	constructor(props) {
		super(props);
		this.state = {
			menuDistributionList: [],
			loaderIconVisible: false,
			sortColumn: { path: "", order: "", sort_color: "#63778a" },
			error_modal: {
				open: false,
				message: ""
			},
			success_modal: {
				open: false,
				message: ""
			},
			isClearAllEnabled: false,
			startDate: localStorage.getItem('startDate') ? moment(localStorage.getItem('startDate')).toDate() : firstTimeDate,
			endDate: localStorage.getItem('endDate') ? moment(localStorage.getItem('endDate')).toDate() : Yesterdaydate,
			maxdate: Yesterdaydate,
			minDate: sixMonthsFromNow,
			currentPage: 1,
			pageSize: 25,
			totalPages: null,
			distributionCounts: [],
			Scheduled: true,
			Queued: true,
			Updating: true,
			Pending: true,
			Completed: true,
			Canceled: true,
			Failed: true,
			searchData: null,
			showToast: false,
			firstLoad: false
		};
		this.OnDeleteDistribution = this.OnDeleteDistribution.bind(this);
		this.OnCancelDeleteDistribution = this.OnCancelDeleteDistribution.bind(this);
		this.OnTryAgain = this.OnTryAgain.bind(this);
	}

	updateDimension = () => {
		const windowHeight = $(window).height();
		const $tableBody = $('.statusTableSrlBar');
		const $tableBodyBottom = $('.paginationDWgoto, .paginationWgoto').height() ? $('.paginationDWgoto, .paginationWgoto').height() + 18 : 0;
		$tableBody.css('height', windowHeight - ($tableBody.offset().top + $tableBodyBottom));
	}
	componentDidUpdate(prevState) {
		this.updateDimension();
		let { searchData } = prevState;
		let curSearchData = this.state && this.state.searchData;
		if (curSearchData && (isEqualObj(searchData, curSearchData) === false)) {
			this.setState({
				currentPage: 1
			}, () => {
				this.getMenuUpdate(1);
				this.getCount();
			})
		}
	}

	static getDerivedStateFromProps = (props) => ({ ...props });

	componentDidMount() {
		this.setState({
			firstLoad: true
		});
		window.addEventListener('resize', this.updateDimension);
		document.body.style.backgroundColor = "#F2F2F2";
		this.props.searchVisible(true);
		this.getMenuUpdate().catch(err => {
			let { response, message } = err;
			if (response || message) {
				let { data } = response || { data: { message } };
				if (data) {
					//To avoid the two popup at the same time
					if (data.errorCode && data.errorCode === '403100') {
						this.setState({
							loaderIconVisible: false,
						}, () => {
						});
						return;
					}
					this.setState({
						loaderIconVisible: false,
						error_modal: {
							open: true,
							message: data.message ? data.stringID || data.message : ""
						}
					});
				}
			}
			else {
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: "KC1083"
					}
				});
			}
		});
		this.interval = setInterval(() => { this.getCount(); this.getMenuUpdate() }, 40000);
		this.getCount();
	}

	getMenuUpdate = () => {
		this.setState({ loaderIconVisible: true });
		let custGroupID = localStorage.getItem("custGroupID");
		let cgids = "";
		if (custGroupID !== "" && custGroupID !== undefined) {
			cgids = custGroupID;
		}

		// User Story 48542 - Added this to show the selected status items
		let { Scheduled, Queued, Updating, Pending, Completed, Failed, Canceled } = this.state;
		let filtertypeVa = "";
		if (Scheduled) {
			if (filtertypeVa) {
				filtertypeVa = filtertypeVa + "," + "Scheduled";
			} else {
				filtertypeVa = "Scheduled";
			}
		}

		if (Queued) {
			if (filtertypeVa) {
				filtertypeVa = filtertypeVa + "," + "Queued";
			} else {
				filtertypeVa = "Queued";
			}
		}

		if (Updating || Pending) {
			if (filtertypeVa) {
				filtertypeVa = filtertypeVa + "," + "IN_PROGRESS";
			} else {
				filtertypeVa = "IN_PROGRESS";
			}
		}

		if (Completed) {
			if (filtertypeVa) {
				filtertypeVa = filtertypeVa + "," + "SUCCEEDED";
			} else {
				filtertypeVa = "SUCCEEDED";
			}
		}

		if (Canceled) {
			if (filtertypeVa) {
				filtertypeVa = filtertypeVa + "," + "CANCELED";
			} else {
				filtertypeVa = "CANCELED";
			}
		}

		if (Failed) {
			if (filtertypeVa) {
				filtertypeVa = filtertypeVa + "," + "REJECTED,FAILED";
			} else {
				filtertypeVa = "REJECTED,FAILED";
			}
		}

		let url = MenuUploadAPI.getMenuUpdateStatus;
		let pageNumber = '?pageNum=' + this.state.currentPage;
		let pageLimit = '&pageSize=' + this.state.pageSize;

		url = url + pageNumber + pageLimit;

		let { searchData } = this.state;
		//searchData_function
		let _search = searchData_function(searchData);
		if (_search) {
			url = `${url}${_search}`;
		}

		return new Promise((resolve, reject) => {
			http.get(url, {
				headers: {
					'Content-Type': 'application/json',
					customerId: CUSTOMER_ID,
					brandId: BRAND_ID,
					'cgids': cgids,
					// 'basecountry': BASECOUNTRY_ID,
					'filtertype': filtertypeVa ? filtertypeVa : "UNSELECTALL",
					'startdate': this.state.startDate ? format(this.state.startDate, 'YYYY-MM-DD') : "",
					'enddate': this.state.endDate ? format(this.state.endDate, 'YYYY-MM-DD') : "",
				}, data: {}
			}).then(response => {
				if (response && response.data) {
					let succededAssignments = [];
					if (response.data.menuDistributeResponse && response.data.menuDistributeResponse.menuDistributeList.length) {
						succededAssignments = response.data.menuDistributeResponse.menuDistributeList.filter(a => a.DISTRIBUTION_STATE === "SUCCEEDED")
					}
					this.setState({
						loaderIconVisible: false,
						menuDistributionList: response.data.menuDistributeResponse,
						isClearAllEnabled: succededAssignments.length > 0 ? true : false,
						totalPages: response.data.menuDistributeResponse && response.data.menuDistributeResponse.numberOfPages ? response.data.menuDistributeResponse.numberOfPages : 0,
					}, () => {
						if (this.props.location.state && this.props.location.state.showToast && this.state.firstLoad) {
							this.setState({ showToast: true, firstLoad: false });
							setTimeout(() => {
								this.setState({ showToast: false });
							}, 5000);
						}
					});
					// this.getCount();
					resolve(response.data);
				}
			}).catch(err => {
				this.setState({ loaderIconVisible: false, totalPages: 0, menuDistributionList: [] });
				reject(err);
			});
		});
	}

	// User Story 48542 - Added getcount to update the count of items per status of all pages
	getCount = async () => {
		this.setState({ loaderIconVisible: true });
		let custGroupID = localStorage.getItem("custGroupID");
		let cgids = "";
		if (custGroupID !== "" && custGroupID !== undefined) {
			cgids = custGroupID;
		}

		let filtertypeVa = "Scheduled,Queued,IN_PROGRESS,SUCCEEDED,REJECTED,FAILED,CANCELED";

		let headerVal = {
			'Content-Type': 'application/json',
			// 'basecountry': BASECOUNTRY_ID,
			customerId: CUSTOMER_ID,
			brandId: BRAND_ID,
			'cgids': cgids,
			"filtertype": filtertypeVa,
			'startdate': this.state.startDate ? format(this.state.startDate, 'YYYY-MM-DD') : "",
			'enddate': this.state.endDate ? format(this.state.endDate, 'YYYY-MM-DD') : "",
		};

		let url = MenuUploadAPI.getMenuUpdateStatus;
		let { searchData } = this.state;
		//searchData_function
		let _search = searchData_function(searchData);
		if (_search) {
			url = `${url}?${_search}`;
		}
		http.get(url, {
			headers: headerVal,
			data: {}
		}).then(response => {
			if (response && response.data) {
				this.setState({
					distributionCounts: response.data.menuDistributeResponse.statusCount
				});
			}
		}).catch(err => {
			this.setState({
				distributionCounts: []
			});
		});
	}

	componentWillUnmount() {
		clearInterval(this.interval);
		localStorage.removeItem('startDate');
		localStorage.removeItem('endDate');
		this.props.searchText({});
		this.props.searchVisible(false);
	}

	onSort = sortColumn => {
		this.setState({
			sortColumn: sortColumn
		});
	}

	closeModel = (err) => {
		this.setState({
			loaderIconVisible: false,
			error_modal: {
				open: false,
				message: ""
			}
		});
	}

	OnCancelDeleteDistribution = () => {

	}

	OnDeleteDistribution = () => {
		this.setState({
			loaderIconVisible: true
		})
		let successfulAssingments = this.getAllSuccessfulAssignments();
		let postData = {
			distributionIds: successfulAssingments
		}

		this.putData(postData, MenuUploadAPI.getMenuUpdateStatus, "DELETE").then(response => {
			if (response && response.data && response.data.message) {
				if (response.data.httpCode === "HTTP_200") {
					this.setState({
						loaderIconVisible: false,
						success_modal: {
							open: true,
							message: "KC1123"
						}
					});
				}
			}
		}).catch(err => {
			this.showError(err);
		});
	}

	OnTryAgain = (e, distributionID) => {
		this.setState({
			loaderIconVisible: true
		})

		let postData = {
			distributionId: distributionID
		}

		this.putData(postData, MenuUploadAPI.retryMenuUpdateStatus, "POST").then(response => {
			if (response && response.data && response.data.message) {
				if (response.data.httpCode === "HTTP_201") {
					this.setState({
						loaderIconVisible: false,
						success_modal: {
							open: true,
							message: "KC1122"
						}
					});
				}
			}
		}).catch(err => {
			this.showError(err);
		});
	}

	onSuccessCloseModal = () => {
		this.setState({
			loaderIconVisible: false,
			success_modal: {
				open: false
			}
		})
		this.getMenuUpdate();
		this.getCount();
	}

	putData = (postData, url, method) => {
		this.setState({
			loaderIconVisible: true
		});
		return new Promise((resolve, reject) => {
			http({
				method: method,
				url: url,
				headers: {},
				data: postData
			}).then(res => {
				let { data } = res;
				let { httpCode } = data;
				if (httpCode !== "HTTP_201") {
					reject({
						response: {
							data: res.data
						}
					});
				} else {
					resolve(res);
				}
			}).catch(err => {
				reject(err);
			});
		});
	}

	showError = (err) => {
		let { response } = err;
		if (response) {
			let { data } = response;
			if (data) {
				let { body } = data;
				if (body) {
					this.setState({
						loaderIconVisible: false,
						error_modal: {
							open: true,
							message: body.stringID || body.message
						}
					});
				}
			}
		} else {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: "KC1065"
				}
			});
		}
	}

	getAllSuccessfulAssignments = () => {
		let menuDistributionList = [...this.state.menuDistributionList.menuDistributeList];
		let successfulAssingments = [];
		if (menuDistributionList && menuDistributionList.length > 0) {
			for (let index = 0; index < menuDistributionList.length; index++) {
				if (menuDistributionList[index].DISTRIBUTION_STATE === "SUCCEEDED") {
					successfulAssingments.push(menuDistributionList[index].ID);
				}
			}
		}
		return successfulAssingments;
	}

	ResetDatepicker = (e) => {
		e.preventDefault();
		return false;
	}

	handleChangestart = (date) => {
		savestartDate = date;
		this.setState({
			startDate: date,
			currentPage: 1
		}, () => {
			localStorage.setItem('startDate', date);
			if (savestartDate > saveendDate) {
				this.setState({
					error_modal: {
						open: true,
						message: "KC1581"
					}
				});
			} else {
				this.setState({
					startDate: date
				}, () => {
					this.getMenuUpdate(date, this.state.endDate);
					this.getCount();
				});
			}
		});
	}

	handleChangeend = (date) => {
		saveendDate = date;
		this.setState({
			endDate: date,
			currentPage: 1
		}, () => {
			localStorage.setItem('endDate', date);
			if (savestartDate > saveendDate) {
				this.setState({
					error_modal: {
						open: true,
						message: "KC1120"
					}
				});
			} else {
				WoopraEvents(`${Constants.DATE_APPLIED}`);
				this.setState({
					endDate: date
				}, () => {
					this.getMenuUpdate(this.state.startDate, date);
					this.getCount();
				});
			}
		});
	}

	/**
	 * When navigate new page this event will return current page number
	 * @param {*} currentPage 
	 */
	onPageChange = (currentPage) => {
		this.setState({ currentPage, loaderIconVisible: true }, () => {
			this.getMenuUpdate();
			window.scrollTo({
				top: 0,
				behavior: "smooth"
			});
		});
	}

	// Woopra Event
	trackEvent = (e) => {
		WoopraEvents(`${Constants.MENU_AND_UNITSETTING}-${e.trackTitle}`);
	}

	// User Story 48542 - Added this to show the selected status items
	checkBoxHandler = (e) => {
		let isChecked = e && e.target && e.target.checked;
		let selCheckboxName = e && e.target && e.target.value;
		this.setState({
			[e.target.value]: isChecked,
			currentPage: 1
		}, () => {
			this.getMenuUpdate();
		})
	}

	clearGlobalSearch = () => {
		this.props.clearSearchText();
	}

	closeToast = () => {
		this.setState({ showToast: false });
	}

	render() {

		const { formatMessage } = this.props.intl;

		let { menuDistributionList, loaderIconVisible, sortColumn, error_modal, success_modal, isClearAllEnabled, currentPage, pageSize, distributionCounts } = this.state;
		let updatequeued = 0;
		let updating = 0;
		let updatecomplete = 0;
		let updatefailed = 0;
		let scheduled = 0;
		let pending = 0;
		let canceled = 0;

		let { Scheduled, Queued, Updating, Pending, Completed, Failed, Canceled } = this.state;

		let columns = [
			{ path: "", label: "", isNbSp: true },
			{ path: "", label: "#" },
			{ path: "", labelStringID: "KC0102" },
			{ path: "BRAND", labelStringID: "KC0062" },
			{ path: "ModelName", labelStringID: "KC0064" },
			{ path: "UNITID", labelStringID: "KC0104" },
			{ path: "locationName", labelStringID: "KC0050" },
			{ path: "DISTRIBUTION_DATE_TIME", labelStringID: "KC0697" },
			{ path: "IS_TEST_PUSH", labelStringID: "KC0698" },
			{ path: "", labelStringID: "KC0523" },
			{ path: "", label: "", isNbSp: true },
		];

		let menuDistributionUpdateList = menuDistributionList && menuDistributionList.menuDistributeList &&
			menuDistributionList.menuDistributeList.length > 0 ? menuDistributionList.menuDistributeList : [];

		// User Story 48542 - Added this to update the count of items per status of all pages
		if (distributionCounts) {
			scheduled = distributionCounts.Scheduled ? distributionCounts.Scheduled : 0;
			updatequeued = distributionCounts.Queued ? distributionCounts.Queued : 0;
			updating = distributionCounts.IN_PROGRESS ? distributionCounts.IN_PROGRESS : 0;
			pending = distributionCounts.IN_PROGRESS ? distributionCounts.IN_PROGRESS : 0;
			updatecomplete = distributionCounts.SUCCEEDED ? distributionCounts.SUCCEEDED : 0;
			updatefailed = distributionCounts.FAILED ? distributionCounts.FAILED : 0;
			canceled = distributionCounts.CANCELED ? distributionCounts.CANCELED : 0;
		}

		if (sortColumn.path === "DISTRIBUTION_DATE_TIME") {
			if (sortColumn.order === "desc") {
				menuDistributionUpdateList = menuDistributionUpdateList.sort((d1, d2) => new Date(d2.DISTRIBUTION_DATE_TIME) - new Date(d1.DISTRIBUTION_DATE_TIME));
			}
			else {
				menuDistributionUpdateList = menuDistributionUpdateList.sort((d1, d2) => new Date(d1.DISTRIBUTION_DATE_TIME) - new Date(d2.DISTRIBUTION_DATE_TIME));
			}
		}
		else {
			menuDistributionUpdateList = _.orderBy(menuDistributionUpdateList, [sortColumn.path], [sortColumn.order]);
		}
		let rowNumber = (currentPage * pageSize) - pageSize;

		return (

			<div id="adminWrapper">

				<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
					<div className="loadingProgressIn"></div>
				</div>

				<div id="page-content-wrapper">
					<div className="menuUpdateWrapper">
						<div className="menuFilterHeader menuFHlast">
							<ul>
								<li>
									<Link to="/menuBrands" title={formatMessage({ id: 'KC0661' })} onClick={(e) => { e.trackTitle = "Brands"; this.trackEvent(e) }}><FormattedMessage id="KC0661" /></Link>
									<Link to="/menuUpdateStatus" title={formatMessage({ id: 'KC0659' })} className="active"><FormattedMessage id="KC0659" /></Link>
									<Link to="/menuApps" title={formatMessage({ id: 'KC0660' })} onClick={(e) => { e.trackTitle = "Apps"; this.trackEvent(e) }}><FormattedMessage id="KC0660" /></Link>
								</li>
								<li>&nbsp;</li>
							</ul>
						</div>
						<div className="selectedUnitHeader menuAssignmentStatus">
							<ul>
								<li>
									<div class="checkBoxListGrp">
										<div class="customCheckbox crticalCBox">
											<input onClick={(e) => this.checkBoxHandler(e)} id="Scheduled" type="checkbox" value="Scheduled" checked={Scheduled} />
											<label for="Scheduled" title={formatMessage({ id: HOVER_TEXT_SCHEDULED })}><FormattedMessage id="KC0573" /> <span> {scheduled}</span></label>
										</div>
										<div class="customCheckbox nonCrticalCBox">
											<input onClick={(e) => this.checkBoxHandler(e)} id="Queued" type="checkbox" value="Queued" checked={Queued} />
											<label for="Queued" title={formatMessage({ id: HOVER_TEXT_QUEUED })}><FormattedMessage id="KC0691" /> <span>{updatequeued}</span></label>
										</div>
										<div class="customCheckbox">
											<input onClick={(e) => this.checkBoxHandler(e)} id="Updating" type="checkbox" value="Updating" checked={Updating} />
											<label for="Updating" title={formatMessage({ id: HOVER_TEXT_UPDATING })}><FormattedMessage id="KC0692" /> <span>{updating}</span></label>
										</div>
										<div class="customCheckbox">
											<input onClick={(e) => this.checkBoxHandler(e)} id="Pending" type="checkbox" value="Pending" checked={Pending} />
											<label for="Pending" title={formatMessage({ id: HOVER_TEXT_PENDING })}><FormattedMessage id="KC0693" /> <span>{pending}</span></label>
										</div>
										<div class="customCheckbox">
											<input onClick={(e) => this.checkBoxHandler(e)} id="Failed" type="checkbox" value="Failed" checked={Failed} />
											<label for="Failed" title={formatMessage({ id: HOVER_TEXT_EXPIRED })}><FormattedMessage id="KC0359" /> <span>{updatefailed}</span></label>
										</div>
										<div class="customCheckbox">
											<input onClick={(e) => this.checkBoxHandler(e)} id="Completed" type="checkbox" value="Completed" checked={Completed} />
											<label for="Completed" title={formatMessage({ id: HOVER_TEXT_COMPLETED })}><FormattedMessage id="KC0324" /> <span>{updatecomplete}</span></label>
										</div>
										<div class="customCheckbox">
											<input onClick={(e) => this.checkBoxHandler(e)} id="Canceled" type="checkbox" value="Canceled" checked={Canceled} />
											<label for="Canceled" title={formatMessage({ id: HOVER_TEXT_CANCELED })}><FormattedMessage id="KC0694" /> <span>{canceled}</span></label>
										</div>
									</div>
								</li>
								<li>
									{isClearAllEnabled ? <button class="statusClearAll" title={formatMessage({ id: 'KC0688' })} data-toggle="modal" data-target="#confirmationDeleteModal"><FormattedMessage id="KC0688" /></button> : ""}
									<div class="dateRangeForm">
										<span><FormattedMessage id="KC2001" />:</span>
										<DatePicker locale={datePickerLocale} autoComplete="off" id="startdate" showMonthDropdown showYearDropdown dropdownMode="select" onKeyDown={(e) => this.ResetDatepicker(e)} selected={this.state.startDate} onChange={this.handleChangestart} minDate={this.state.minDate} maxDate={this.state.maxdate} />
										<span><FormattedMessage id="KC2072" />:</span>
										<DatePicker locale={datePickerLocale} popperModifiers={{ preventOverflow: { enabled: true, }, }} autoComplete="off" id="enddate" showMonthDropdown showYearDropdown dropdownMode="select" onKeyDown={(e) => this.ResetDatepicker(e)} selected={this.state.endDate} onChange={this.handleChangeend} minDate={this.state.minDate} maxDate={this.state.maxdate} />
									</div>
								</li>
							</ul>
						</div>
						<div className="menuAStatusTableOuter">
							<div className="menuAStatusTable">
								<TableHeader
									menuUpdateScreen={true}
									sortColumn={sortColumn}
									onSort={this.onSort}
									columns={columns} />
							</div>
							<div className='statusTableSrlBar'>
								<div className="panel panel-default" id="availableUpdatesAccordion" role="tablist" aria-multiselectable="true">
									{/* <!-- Header 01 --> */}
									{menuDistributionUpdateList.map((item, j) => {
										return (
											<React.Fragment key={j}>
												<div className="menuAStatusTable">
													<ul className="tBody">
														<li><a role="button" data-toggle="collapse" draggable="false" data-parent="#availableUpdatesAccordion" data-target={`#availableUpdatesAcc` + item.ID} aria-expanded="false" aria-controls={`#availableUpdatesAcc` + item.ID} className="btn collapseExpand collapsed">&nbsp;</a></li>
														<li>{rowNumber + j + 1}</li>
														<li><span className="menuType">{item.TYPE && item.TYPE.toLowerCase() === "recipe" ? (<img src={require("../../../images/icons/recipeS.png")} alt="" ></img>) : item.TYPE && item.TYPE.toLowerCase() === "cookbook" ? (<img src={require("../../../images/icons/manualS.png")} alt="" ></img>) : item.TYPE && item.TYPE.toLowerCase() === "settings" ? (<img src={require("../../../images/icons/settingS.png")} alt="" ></img>) : (<img src={require("../../../images/icons/duplicateS.svg")} alt="" ></img>)}</span></li>
														<li>{item.BRAND}
															<span className="menuAStatusSM">{item.ModelName}</span>
															<div className="menuAStatusSB">
																<div className="menuAStatusD">{moment(item.DISTRIBUTION_DATE_TIME, 'MMMM DD, YYYY hh:mm A').format('MMM. DD, YYYY, hh:mm A')}</div>
																{item.retriable === true ? <button type="button" class="btn btn-danger" onClick={(e) => this.OnTryAgain(e, item.ID)}>Try Again</button>
																	: <div className="progress">
																		<div className={item.DISTRIBUTION_STATE === 'REJECTED' ? "progress-bar warningStatus" : "progress-bar completedStatus"} style={{ width: item.DISTRIBUTION_PROGRESS + '%' }}></div>
																	</div>}
															</div>
														</li>
														<li>{item.ModelName}</li>
														<li>{item.UNITID}</li>
														<li>{item.locationName}</li>
														<li>{moment(item.DISTRIBUTION_DATE_TIME, 'MMMM DD, YYYY hh:mm A').format('MMM. DD, YYYY, hh:mm A')}</li>
														<li>{item.IS_TEST_PUSH ? <img src={require('../../../images/icons/confirmGreen.svg').default} alt="" /> : ""}</li>
														<li>
															<img src={item.DISTRIBUTION_STATE === 'FAILED' ? require('../../../images/icons/ErrorD.svg').default : item.DISTRIBUTION_STATE === 'REJECTED' ? require('../../../images/icons/ErrorD.svg').default : item.DISTRIBUTION_STATE === 'Scheduled' ? require('../../../images/icons/clockS.svg').default : item.DISTRIBUTION_STATE === 'SUCCEEDED' ? require('../../../images/icons/confirmGreen.svg').default : item.DISTRIBUTION_STATE.toUpperCase() === 'CANCELED' ? require('../../../images/icons/cancelIcon.png') : require('../../../images/icons/UpdateD.svg').default} alt="" />
														</li>
														<li>
															{item.retriable === true ? <button type="button" class="btn btn-danger" onClick={(e) => this.OnTryAgain(e, item.ID)}><FormattedMessage id="KC1117" /></button>
																: <div className="progress">
																	<div className={item.DISTRIBUTION_STATE === 'REJECTED' ? "progress-bar warningStatus" : "progress-bar completedStatus"} style={{ width: item.DISTRIBUTION_PROGRESS + '%' }}></div>
																</div>}
														</li>
													</ul>
												</div>
												{/* <!-- Header 01 Content --> */}
												<div id={`availableUpdatesAcc` + item.ID} className="panel-collapse collapse" role="tabpanel" aria-labelledby={`availableUpdatesAcc` + item.ID}>
													<div class="panel-body menuAStatusExpTable">
														<ul className="tBody">
															{localStorage.getItem('brandName') !== "GARLAND" && item.libraryList && item.libraryList.length > 0 ?
																<React.Fragment>
																	<li>&nbsp;</li>
																	<li>
																		{item.libraryList.map((items, i) =>
																			<div className="menuAExpTableOuter">
																				<div className="menuAExpTable">
																					<ul>
																						<li>{items.TYPE && items.TYPE.toLowerCase() === "recipe" ? (<img src={require("../../../images/icons/recipeA.png")} alt="" ></img>) : items.TYPE && items.TYPE.toLowerCase() === "cookbook" ? (<img src={require("../../../images/icons/manualA.png")} alt="" ></img>) : (<img src={require("../../../images/icons/settingsA.png")} alt="" ></img>)}</li>
																						<li>
																							<h4 title={items.LIBRARY_NAME}>{items.LIBRARY_NAME}</h4>
																						</li>
																					</ul>
																				</div>
																			</div>
																		)}
																	</li>
																	<li>
																		<h4><FormattedMessage id="KC1116" /></h4>
																		<h5>{item.libraryList && item.libraryList.length > 0 ? item.libraryList.reduce((prev, cur) => prev + parseFloat(cur.LIBRARY_FILE_SIZE), 0).toFixed(1) : 0} <FormattedMessage id="KC1498" /></h5>
																	</li>
																</React.Fragment>
																: item.menuList.map((items, i) =>
																	<React.Fragment key={`items_${i}`}>
																		<li>&nbsp;</li>
																		<li>
																			<div className="menuAExpTableOuter">
																				<div className="menuAExpTable">
																					<ul>
																						<li>{items.FILE_FORMAT && items.FILE_FORMAT.toLowerCase() === ".cbr" ? (<img src={require("../../../images/merrychef/menuIcon.png")} alt="" ></img>) : items.FILE_FORMAT && items.FILE_FORMAT.toLowerCase() === ".sdf" ? (<img src={require("../../../images/convotherm/menuSDF.png")} alt="" ></img>) : items.FILE_FORMAT && items.FILE_FORMAT.toLowerCase() === ".db" ? (<img src={require("../../../images/convotherm/menuDB.png")} alt="" ></img>) : (<img src={require("../../../images/merco/menuIcon.png")} alt="" ></img>)}</li>
																						<li>
																							<h4>{items.FILE_NAME}</h4>
																							<h5>{items.UPDATED_DATE}</h5>
																						</li>
																					</ul>
																				</div>
																			</div>
																		</li>
																		<li>&nbsp;</li>
																	</React.Fragment>
																)}
														</ul>
													</div>
												</div>
											</React.Fragment>
										)
									})
									}
								</div>
								<NoRecordsFound loaderIconVisible={loaderIconVisible} length={menuDistributionUpdateList !== undefined ? menuDistributionUpdateList.length : 0} classname={'norecordsfoundbluetext'} />
							</div>
							{/* <Paginator
								pageNumber={this.state.currentPage}
								pageCount={this.state.totalPages}
								onNextPage={(p) => this.onPageChange(p)}
								onPreviousPage={(p) => this.onPageChange(p)}
								applyClass="tablePagination"
							/> */}
							{this.state.totalPages && this.state.totalPages > 1 ? <GoToPagination
								totalPageNumber={this.state.totalPages}
								pageNumber={this.state.currentPage}
								navigateTo={(p) => this.onPageChange(p)} /> : null}
						</div>
					</div>
					<ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
					<SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
				</div>
				{/* <!-- Delete Confirmation Modal --> */}
				<div className="modal fade confirmationDeleteModal" data-keyboard="true" data-backdrop="static" id="confirmationDeleteModal" tabindex="-1" role="dialog" aria-labelledby="confirmationDeleteModalLabel">
					<div className="modal-dialog modal-md">
						<div className="modal-content">
							<div className="modal-body">
								<div className="deleteConfirmation">
									<FormattedMessage id="KC1115" />
								</div>
							</div>
							<div className="modal-footer textAlignCenter">
								<button className="btn btn-secondary deleteNoBtn" onClick={() => this.OnCancelDeleteDistribution()} data-dismiss="modal" type="button"><FormattedMessage id="KC0580" /></button>
								<button className="btn btn-secondary deleteYesBtn" onClick={() => this.OnDeleteDistribution()} data-dismiss="modal" type="button"><FormattedMessage id="KC0611" /></button>
							</div>
						</div>
					</div>
				</div>
				{/* <!-- //Delete Confirmation --> */}
				<UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
				{/* Toast Notification */}
				{this.state.showToast && (
					<div className="alert toasMsgAlert alert-dismissible fade show" role="alert">
						<span className="toasMsg">{formatMessage({ id: "KC2928" }).replace("{{number}}",this.props.location.state.totalUnitsCount)}</span>
						<button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={this.closeToast}>&nbsp;</button>
					</div>
				)}
			</div>
		)
	}
};

function mapStateToProps(state) {
	return {
		searchData: state.advanceSearch.searchData
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		searchVisible, searchText, clearSearchText
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(menuUpdateStatus));