import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { UnitInfo, MenuUploadAPI, smartTagMangement } from '../../../service/api';
import { UnitBuilder } from '../../../utils/unitResponseBuilder';
import http from '../../../service/httpService'
import $ from 'jquery';
import _ from 'lodash';
import { TableHeader } from '../../controls/tableHeader';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import uploadMenuToCloud from './menuUpload';
import { BRAND_ID_VALUE, CUSTOMER_ID, BRAND_ID, BASECOUNTRY_ID, unitSelectionAllowOnTransfer } from '../../../utils/appConstants';
import appconstants from '../../../utils/appConstants';
import authService from '../../../service/authService';
import Tooltip from 'react-tooltip-lite';
import OfflineMediaImg from '../../../images/icons/offlineT.svg';
import NoRecordsFound from '../../controls/noRecordsFound';
import { injectIntl, FormattedMessage } from 'react-intl';
import TagOrgModal from '../smartTag/tagOrgModel';
import OrgTagsDetail from '../smartTag/orgTagsDetail';
import SmartTagDrop from '../../controls/smartTagDragDrop';
import moment from '../../../utils/momentHelper.js';
import GenericModal from '../../modal/generic-model';
import { searchVisible, searchText, clearSearchText } from '../../../actions/advanceSearch/advanceSearchAction';
import { isEqualObj } from '../../globalSearchBar/selectOption';

class menuAssignUpdateList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loaderIconVisible: false,
			data: [],
			unitsSelected: 0,
			unitsOffline: 0,
			userSelectAll: false,
			isIndeterminateSelect: false,
			userChecked: [],
			userCheckedData: [],
			sortColumn: { path: "MODEL_NAME", order: "asc", sort_color: "#fff" },
			total_unit_count: 0,
			success_modal: {
				open: false,
				message: ""
			},
			error_modal: {
				open: false,
				message: ""
			},
			scheduleDateTimeUtc: "",
			Type: false,
			isMenuUploadSuccess: false,
			isFlagUpdated: false,
			showpopup: [],
			showOrgPopup: false,
			type: "orgList",
			showStPopUp: false,
			selUnitDetails: {},
			isSelectionHappen: false,
			generic_modal: {
				open: false,
				message: "",
				messagetype: ""
			},
			serialNumber: "",
			isError: false,
			totalUnitsCount: 0,
			enteredUnits: '',
			isInputValid: false
		};
		this.menuUpdate = this.menuUpdate.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSingleCheckboxChange = this.handleSingleCheckboxChange.bind(this);
		this.onGenericCloseModal = this.onGenericCloseModal.bind(this);
		this.handleUnitsChange = this.handleUnitsChange.bind(this);
	}

	menuUpdate = () => {
		const { isMenuUploadSuccess } = this.state;
		this.setState({
			loaderIconVisible: isMenuUploadSuccess ? false : true,
			isFlagUpdated: true
		});
		if (isMenuUploadSuccess) {
			this.props.history.push({
				pathname: `/menuUpdateStatus`,
				state: { showToast: true, totalUnitsCount: this.state.userCheckedData.length }
			});
		}
	}

	static getDerivedStateFromProps = (props) => ({ ...props });

	// componentWillUnmount
	componentWillUnmount() {
		this.props.searchText({});
		this.props.searchVisible(false);
		$('#assignedSGOkModal').off('hidden.bs.modal', this.clearEnteredUnits);
	}

	// clear Global Search
	clearGlobalSearch = () => {
		this.props.clearSearchText();
	}

	/**
	 * Convert Encoding UTF8 to binary
	 */
	convertToBinary = (string) => {
		const codeUnits = new Uint16Array(string.length);
		for (let i = 0; i < codeUnits.length; i++) {
			codeUnits[i] = string.charCodeAt(i);
		}
		return String.fromCharCode(...new Uint8Array(codeUnits.buffer));
	}

	onSuccessCloseModal = () => {
		this.setState({
			success_modal: {
				open: false
			}
		})
	}

	closeModel = () => {
		this.setState({
			error_modal: {
				open: false
			}
		})
	}

	componentWillMount = () => {
		let { srcfile, previouspath, scheduleDateTimeUtc, Type, serialNumber } = this.props.location;
		this.setState({
			srcfile: srcfile,
			previouspath: previouspath,
			scheduleDateTimeUtc: scheduleDateTimeUtc,
			Type: Type,
			serialNumber: serialNumber
		});
	}
	componentDidUpdate(prevState) {
		this.updateDimensions();
		let { searchData } = prevState;
		let curSearchData = this.state && this.state.searchData;
		if (curSearchData && (isEqualObj(searchData, curSearchData) === false)) {
			this.requestData();
		}
	}
	updateDimensions() {
		const windowHeight = $(window).height();
		const assignUListSrlBar = $(".assignUListSrlBar");
		assignUListSrlBar.css('height', windowHeight - assignUListSrlBar.offset().top);
	}
	componentDidMount() {
		this.props.searchVisible(true);
		window.addEventListener('resize', this.updateDimensions);
		this.updateDimensions();

		let { srcfile, previouspath, scheduleDateTimeUtc, Type } = this.props.location;
		this.setState({
			srcfile: srcfile,
			previouspath: previouspath,
			scheduleDateTimeUtc: scheduleDateTimeUtc,
			Type: Type
		});
		
		document.body.style.backgroundColor = "#F2F2F2";
		try {
			this.requestData();
		} catch (error) {
			//Error Goes Here
		}
		$('#assignedSGOkModal').on('hidden.bs.modal', this.clearEnteredUnits);
	}

	onSort = sortColumn => {
		$(".table-header").removeClass('sortingParent');
		$(".sortingChild").parent().addClass('sortingParent');
		this.setState({
			sortColumn: sortColumn
		});
	}

	getBrandId = () => {
		let brandName = localStorage.getItem("brandName");
		return BRAND_ID_VALUE[brandName] || 0;
	}

	showError = (err) => {
		let { response } = err;
		//To avoid the two popup at the same time
		if (response.status && response.status === 403) {
			this.setState({
				loaderIconVisible: false,
			}, () => {
			});
			return;
		}
		if (response) {
			let { data } = response;
			if (data) {
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: data.message ? (data.stringID || data.message) : ""
					}
				});
			}
		} else {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: "KC1168"
				}
			});
		}
	}

	requestData = async () => {

		let brandId = this.getBrandId();
		let filterModel = this.props.filterlistInformtion.filterModel;
		const ffModel = localStorage.getItem(appconstants.menuFilters);
		const filterModel1 = ffModel && JSON.parse(ffModel);
		if (filterModel1) {
			filterModel = filterModel1;
		}

		let selectedCountryList = filterModel.find(el => el.field_name === 'country');
		let selectedRegionList = filterModel.find(el => el.field_name === 'chain');
		let selectedLocationList = filterModel.find(el => el.field_name === 'location');
		let selectedWithinLocationList = filterModel.find(el => el.field_name === 'withinlocation');
		let selectedCustomerList = filterModel.find(el => el.field_name === 'customer');
		let selectedBrandList = filterModel.find(el => el.field_name === 'brand');
		let selectedModelList = filterModel.find(el => el.field_name === 'model');
		let selectedMachineTypeList = filterModel.find(el => el.field_name === 'machineType');

		let selectedCgids = [];
		let selectedCids = [];
		let selectedBrands = [];
		let selectedModels = [];
		let selectedMachineTypes = [];

		selectedCountryList.dataSource.map(c => {
			selectedCgids.push(c.id)
		});

		if (selectedRegionList.dataSource.length > 0) {
			selectedCgids = [];
		}

		selectedRegionList.dataSource.map(c => {
			selectedCgids.push(c.cust_gp_id)
		});

		if (selectedLocationList.dataSource.length > 0) {
			selectedCgids = [];
		}

		selectedLocationList.dataSource.map(c => {
			selectedCgids.push(c.cust_gp_id)
		});

		if (selectedWithinLocationList.dataSource.length > 0) {
			selectedCgids = [];
		}

		selectedWithinLocationList.dataSource.map(c => {
			return selectedCgids.push(c.cust_gp_id)
		});

		selectedCustomerList.dataSource.map(c => {
			selectedCids.push(c.ID)
		});

		selectedBrandList.dataSource.map(c => {
			selectedBrands.push(c.ID)
		});

		selectedModelList.dataSource.map(c => {
			selectedModels.push(c.ID)
		});

		selectedMachineTypeList.dataSource.map(c => {
			selectedMachineTypes.push(c.ID)
		});

		var d = document.getElementById("spannavNotifiStatus");
		let custGroupID = localStorage.getItem("custGroupID");
		let cgids = "";
		if (custGroupID != "" && custGroupID != undefined) {
			cgids = custGroupID;
		}
		if (selectedCgids.length > 0) {
			cgids = selectedCgids.join(',');
			d.className = "navNotifiStatus";
		}
		else if (selectedCids.length > 0 || selectedBrands.length > 0 || selectedModels.length > 0 || selectedMachineTypes.length > 0) {
			d.className = "navNotifiStatus";
		}
		else {
			d.className = "";
		}

		let sortedDesc = "desc";
		let sortedID = "CONNECT_STATE";
		let url = `${UnitInfo.list}`;
		let apiUrl = url;
		let res = {
			rows: [],
			pages: 0
		};
		this.setState({ loaderIconVisible: true });
		let headers = {
			'Content-Type': 'application/json',
			'cgid': cgids,
			'cid': selectedCids.join(','),
			'brandid': selectedBrands.length > 0 ? selectedBrands.join(',') : brandId ? brandId : BRAND_ID,
			'model': selectedModels.join(','),
			'machinetype': selectedMachineTypes.join(','),
			'sortby': sortedID,
			'sorttype': sortedDesc,
			// 'basecountry':BASECOUNTRY_ID,
			'provisionedunits': 'true',
			'unitids': this.state.serialNumber,
			'showOnlyActiveUnits': true 
		}
		// if(localStorage.getItem('brandName') === "GARLAND"){
		// 	headers['modelfamilyids'] = '31,32';
		// }

		let { searchData } = this.state;
		if (searchData && searchData.searchtext) {
			apiUrl = `${apiUrl}?searchText=${searchData.searchtext}`;
		}

		if (searchData) {
			let searchParam = {};
			Object.keys(searchData).forEach((item) => {
				if (item !== "searchtext") {
					return searchParam[item] = searchData[item];
				}
			})
			if (searchParam && Object.keys(searchParam).length) {
				apiUrl = `${apiUrl}?searchParams=${btoa(JSON.stringify(searchParam))}`;
			}
		}

		await http.get(apiUrl,
			{
				headers,
				data: {}
			}).then(response => {
				let { units, numberOfPages } = response.data;
				units = UnitBuilder(units);

				var checkedCopy = [];
				var userSelectAll = this.state.userSelectAll;
				units.forEach(function (e, index) {
					checkedCopy.push(userSelectAll);
				});

				this.setState({
					loaderIconVisible: false,
					data: units,
					userChecked: checkedCopy,
					userSelectAll: userSelectAll
				});

			}).catch(err => {
				this.showError(err);
			});

		//});

	};

	onBackClickHandler = () => {
		if ((this.getBrandId() !== undefined) && (this.getBrandId() === "")) {
			this.props.history.push(`/menuBrands`);
		} else {
			this.props.history.push(`/menuScheduleUpdateNow?brandId=` + this.getBrandId());
		}
	}

	handleChange = () => {
		var userSelectAll = !this.state.userSelectAll;
		this.setState({ userSelectAll: userSelectAll, isIndeterminate: false });
		var checkedCopy = [];
		let units = [...this.state.data];

		units && units.length > 0 && units.map(item => {
			item.checked = item.PROVISIONING_STATUS === "CERTIFICATE_RECEIVED" ? userSelectAll : false;
			checkedCopy.push(item.checked);
		});

		this.isSelectAssign(units);
		let unitsOfflineCount = units.filter(c => c.PROVISIONING_STATUS === "CERTIFICATE_RECEIVED" && c.CONNECT_STATE === "Offline");
		let unitsSelected = units.filter(c => c.PROVISIONING_STATUS === "CERTIFICATE_RECEIVED" && c.checked === true);

		// this.checkUnitsCount(unitsSelected);
		if (userSelectAll) {
			this.setState({
				userChecked: checkedCopy,
				data: units,
				unitsSelected: unitsSelected.length,
				unitsOffline: unitsOfflineCount.length,
				userCheckedData: unitsSelected,
				isSelectionHappen: false
			});
		}
		else {
			this.setState({
				userChecked: checkedCopy,
				data: units,
				unitsSelected: 0,
				unitsOffline: 0,
				userCheckedData: [],
				isSelectionHappen: false
			});
		}
	};

	getCheckBoxstate(ListCount, unselectedCount) {
		if (ListCount == unselectedCount) {
			return { userSelectAll: true, isIndeterminateSelect: false };
		} else if (unselectedCount == 0) {
			return { userSelectAll: false, isIndeterminateSelect: false };
		} else if (ListCount > unselectedCount) {
			return { userSelectAll: true, isIndeterminateSelect: true };
		}
	}

	handleSingleCheckboxChange = (e, index, rowData) => {
		var checkedCopy = this.state.userChecked;
		checkedCopy[index] = !this.state.userChecked[index];
		if (checkedCopy[index] === false) {
			this.setState({ userSelectAll: false });
		}
		let checkAll = [];

		const units = [...this.state.data];
		units[index] = { ...units[index] };
		units[index].checked = e.target.checked;

		checkAll = units.filter(c => c.PROVISIONING_STATUS === "CERTIFICATE_RECEIVED" && (c.checked === false || c.checked === undefined));
		let checked = units.filter(c => c.PROVISIONING_STATUS === "CERTIFICATE_RECEIVED" && c.checked === true);
		let unitsOfflineCount = checked.filter(c => c.CONNECT_STATE === "Offline");
		let unitRegistedCount = units.filter(c => c.PROVISIONING_STATUS === "UNIT_REGISTERED").length

		// this.checkUnitsCount(checked);
		this.setState({
			data: units,
			userChecked: checkedCopy,
			unitsSelected: checked.length,
			unitsOffline: unitsOfflineCount.length,
			userCheckedData: checked,
			isSelectionHappen: false,
			...this.getCheckBoxstate(parseInt(units.length) - parseInt(unitRegistedCount)
				, checkAll.length)
		});

		this.isSelectAssign(checked);
	};

	isSelectAssign = (units) => {
		let checkAllTrue = [];
		checkAllTrue = units.filter(c => c.checked === true);

		if (checkAllTrue.length > 0 && (units.length >= 1)) {
			$("#btnfinish").addClass("btn-default-text activeState");
			$("#btnfinish").removeClass("disabled");
		}
		else {
			$("#btnfinish").removeClass("activeState");
			$("#btnfinish").addClass("disabled");
		}
	}

	readCrcFile = (uploadedfile) => {
		let md5 = require('md5');
		return new Promise((resolve, reject) => {
			let reader = new FileReader();
			try {
				reader.readAsDataURL(uploadedfile[0]);
				reader.onload = () => {
					resolve(md5(Buffer.from(reader.result.replace(/^data:application\/octet-stream;base64,/, "").replace(/^data:application\/json;base64,/, ""), "base64")));
				};
			} catch (error) {
				reject(error);
			}
		})
	}

	onFinishClickHandler = async () => {
		let uploadedfile = this.state.srcfile;
		if (!uploadedfile || uploadedfile[0].size === 0) {
			let err = {
				response: {
					status: 400,
					data: {
						message: "File is empty."
					}
				}
			};
			this.showError(err);
			this.setState({ isError: true });
			return;
		}

		let userCheckedData = this.state.userCheckedData;
		const unitList = userCheckedData.map(item => ({
			"thingArn": item.THING,
			"productId": item.PRODUCT_ID,
			"cloudUnitName": item.CLOUD_UNIT_NAME,
			"unitTimeZone": item.TimeZone_Standard
		}));

		let fileName = uploadedfile[0].name.replace(/[^a-zA-Z 0-9\n\r.]+/g, '');
		let fileextSplit = fileName.split('.');
		let fileext = fileextSplit[1];
		let fileNamewithOutExt = fileextSplit[0];
		let menuId = fileNamewithOutExt + '_' + Date.now();
		menuId = menuId.replace(/\s+/g, '');
		let fileSize = (uploadedfile[0].size / 1024).toFixed(2);
		let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
		let loggedinUser = loginUser.username;
		let brandName = localStorage.getItem("brandName");

		let url = `${MenuUploadAPI.getPresignedUrl}?customer=${localStorage.getItem("customerId")}&key=${menuId}.${fileext}&brand=${brandName}`;

		let postData = {
			"customer": CUSTOMER_ID,
			"brand": brandName,
			"brandId": BRAND_ID_VALUE[brandName],
			"type": 'vhc',
			"fileName": fileName,
			"fileFormat": '.' + fileext,
			"fileSize": fileSize,
			"user": loggedinUser,
			"menuId": menuId,
			"thingList": unitList,
			"isTestPush": false
		};
		if (fileext === 'db') {
			postData.filetype = 'menu';
		}
		if (fileext === 'ini') {
			postData.filetype = 'config';
		}
		if (this.state.Type) {
			postData.scheduleDateTimeUtc = this.state.scheduleDateTimeUtc;
		}

		this.setState({ loaderIconVisible: true });

		postData.crc = await this.readCrcFile(uploadedfile);

		try {
			await uploadMenuToCloud(url, postData, uploadedfile, loggedinUser);
			$('#confirmationOkModal').modal('show');
			this.setState({
				loaderIconVisible: false,
				isMenuUploadSuccess: true
			});
			if (this.state.isFlagUpdated) {
				this.props.history.push(`/menuUpdateStatus`);
			}
		} catch (err) {
			this.showError(err);
		} finally {
			this.setState({ loaderIconVisible: false });
		}
	}

	postData = (url, reqData) => {
		this.setState({
			loaderIconVisible: true
		});
		return new Promise((resolve, reject) => {

			http.post(url
				, reqData,
				{
					headers: {
						'Content-Type': 'application/json',
						'customer': localStorage.getItem("customerId"),
						'user': localStorage.getItem("user")
					}
				}
			)
				.then(response => {
					let { data } = response;
					let { httpCode, responseCode, message } = data;
					if (httpCode === "HTTP_201" || httpCode === "HTTP_200") {
						this.setState({
							loaderIconVisible: false,
							success_modal: {
								open: true,
								message: (data.stringID || reqData.thingList.length > 100 ? data.message : "KC2227")
							},
						});
					}
				}).catch(err => {
					reject(err);
					this.setState({
						loaderIconVisible: false,
						error_modal: {
							open: true,
							message: err.response.data.stringID || err.response.data.message,
						},
					});

				});
		});
	}


	applyChanges = (postData) => {
		return new Promise((res, rej) => {
			let custGroupID = localStorage.getItem("custGroupID");
			let cgids = "";
			if (custGroupID !== "" && custGroupID !== undefined) {
				cgids = custGroupID;
			}
			http({
				method: "POST",
				url: smartTagMangement.transferTagDetails,
				headers: {
					'Content-Type': 'application/json',
					'customerid': localStorage.getItem("customerId"),
					'brandid': localStorage.getItem("selectedbrandID"),
					'cgid': cgids
				},
				data: postData
			}).then(async response => {
				if (response && response.data) {
					res(response.data.data || []);
				}
			}).catch(err => {
				res(err);
			});

		})
	}

	applyFilterChanges = (values) => {
		if (values && values.length > 0) {
			this.setState({ loaderIconVisible: true, initialUnit: this.state.data })
			this.applyChanges(values).then((res) => {
				const numberType = res && res.length ? res.map(Number) : [];
				let units = [];
				for (let i = 0; i < this.state.data.length; i++) {
					const ele = this.state.data[i];
					const isInactive = ele.PROVISIONING_STATUS !== "CERTIFICATE_RECEIVED";
					units.push({ ...this.state.data[i], checked: numberType.includes(ele.ID) ? isInactive ? false : true : false });
				}
				let checked = units.filter(c => c.PROVISIONING_STATUS === "CERTIFICATE_RECEIVED" && c.checked === true);
				// this.checkUnitsCount(checked);
				this.setState({
					data: units,
					loaderIconVisible: false,
					unitsSelected: checked.length,
					userCheckedData: checked,
					isSelectionHappen: true,
					...this.getCheckBoxstate(units.length, checked.length)
				}, () => {
					this.isSelectAssign(checked)
					$('#transferDetailsModal').modal('hide');
				});
			})
		} else {
			let checked = this.state.initialUnit.filter(c => c.PROVISIONING_STATUS === "CERTIFICATE_RECEIVED" && c.checked === true);

			// this.checkUnitsCount(checked);
			this.setState({
				data: this.state.initialUnit,
				unitsSelected: checked.length,
				userCheckedData: checked,
				isSelectionHappen: false,
				...this.getCheckBoxstate(this.state.initialUnit.length, checked.length)
			}, () => {
				this.isSelectAssign(checked);
			})
		}
	}

	getInfoView = (value, type) => {
		let smartTagObj = {
			mode: 'isView',
			type,
			selectedRow: value
		}
		localStorage.setItem('smartTagInfo', JSON.stringify(smartTagObj));
		let showpopup = [];
		showpopup.push(value);
		this.setState({ showpopup, showOrgPopup: true, type }, () => {
			$('#transferDetailsModal').modal('hide');
			$('#stUnitDetailsModal').modal('show');
		});
	}

	showSTagInfo = (unitDet, type = "org") => {
		let unitObj = unitDet;
		unitObj.id = unitDet.ID;
		this.setState({
			showStPopUp: true,
			selUnitDetails: unitObj
		}, () => {
			$('#unitSmartTagsDetails').modal('show')
		})

	}

	downloadUnitList = (tagName) => {
		this.setState({ loaderIconVisible: true });
		let url = smartTagMangement.getPresignedUrl;
		let registrationFlag = 'no';
		let unitRegistrationAuthFeature = authService.getFeature("/addUnit");
		if (unitRegistrationAuthFeature && unitRegistrationAuthFeature.is_editable === 1) {
			registrationFlag = 'yes';
		}
		url = url + `?registrationFlag=${registrationFlag}&tagName=${tagName}&isView=yes`;
		http.get(url, {
			headers: {
				'Content-Type': 'application/json',
				'customerid': localStorage.getItem("customerId"),
				'cgid': localStorage.getItem("custGroupID"),
				'brandid': BRAND_ID,
			}, data: {}
		}).then(async response => {
			if (response && response.data && response.data.data) {
				this.setState({ loaderIconVisible: false });
				if (response.data.data.preSignedUrl && response.data.data.preSignedUrl !== '#') {
					window.location = response.data.data.preSignedUrl;
				}
			}
		}).catch(err => {
			this.setState({ loaderIconVisible: false });
		});
	}

	onGenericCloseModal = () => {
		this.setState({
			generic_modal: {
				open: false,
				message: "",
				messagetype: ""
			}
		});
	}

	checkUnitsCount = (units) => {
		const totalUnits = parseInt(unitSelectionAllowOnTransfer);
		if (units && units.length > totalUnits) {
			this.setState({
				generic_modal: {
					open: true,
					message: "Please select maximum of 300 units",
					messagetype: "Maximum number of units",
					count: units.length,
					total: totalUnits
				}
			})
		}
	};

	openModalHandler = () => {
		$('#assignedSGOkModal').modal('show');
		this.setState({
			totalUnitsCount: this.state.userCheckedData ? this.state.userCheckedData.length : 0
		});
	}

	handleUnitsChange(event) {
		let totalUnitsCount = this.state.userCheckedData ? this.state.userCheckedData.length : 0;
		let isInputValid = event.target.value === totalUnitsCount.toString();

		this.setState({
			enteredUnits: event.target.value,
			isInputValid: isInputValid
		});
	}

	clearEnteredUnits = () => {
		this.setState({ enteredUnits: '' });
	}

	render() {

		const { formatMessage } = this.props.intl;

		const { loaderIconVisible, data, sortColumn, unitsSelected, unitsOffline, error_modal, success_modal, showpopup, type, isSelectionHappen, userCheckedData, generic_modal, srcfile, totalUnitsCount } = this.state;
		let menuAuthFeature = authService.getFeature("/menuBrands");
		let orderId = 1;
		let Updatedunits = (parseInt(unitsSelected) - parseInt(unitsOffline));
		let columns = [
			{ path: "", label: "#" },
			{ path: "", label: "Unit", isNbSp: true },
			{ path: "BRAND_NAME", labelStringID: "KC0062" },
			{ path: "PRODUCT_TYPE", labelStringID: "KC0102" },
			{ path: "MODEL_NAME", labelStringID: "KC0064" },
			{ path: "UNITID", labelStringID: "KC0104" },
			{ path: "UNIT_NUMBER", labelStringID: "KC0674" },
			{ path: "location_name", labelStringID: "KC0675" },
			{ path: "within_location", labelStringID: "KC0061" },
			{ path: "", labelStringID: "KC2317" },
			{ path: "CONNECT_STATE", labelStringID: "KC0523", isNbSp: true },
			{ path: "", label: "", isCheckBox: true, isCheckBoxActive: menuAuthFeature.is_editable }
		];


		let unitList = data;

		unitList.forEach(item => {
			item.order_id = orderId++
		});

		unitList = _.orderBy(unitList, [sortColumn.path], [sortColumn.order]);

		return (
			<React.Fragment>
				<div id="adminWrapper">

					<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
						<div className="loadingProgressIn"></div>
					</div>

					<div id="page-content-wrapper" class="withSearchBar">

						<div class="menuUpdateWrapper">
							<div class="adminBgHeader">
								<ul>
									<li>
										<a href="javascript:void(0)" onClick={() => this.onBackClickHandler()} title={formatMessage({ id: 'KC0989' })} class="backIcon">&nbsp;</a>
									</li>
									<li className="txtboldmsg">
										<FormattedMessage id="KC1169" />
									</li>
									<li>
										<button type="button" id="btnfinish" title={formatMessage({ id: 'KC0056' })} className="btn disabled" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} onClick={() => this.openModalHandler()}><FormattedMessage id="KC0056" /></button>
									</li>
								</ul>
							</div>
							<div class="selectedUnitHeader">
								<ul>
									<li>{`${unitList.length || 0} listed units`}{(userCheckedData && userCheckedData.length) || isSelectionHappen ? ` / ${userCheckedData.length} units selected` : null}</li>
									<li>
										<button type="button" id="transferDetails" onClick={() => $('#transferDetailsModal').modal('show')} class="btn btn-default useTagsforunit" data-toggle="popover" data-content={formatMessage({ id: 'KC2356' })}><FormattedMessage id="KC2360" /></button>
										<Link to="/menuAssignUpdateList" class="btn btn-default-text assignunitList active">&nbsp;</Link>
									</li>
								</ul>
							</div>
							<form class="SWlistUnits">
								<div class="SWlistUnitsTable">
									<TableHeader
										sortColumn={sortColumn}
										onSort={this.onSort}
										selectAllChange={this.handleChange}
										columns={columns}
										isIndeterminate={this.state.isIndeterminateSelect}
										isSelectAll={this.state.userSelectAll} />
								</div>
								<div className="assignUListSrlBar">
									<div className="SWlistUnitsTable">
										{
											unitList.map((item, i) => {
												return (
													<ul className={item.CONNECT_STATE === "Offline" ? "tBody" : "tBody "} key={i}>
														<li>{i + 1}</li>
														<li><img src={item.Brand_Logo} /></li>
														<li>{item.BRAND_NAME}
															<div class="unitsListSM">{item.PRODUCT_TYPE}, {item.MODEL_NAME}</div>
															<div class="unitsListSML">{item.location_name}</div></li>
														<li>{item.PRODUCT_TYPE}</li>
														<li>{item.MODEL_NAME}</li>
														<li>{item.UNITID} <span class="unitsListAN">Unit Number <b>{item.UNIT_NUMBER}</b></span></li>
														<li>{item.UNIT_NUMBER}</li>
														<li>{item.location_name}</li>
														<li>{item.within_location}</li>
														<li>
															{item.orgTags && item.orgTags.length ? <button className="btn orgTagedUnits" type="button" onClick={() => this.showSTagInfo(item, 'org')}>&nbsp;</button> : ""}
															{item.unitTags && item.unitTags.length ? <button className="btn tagedUnits" type="button" onClick={() => this.showSTagInfo(item, 'unit')}>&nbsp;</button> : ""}
														</li>
														<li>
															{item.CONNECT_STATE === 'Offline' && <img src={OfflineMediaImg} />}
														</li>
														<li>{item.PROVISIONING_STATUS !== "CERTIFICATE_RECEIVED" ? <Tooltip content={formatMessage({ id: 'KC1735' })} direction="left" className="targetdisabled">
															<div className={"customCheckbox customCheckboxWOlabel disabled"}>
																<input id={item.order_id - 1} type="checkbox" value={item.checked} defaultChecked={item.checked}
																	checked={item.checked}
																	onChange={(e) => this.handleSingleCheckboxChange(e, item.order_id - 1)} />
																<label for={item.order_id - 1}></label>
															</div></Tooltip>
															: <div className={menuAuthFeature.is_editable === 1 ? "customCheckbox customCheckboxWOlabel" : "customCheckbox customCheckboxWOlabel disabled"}>
																<input id={item.order_id - 1} type="checkbox" value={item.checked} defaultChecked={item.checked}
																	checked={item.checked}
																	onChange={(e) => this.handleSingleCheckboxChange(e, item.order_id - 1)} />
																<label for={item.order_id - 1}></label>
															</div>}
														</li>
													</ul>
												)
											}
											)
										}
									</div>
									<NoRecordsFound loaderIconVisible={loaderIconVisible} length={unitList !== undefined ? unitList.length : 0} classname={'norecordsfoundbluetext'} />
								</div>
							</form>
						</div>
					</div>
					{/* <!-- Transfer Modal --> */}
					<div class="modal fade transferDetailsModal" data-keyboard="true" data-backdrop="static" id="transferDetailsModal" tabindex="-1" role="dialog">
						<SmartTagDrop applyFilterChanges={this.applyFilterChanges} getInfoView={this.getInfoView} />
					</div>
					{/* <!-- Transfer Modal --> */}
					<div className="modal fade reportDetailsModal" data-keyboard="true" data-backdrop="static" id="stUnitDetailsModal" tabindex="-1" role="dialog" aria-labelledby="stUnitDetailsModalLabel">
						<div className="modal-dialog modal-md">
							<div className="modal-content">
								<div className="modal-header modal-header-danger">
									<FormattedMessage id="KC2312" />
								</div>
								<div className="modal-body">
									{showpopup && showpopup.map((item, i) => <div className="reportDetailsD">
										<div className="createdEditedDetailsO">
											<ul className="tHead">
												<li>
													<div className={type === "orgList" ? "tagTypeLocation" : "tagTypeUnit"}><span>{item.tagName}</span></div>
												</li>
											</ul>
										</div>
										<h6><FormattedMessage id="KC0195" /></h6>
										<p>{item.description ? item.description : 'NA'}</p>
										<div className="createdEditedDetailsO">
											<ul className="tHead">
												<li>
													<p><FormattedMessage id="KC2313" /> <button type="button" onClick={() => $('#stUnitDetailsModal').modal('hide')} data-toggle="modal" data-target="#orgViewModal" className="orgViewIcon btn"><img src={require('../../../images/icons/iconUnitSelection.svg').default} /></button> {type === 'orgList' ? formatMessage({ id: "KC2306" }) : formatMessage({ id: "KC2307" })}</p>
												</li>
												{type === 'orgList' ? "" :
													<li>
														<Link to='#' onClick={() => this.downloadUnitList(item.tagName)} className="downloadListof"><FormattedMessage id="KC2314" /></Link>
													</li>}
											</ul>
										</div>
										<div className="createdEditedDetailsO">
											<ul className="tHead">
												<li><FormattedMessage id="KC0589" /></li>
												<li><FormattedMessage id="KC1278" /></li>
											</ul>
											<ul className="tBody">
												<li>
													<div className="createdEditedDetails">
														<ul>
															<li><FormattedMessage id="KC0241" />:</li>
															<li>{item.createdByName ? item.createdByName : ''}</li>
														</ul>
														<ul>
															<li><FormattedMessage id="KC0136" />:</li>
															<li>{item.createdBy ? item.createdBy : ''}</li>
														</ul>
														<ul>
															<li><FormattedMessage id="KC0298" />:</li>
															<li>{item.createdAt ? moment.GetDateTimeByFormat(item.createdAt, 'LLL') : ''}</li>
														</ul>
													</div>
												</li>
												<li>
													<div className="createdEditedDetails">
														<ul>
															<li><FormattedMessage id="KC0241" />:</li>
															<li>{item.updatedByName ? item.updatedByName : ''}</li>
														</ul>
														<ul>
															<li><FormattedMessage id="KC0136" />:</li>
															<li>{item.updatedBy ? item.updatedBy : ''}</li>
														</ul>
														<ul>
															<li><FormattedMessage id="KC0298" />:</li>
															<li>{item.UpdatedAt ? moment.GetDateTimeByFormat(item.UpdatedAt, 'LLL') : ''}</li>
														</ul>
													</div>
												</li>
											</ul>
										</div>
									</div>)}
								</div>
								<div className="modal-footer textAlignCenter">
									<button className="btn btn-secondary okBtn" data-dismiss="modal" onClick={() => this.setState({ showpopup: [], showOrgPopup: false }, () => $('#transferDetailsModal').modal('show'))} type="button"><FormattedMessage id="KC0777" /></button>
								</div>
							</div>
						</div>
					</div >
					{this.state.showOrgPopup ? <TagOrgModal details={this.state.showpopup && this.state.showpopup.length ? this.state.showpopup[0] : {}} /> : null}
					{this.state.showStPopUp ? <OrgTagsDetail closeSTModel={() => this.setState({ showStPopUp: false })} unitDet={this.state.selUnitDetails} isUnit={true} /> : ""}
					<ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
					<SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
					<GenericModal open={generic_modal.open} stringID={generic_modal.message} messagetype={generic_modal.messagetype} values={{ count: generic_modal.count ? generic_modal.count : 0, total: generic_modal.total ? generic_modal.total : 0 }} onGenericCloseModal={(e) => this.onGenericCloseModal()} />
					<UserManagementHeader headerNameStringID="KC2358" headerName="Transfer Management" activeClass="transferNav" filterKey={appconstants.menuFilters} history={this.props.history} />
					{/* Unit Confirmation Modal */}
					<div className="modal fade confirmModalSM" data-keyboard="true" data-backdrop="static" id="assignedSGOkModal" tabindex="-1" role="dialog">
						<div className="modal-dialog modal-md">
							<div className="modal-content">
								<div className="modal-body">
									<div className="confirmMContententD">
										<div className="h2Title warningInfo">{formatMessage({ id: 'KC2665' }).replace("{{number}}",{totalUnitsCount})}</div>
										<p>{formatMessage({ id: 'KC2666' }).replace("{{number}}",{totalUnitsCount})}<br />
											<span class="textBold"><FormattedMessage id="KC2667" /></span><br />
											<FormattedMessage id="KC2668" /></p>										
										<div className="textWformF"><FormattedMessage id="KC0102" /><span>' {totalUnitsCount}'</span><FormattedMessage id="KC2932" /> &nbsp;
											<input
												type="text"
												className={`form-control ${!this.state.isInputValid && this.state.enteredUnits !== '' ? 'errorField' : ''} ${this.state.isInputValid ? 'valueField' : ''}`}
												value={this.state.enteredUnits}
												onChange={this.handleUnitsChange}
											/>
											<div style={{ height: '20px' }}>
												{!this.state.isInputValid && this.state.enteredUnits !== '' && (
													<div class="required"><FormattedMessage id="KC2670" /></div>
												)}
											</div>
										</div>
									</div>
								</div>
								<div className="modal-footer textAlignCenter">
									<button className="btn confirmYes" data-dismiss="modal" type="button" onClick={() => this.setState({ isInputValid: false })}><FormattedMessage id="KC0580"/></button>
									<button className="btn confirmNo" data-dismiss="modal" type="button" disabled={!this.state.isInputValid} onClick={(e) => this.onFinishClickHandler()}><FormattedMessage id="KC0611"/></button>							</div>
							</div>
						</div>
					</div>
				</div>

				<div class="modal fade confirmationOkModal" data-keyboard="true" data-backdrop="static" id="confirmationOkModal" tabindex="-1" role="dialog" aria-labelledby="confirmationOkModalLabel">
					<div class="modal-dialog modal-md">
						<div class="modal-content">
							<div class="modal-body">
								<div class="confirmationContentDiv">
									<h5><FormattedMessage id="KC1170" /></h5>
									<p>{Updatedunits} / {unitsSelected} <FormattedMessage id="KC1171" /> <br />
										{unitsOffline} <FormattedMessage id="KC1172" />. <br />
										{unitsOffline} <FormattedMessage id="KC1594" />. <br />
									</p>
								</div>
							</div>
							<div class="modal-footer textAlignCenter">
								<button onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} class="btn btn-secondary confirmationOKBtn" data-dismiss="modal" onClick={() => this.menuUpdate()} type="button"><FormattedMessage id="KC0777" /></button>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
};

function mapStateToProps(state) {
	return {
		searchData: state.advanceSearch.searchData,
		filterlistInformtion: state.filterModel
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		searchVisible, searchText, clearSearchText
	}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(menuAssignUpdateList));