import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import http from '../../service/httpService';
import { UnitInfo, UserProfile } from '../../service/api';
import { geterrorStatusInfo } from '../../actions/allLocations/locations/errorStatusAction';
import PeakHoursChart from './charts/peakHourChart';
import ConnectivityChart from './charts/connectivityStatusChart';
import ProductionLineChart from './charts/productionLineChart';
import ErrorChart from './charts/errorStatusChart';
import { UnitBuilder } from '../../utils/unitResponseBuilder';
import { setCurrentUnit } from '../../actions/organizationentity/entityactions';
import { setUnit } from '../../actions/dashboardRenderer/dashboardRenderer';
import AllLocationHeaderLayout from '../layoutSection/allLocationHeaderLayout';
import { UnitCard } from '../controls/unitCard';
import $ from 'jquery';
import * as _ from 'lodash';
import auth from '../../service/authService';
import appconstants from '../../utils/appConstants'
import { setunitBrandId } from '../../utils/appConstants';
import NoRecordsFound from '../controls/noRecordsFound';
import { CUSTOMER_ID, BRAND_ID, setBrandId } from '../../utils/appConstants';
import { searchVisible } from '../../actions/advanceSearch/advanceSearchAction';
import { isEqualObj } from '../globalSearchBar/selectOption';
import { DropDownListLink } from '../controls/dropDownListLink';

import { searchText } from '../../actions/advanceSearch/advanceSearchAction';
import WoopraEvents from '../../woopraConfig/WoopraEvents';
import Constants from '../../woopraConfig/Woopraconstant';
import { FormattedMessage, injectIntl } from 'react-intl';
import GoToPagination from '../controls/goToPagination';


var moment = require('moment');
var todaysDate = new Date();
class DashboardUnits extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			totalPages: null,
			loading: true,
			currentPage: 1,
			pageSize: 25,
			sortColumn: { path: "", order: "", sort_color: "#F2F2F2" },
			isUnitsDataLoaded: false,
			searchData: null,
			datapickflag: false,
			globalDropdownFilter: 'Month',
			sdmindate: todaysDate,
			sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
			edmaxdate: todaysDate,
			edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
		};
		this.filterstate = {
			sCgids: [],
			sCids: [],
			sBrandids: [],
			sModels: [],
			sMachineTypes: [],
		};
		window.addEventListener("load", this.updateDimensions());
		window.addEventListener("resize", this.updateDimensions());
		this.setLocatioStorage = this.setLocatioStorage.bind(this);
		this.setBrandList = this.setBrandList.bind(this);
	}

	updateDimensions() {
		const windowHeight = $(window).height();
		const tableBody = $('.pageScrollbar');
		const tableBodyBottom = $('.paginationDWgoto, .paginationWgoto').height() ? $('.paginationDWgoto, .paginationWgoto').height() + 18 : 0 ;
		tableBody.css('height', windowHeight - (tableBody && tableBody.offset() && tableBody.offset().top + tableBodyBottom));
	}

	async componentDidUpdate(prevState) {
		this.updateDimensions();
		let { searchData } = prevState;
		let curSearchData = this.state && this.state.searchData;
		if (curSearchData && (isEqualObj(searchData, curSearchData) === false) && Object.keys(this.state.searchData).length > 0)  {
			this.setState({ loading: true,currentPage: 1 },async()=>{
				const response = await this.requestData(this.state.currentPage, this.state.sortColumn);
				this.setState({
					data: response.rows,
					totalPages: response.pages,
					loading: response.showLoading,
					isUnitsDataLoaded: response.isUnitsDataLoaded
				});
			})
			
		}
	}

	componentDidMount() {
		localStorage.removeItem('currentUnit');
		localStorage.removeItem('currentLocation');
		localStorage.removeItem('showOfflinePopup');
		localStorage.removeItem('liveMonitoringTracker');
        let _getBrandsList;
        let _getbrandIds = localStorage.getItem('brandId').replace(/[\[\]']+/g, '');
        _getBrandsList = _.isEqual(BRAND_ID.split(',').sort(), _getbrandIds.split(',').sort())
        if(!_getBrandsList) {
            setBrandId(_getbrandIds);
        }
		this.props.searchVisible(true);
		this.setState({ loaderIconVisible: true });

		this.updateDimensions();
		const { currentPage, sortColumn } = this.state;
		this.loadData(currentPage, sortColumn);
	}

	onDropDownHandler(e) {
		const filterType = e.nodeText;
		this.setState({
			globalDropdownFilter: filterType
		});
		this.refs.peakHourChart.fetchData(this.refs.peakHourChart.state.brand_id, filterType)
		this.refs.productionHourChart.fetchData(this.refs.productionHourChart.state.brand_id, filterType)

		this.refs.peakHourChart.setState({ datapickflag: filterType === 'Date Range' })
		this.refs.productionHourChart.setState({ datapickflag: filterType === 'Date Range' })
	}

	// componentWillUnmount
	componentWillUnmount() {
		this.props.searchText({});
		this.props.searchVisible(false);
	}

	loadData = async (currentPage, sortColumn) => {
		try {
			const response = await this.requestData(currentPage, sortColumn);
			await this.setBrandList();
			this.setState({
				data: response.rows,
				totalPages: response.pages,
				currentPage: currentPage,
				loading: response.showLoading,
				isUnitsDataLoaded: response.isUnitsDataLoaded
			});
		} catch (error) {
			this.setState({ loading: false });
		}
	}

	/**
	 * When navigate new page this event will return current page number
	 * @param {*} currentPage 
	 */
	onPageChange = (currentPage) => {
		this.setState({ loading: true });
		this.loadData(currentPage);
	}

	/**
	 * When sort the record by On-Demand
	 * This event will return the sorted column details
	 */
	onSortedChange = (sortColumn) => {
		this.setState({
			sortColumn: sortColumn,
			currentPage: 1
		});
		this.loadData(1, sortColumn);
	}

	/**
	 * Business login to fetch units by below constraints
	 * 1. Fetch by Brand specific units
	 * 2. Fetch by Sorted column details
	 * 3. Fetch by page wise
	 */
	requestData = async (currentPage, sortColumn) => {
		try {
			const sortData = sortColumn ? sortColumn : this.state.sortColumn;
			const pageSize = this.state.pageSize;
			const sortType = sortData.order;
			const sortBy = sortData.path;
			const page = currentPage - 1;

			let filterModel = this.props.filterlistInformtion.filterModel;
			const ffModel = localStorage.getItem(appconstants.filterKey);
			const filterModel1 = ffModel && JSON.parse(ffModel);
			if (filterModel1) {
				filterModel = filterModel1;
			}

			let selectedCountryList = filterModel.find(el => el.field_name === 'country');
			let selectedRegionList = filterModel.find(el => el.field_name === 'chain');
			let selectedLocationList = filterModel.find(el => el.field_name === 'location');
			let selectedWithinLocationList = filterModel.find(el => el.field_name === 'withinlocation');
			let selectedCustomerList = filterModel.find(el => el.field_name === 'customer');
			let selectedBrandList = filterModel.find(el => el.field_name === 'brand');
			let selectedModelList = filterModel.find(el => el.field_name === 'model');
			let selectedMachineTypeList = filterModel.find(el => el.field_name === 'machineType');
            let selectedUnitSTList = filterModel.find(el => el.field_name === 'unitSmartTagsFilter');
            let selectedOrgSTList = filterModel.find(el => el.field_name === 'orgSmartTagsFilter');

			let selectedCgids = [];
			let selectedCids = [];
			let selectedBrands = [];
			let selectedModels = [];
			let selectedMachineTypes = [];
            let selectedST = [];

			selectedCountryList.dataSource.map(c => {
				selectedCgids.push(c.id)
			});

			if (selectedRegionList.dataSource.length > 0) {
				selectedCgids = [];
			}

			selectedRegionList.dataSource.map(c => {
				selectedCgids.push(c.cust_gp_id)
			});

			if (selectedLocationList.dataSource.length > 0) {
				selectedCgids = [];
			}

			selectedLocationList.dataSource.map(c => {
				selectedCgids.push(c.cust_gp_id)
			});

			if (selectedWithinLocationList.dataSource.length > 0) {
				selectedCgids = [];
			}

			selectedWithinLocationList.dataSource.map(c => {
				return selectedCgids.push(c.cust_gp_id)
			});

			selectedCustomerList.dataSource.map(c => {
				selectedCids.push(c.ID)
			});

			selectedBrandList.dataSource.map(c => {
				selectedBrands.push(c.ID)
			});

			selectedModelList.dataSource.map(c => {
				selectedModels.push(c.ID)
			});

			selectedMachineTypeList.dataSource.map(c => {
				selectedMachineTypes.push(c.ID)
			});

			selectedUnitSTList && selectedUnitSTList.dataSource.map(c => {
                return selectedST.push(c.id)
            });

            selectedOrgSTList && selectedOrgSTList.dataSource.map(c => {
                return selectedST.push(c.id)
            });

			this.filterstate = ({
				sCgids: selectedCgids,
				sCids: selectedCids,
				sBrandids: selectedBrands,
				sModels: selectedModels,
				sMachineTypes: selectedMachineTypes,
				sSmartTags: selectedST,
			});

			var d = document.getElementById("spannavNotifiStatus");
			let cgids = "";
			if (selectedCgids.length > 0) {
				cgids = selectedCgids.join(',');
			}
			else {
				cgids = localStorage.getItem("custGroupID");
			}

			if (selectedCgids.length > 0 || selectedST.length > 0 || selectedCids.length > 0 || selectedBrands.length > 0 || selectedModels.length > 0 || selectedMachineTypes.length > 0) {
				d.className = "navNotifiStatus";
			}
			else {
				d.className = "";
			}
			let url = `${UnitInfo.list}`;
			let pageNumber = '?pageNum=' + (page + 1);
			let pageLimit = '&pageSize=' + pageSize;
			let order = page * pageSize;

			let { searchData } = this.state;
			let apiUrl = url + pageNumber + pageLimit;

			if (searchData && searchData.searchtext) {
				apiUrl = `${apiUrl}&searchText=${searchData.searchtext}`;
			}

			if (searchData) {
				let searchParam = {};

				Object.keys(searchData).forEach((item) => {
					if (item !== "searchtext") {
						return searchParam[item] = searchData[item];
					}
				})

				if (searchParam && Object.keys(searchParam).length) {
					apiUrl = `${apiUrl}&searchParams=${btoa(JSON.stringify(searchParam))}`;
				}
			}
			let headers = {
				'Content-Type': 'application/json',
				'cgid': cgids ? cgids : "NA",
				'cid': CUSTOMER_ID === "-1" ? selectedCids.join(',') : CUSTOMER_ID,
				'brandid': selectedBrands.length > 0 ? selectedBrands.join(',') : BRAND_ID,
				'model': selectedModels.join(','),
				'machinetype': selectedMachineTypes.join(','),
				'sortby': sortBy,
				'sorttype': sortType
			}
			console.log("selectedST", selectedST);
            if(selectedST && selectedST.length){
                headers['tags'] = selectedST.join(',')
            }
			const response = await http.get(apiUrl,
				{
					headers,
					data: {}
				})

			let { units, numberOfPages } = response.data;
			units.map((item, i) => {
				item.order_id = ++order;
			});
			units = UnitBuilder(units);

			return {
				rows: units,
				pages: numberOfPages,
				isUnitsDataLoaded: true,
				showLoading: false,
			};

		} catch (error) {
			return {
				rows: [],
				pages: 0,
				showLoading: false,
			};
		}

	};

	setLocatioStorage = (item) => {
		this.props.setCurrentUnit(item);
		localStorage.setItem('unitID', item.UNITID);
		localStorage.setItem("brandName", item.BRAND_NAME);
		localStorage.setItem('unitBrandId', item.BRAND_ID);
		localStorage.setItem('modelName', item.MODEL_NAME);
		localStorage.setItem('modelFamilyName', item.MODEL_FAMILY_NAME);
		localStorage.setItem('cloudUnitName', item.CLOUD_UNIT_NAME);
		setunitBrandId(item.BRAND_ID ? item.BRAND_ID : "", item.UNITID ? item.UNITID : "", item.MODEL_NAME ? item.MODEL_NAME : "");
		this.getExcludeProductDetails(item.BRAND_ID);
	}

	setRendererLocationStorage = (item) => {
		this.props.setUnit(item);
		localStorage.setItem('unitID', item.UNITID);
		localStorage.setItem("brandName", item.BRAND_NAME);
		localStorage.setItem('unitBrandId', item.BRAND_ID);
		localStorage.setItem('modelName', item.MODEL_NAME);
		localStorage.setItem('modelFamilyName', item.MODEL_FAMILY_NAME);
		localStorage.setItem('cloudUnitName', item.CLOUD_UNIT_NAME);
		setunitBrandId(item.BRAND_ID ? item.BRAND_ID : "", item.UNITID ? item.UNITID : "", item.MODEL_NAME ? item.MODEL_NAME : "");
		this.getExcludeProductDetails(item.BRAND_ID);
	}

	getExcludeProductDetails = async (brandid) => {
		const response = await http.get(UnitInfo.excludeProduct,
			{
				headers: {
					'Content-Type': 'application/json',
					'cid': CUSTOMER_ID,
					'brandid': brandid,
				},
				data: {}
			})
		let excludeProduct = response.data && response.data[0]
		let checklength = excludeProduct && excludeProduct.excludedProductList && excludeProduct.excludedProductList.length ? excludeProduct.excludedProductList.length :0;
		localStorage.setItem('excludedProductList', checklength > 0 ? true : false);
	}

	// Woopra Event
	trackEvent = () => {
        WoopraEvents(`${Constants.LOCATION}`);
    }

    setBrandList = async () => {
        try {
            let cgids = localStorage.getItem("custGroupID");
			let brandIds = localStorage.getItem("brandId")
            let url = `${UnitInfo.uniqueBrands}`;
            const response = await http.get(url,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'cgid': cgids ? cgids : "NA",
                        'cid': CUSTOMER_ID,
						'brandid': BRAND_ID
                    },
                    data: {}
                });
            let brandIDNameArr = response.data.brands.map(function (item) {
                return {
                    BRAND_NAME: item.BRAND_NAME,
                    BRAND_ID: item.BRAND_ID
                }
            });
            let uniqueObjArray = [...new Map(brandIDNameArr.map((item) => [item["BRAND_ID"], item])).values()];
            let brandIDName = uniqueObjArray.sort((a, b) => (a.BRAND_NAME > b.BRAND_NAME) ? 1 : ((b.BRAND_NAME > a.BRAND_NAME) ? -1 : 0));
            localStorage.setItem("availableUnitsBrand", JSON.stringify(brandIDName));
        } catch (error) {
            return error;
        }
    }

	static getDerivedStateFromProps = (props) => ({ ...props });

	render() {
		const { data, totalPages, currentPage, loading, isUnitsDataLoaded } = this.state;
		const { formatMessage } = this.props.intl;
		const { sCgids, sCids, sBrandids, sModels, sMachineTypes, sSmartTags } = this.filterstate;
		let custGroupID = localStorage.getItem("custGroupID");

		let features = auth.getAllFeatures();
		features = features ? features.filter(a => a.feature_category === "Unit Data") : [];

		return (
			<React.Fragment>
				<div id="wrapper">
					<div className={loading ? "loadingProgress showloader" : "loadingProgress"}>
						<div className="loadingProgressIn"></div>
					</div>
					<AllLocationHeaderLayout activeClass="dashboardNav" filterKey={appconstants.filterKey} history={this.props.history} />
					{<div id="page-content-wrapper" class="withSearchBar">
						<div class="pageHeader">
							<ul>
								<li>
									<Link to="/dashBoardLocations" title={formatMessage({id: 'KC0175'})} className="active"><FormattedMessage id='KC0054' /></Link>
								</li>
								<li class="filterDropdown">
									<p class="globalFilterText"><FormattedMessage id='KC0097'/></p>
									<DropDownListLink translation={true} OnChange={(e) => this.onDropDownHandler(e)} filterType={this.state.globalDropdownFilter}  disableDatePicker={true} />
								</li>
							</ul>
						</div>
					<div class="pageScrollbar">
						<div class="gridViewTable">
							<div class="gridView">
								<div class="colm3D colm3D1">
									{isUnitsDataLoaded && <div class="colmDi">
										<h4><FormattedMessage id='KC0040'/></h4>
										<ConnectivityChart Type={'Unit'} cgid={sCgids} cid={sCids} brandid={sBrandids} model={sModels} machineType={sMachineTypes} orgid={custGroupID} tags={sSmartTags}/>
									</div>
									}
								</div>
								<div class="colm3D">
									{isUnitsDataLoaded && <div class="colmDi">
										<h4><FormattedMessage id="KC1042"/></h4>
										<ErrorChart Type={'Unit'} cgid={sCgids} cid={sCids} brandid={sBrandids} model={sModels} machineType={sMachineTypes} orgid={custGroupID} tags={sSmartTags}/>
									</div>
									}
								</div>
								<div class="colm3D">
									{isUnitsDataLoaded && <PeakHoursChart ref="peakHourChart" cgid={sCgids} cid={sCids} brandid={sBrandids} model={sModels} machineType={sMachineTypes} orgid={custGroupID}  tags={sSmartTags}/>}
								</div>
								<div className="colm3D colm3D4">
									{isUnitsDataLoaded && <ProductionLineChart ref="productionHourChart" cgid={sCgids} cid={sCids} brandid={sBrandids} model={sModels} machineType={sMachineTypes} orgid={custGroupID}  tags={sSmartTags}/>}
								</div>
							</div>
						</div>
						<div class="pageTabHeader">
							<ul>
								<li>
									<Link to="/dashBoardLocations" title={formatMessage({id: 'KC0044'})} class="locationsView" onClick={()=> this.trackEvent()}><FormattedMessage id='KC0044' /></Link>
								</li>
								<li>
									<Link to="/dashboardUnits" title={formatMessage({id: 'KC0045'})} class="unitsView active"><FormattedMessage id='KC0045' /></Link>
								</li>
							</ul>
						</div>
						<div className={data && data.length > 0 ? "allUnitsGrid" : "displaynone"}>
							<ul>
								{Object.keys(data).map((item, i) => (
									<li key={i}>
										<UnitCard unit={data[i]} features={features} setLocatioStorage={this.setLocatioStorage} setRendererLocationStorage={this.setRendererLocationStorage} history={this.props.history}/>
									</li>
								))}
							</ul>
						</div>
						<NoRecordsFound loaderIconVisible={!isUnitsDataLoaded} length={data !== undefined ? data.length : 0} classname={'norecordsfound'} stringID={this.state.searchData && data && data.length === 0 ? "KC0853" : "KC0205"}/>
					</div>
							{ totalPages && totalPages > 1 ? <GoToPagination
                            isBlackBackground={true}
                            totalPageNumber={totalPages}
                            pageNumber={currentPage}
                            navigateTo={(p) => this.onPageChange(p)} /> : null }
					</div>
					}
				</div>
			</React.Fragment>
		)
	}
};


function mapStateToProps(state) {
	return {
		filterlistInformtion: state.filterModel,
		errorStatusInfo: state.connectivityStatusInformation,
		selectedCountries: state.filterInformation.selectedCountries,
		selectedRegions: state.filterInformation.selectedRegions,
		selectedLocations: state.filterInformation.selectedLocations,
		selectedCustomers: state.filterInformation.selectedCustomers,
		selectedBrands: state.filterInformation.selectedBrands,
		selectedModels: state.filterInformation.selectedModels,
		searchData: state.advanceSearch.searchData
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		setCurrentUnit,
		setUnit,
		geterrorStatusInfo,
		searchVisible,
		searchText
	}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(DashboardUnits));
