import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UnitInfo, UserProfile } from '../../../service/api';
import { bindActionCreators } from 'redux';
import http from '../../../service/httpService';
import { Link } from 'react-router-dom';
import { brandLocations, unitModels, } from '../../../utils/imgContants';
import uploadMediaToCloud from '../media/uploadMedia';
import { setUserDataModel } from '../../../actions/stateActionsData/userAction';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import appconstants, { BASECOUNTRY_ID } from '../../../utils/appConstants';
import { getSelectedFilterList } from '../../../actions/listView/locations/filterListAction';
import { FormattedMessage, injectIntl } from 'react-intl';
import { searchVisible, searchText, clearSearchText } from '../../../actions/advanceSearch/advanceSearchAction';
import { isEqualObj} from '../../globalSearchBar/selectOption';
import $ from 'jquery';
import _ from 'lodash';
import qs from 'query-string';
import { readArchivesFromCompressedResponse } from '../../../utils/helpers';

var bg = require('../../../images/unit/CremCarrera.svg')
const brandsLogo = unitModels;
const brandsLocationLogo = brandLocations;

class assignMedia extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userData: {},
            roleId: 0,
            userGroup: [],
            userPreference: [],
            loaderIconVisible: false,
            success_modal: {
                open: false,
                message: ""
            },
            error_modal: {
                open: false,
                message: ""
            },
            info_modal: {
                open: false,
                message: ""
            },
            showIcons: false,
            level: 1,
            link: {},
            linkList: {},
            newLinkCreated: false,
            isFormValidated: false,
            showElementInfo: false,
            showLink: [],
            unit: {},
            selectedDynasty: [],
            selectedCgIds: [],
            selectedGroupName: [],
            selectedgroupId: {},
            findTreeLevel: "",
            previouspath: '',
            checkedUnits: [],
            initialload: true,
            nameFromReport: '',
            oldSmartGroupName : "",
            oldSelectedPurposes:[],
            sumartGroupID:"",
            oldSelUnits:[],
            unModifiedOldSelUnits:[],
            findPageName:"",
            veryFirstTime:true,
            mediaSelectedItemsInfo: [],
            // identifySelectedState:[]
        };
        this.filterstate = {
            sCgids: [],
            sCids: [],
            sBrandids: [],
            sModels: [],
            sMachineTypes: [],
        };
        this.showLinks = this.showLinks.bind(this);
        this.getData = this.getData.bind(this);
        this.submitAddMedia = this.submitAddMedia.bind(this);
        this.closeModel = this.closeModel.bind(this);
        this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
        this.onClickListView = this.onClickListView.bind(this);
    }

    onSuccessCloseModal = () => {
        this.props.history.push(this.state.previouspath);
    }
    closeModel() {
        this.setState({
            error_modal: {
                open: false
            }
        })
        this.props.history.push(this.state.previouspath);
    }


    showError = (err) => {
        const { formatMessage } = this.props.intl;
        let { response } = err;
        if (response) {
            let { data } = response;
            let { KC_ERR_400, body } = data;
            if (response && response.status === 400) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: KC_ERR_400 ? KC_ERR_400 : ""
                    }
                });
            } else if (response && response.status === 500) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: body.message ? body.message : ""
                    }
                });
            }
        } else {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: formatMessage({ id: 'KC1065' })
                }

            });
        }
    }

    componentWillMount = () => {
        let { url, data, srcfile, imageBuffer, previouspath } = this.props.location;
        this.setState({
            url: url,
            data: data,
            srcfile: srcfile,
            imageBuffer: imageBuffer,
            previouspath: previouspath
        });
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#F2F2F2";
        $("div").removeClass("modal-backdrop fade show");
        this.props.searchVisible(true);
        this.getCustGroupID();
        window.addEventListener('resize', this.updateDimensions);
        let { url, data, srcfile, imageBuffer, previouspath } = this.props.location;
        this.setState({
            url: url,
            data: data,
            srcfile: srcfile,
            imageBuffer: imageBuffer,
            previouspath: previouspath
        });
        if(this.props.location.userChecked && this.props.location.userChecked){
            let checkUnit = [];

            for(let i=0; i< this.props.location.userChecked.length; i++){
                const item = this.props.location.userChecked[i];
                checkUnit.push(item);
            }
            this.setState({checkedUnits:checkUnit, isFormValidated: checkUnit.length > 0,selectedDynasty:[]})
        }
    }

    static getDerivedStateFromProps = (props) => ({...props});

    // componentWillUnmount
	componentWillUnmount() {
		this.props.searchText({});
		this.props.searchVisible(false);
	}

    // clear Global Search
	clearGlobalSearch = () => {
		this.props.clearSearchText();
	}

    /**
     * Convert Encoding UTF8 to binary
     */
    convertToBinary = (string) => {
        const codeUnits = new Uint16Array(string.length);
        for (let i = 0; i < codeUnits.length; i++) {
            codeUnits[i] = string.charCodeAt(i);
        }
        return String.fromCharCode(...new Uint8Array(codeUnits.buffer));
    }

    componentDidUpdate(prevState) { 
        this.updateDimensions();
        let { searchData } = prevState;
		let curSearchData = this.state && this.state.searchData;
		if( curSearchData && (isEqualObj( searchData, curSearchData )  === false) ) {
            this.setState({
				currentPage: 1
			},() =>{
				this.getCustGroupID();
			})
		}
    }
	
	updateDimensions() {                
        var windowHeight = $(window).height();
        var assignOrgHeader = $(".assignOrgHeader").height();
        var collapseExpandHeader = $(".collapseExpandHeader").height();
        var selectedUnitH = $(".selectedUnitHeader").height();
        var assignOrgTreeWrprH = ((windowHeight) - (assignOrgHeader + collapseExpandHeader + selectedUnitH)) - 110;
        $(".assignOrgTreeOuter").css("height", assignOrgTreeWrprH);
    }

    
    getData = (url, Cgids) => {
        let responseResult = {
            data: []
        }
        this.setState({
            loaderIconVisible: true
        });
        return new Promise((resolve, reject) => {
            let headers = { 'Content-Type': 'application/json', 'brandids': localStorage.getItem("unitBrandId") };
            if (Cgids) headers.Cgids = Cgids;
            http.get(url, {
                headers,
                data: {}
            }
            ).then(response => {
                resolve(response);

            }).catch(err => {
                reject(err)
            })
        });
    }

    getCustGroupID = () => {
        let custGroupIDList = "";
        var setcustGroupIDList = "";
        let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
        let loggedinUser = loginUser.username;
        this.setState({ loaderIconVisible: true, })

        const values = qs.parse(this.props.location.search);
        if (values.user !== undefined) {
            loggedinUser = values.user;
        }
        this.setState({ queryString: loggedinUser });

        http({
            method: "GET",
            url: `${UserProfile.get_Cust_GroupID}`,
            headers: {
                'customer_id': localStorage.getItem("customerId"),
                'email': loggedinUser,
                'Content-Type': 'application/json',
                'base_country': localStorage.getItem('basecountry'),
                'emulation': localStorage.getItem('emulation') ? 'true' : 'false'
            }, data: {},
        }).then(response => {
            custGroupIDList = response.data.data;
            custGroupIDList.forEach(p => {
                setcustGroupIDList ? setcustGroupIDList = setcustGroupIDList + "," + p.customer_group_id : setcustGroupIDList = p.customer_group_id
            });

            if (setcustGroupIDList != "") {
                this.getOrgnanizationView(setcustGroupIDList);
            }
        }).catch(err => {
            console.log('getCustGroupID catch exception occured',err);
            this.setState({
                linkList: [],
                loaderIconVisible: false,
            })
        });
    }

    processOrgnanizationJSON(orgJSON) {
        // const length = orgJSON.start_level_id;
        const length = 1;
        let findLeveltoShow = orgJSON.level1[0];
        if (length > 1) {
            for (let i = 2; i <= length; i++) {
                findLeveltoShow = findLeveltoShow[`level${i}`][0];
            }
        }

        this.setState({
            linkList: orgJSON,
            findTreeLevel: findLeveltoShow,
            loaderIconVisible: false
        })
    }

    getOrgnanizationView = async (values) => {
        this.setState({ loaderIconVisible: true, })
        let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
        let loggedinUser = loginUser.username;
        let url = `${UserProfile.get_Orgnanization_View + localStorage.getItem("customerId")}?removeCache=${new Date().getTime()}`;
        let { searchData } = this.state;
        if (searchData && searchData.searchtext) {
            url = `${url}&searchText=${searchData.searchtext.toString()}`;
        }
        if (searchData) {
            let searchParam = {};
            Object.keys(searchData).map((item) => {
                if (item !== "searchtext") {
                    searchParam[item] = searchData[item];
                }
            })
            if (searchParam && Object.keys(searchParam).length) {
                url = `${url}&searchParams=${btoa(this.convertToBinary(JSON.stringify(searchParam)))}`;
            }
        }
        await http({
            method: "GET",
            url: url,
            headers: {
                "Cgids": values,
                'brandids': localStorage.getItem("unitBrandId"),
                'Content-Type': 'application/json',
                'useremail': loggedinUser,
                "basecountry": BASECOUNTRY_ID,
                'modelfamilyids': localStorage.getItem("selectedFamilyID")
            }, data: {},
        }).then(async response => {
            // if (response.data.fetchFromS3) {
            //     await $.getJSON(response.data.presignedS3URL, (data) => {
            //         this.processOrgnanizationJSON(data);
            //     });
            // }
            // else {
            //     this.processOrgnanizationJSON(response.data);
            // }
            let orgJSON = await readArchivesFromCompressedResponse(response.data);
			this.processOrgnanizationJSON(orgJSON);
            this.setState({
                cgIds: values
            })
        }).catch(err => {
            console.log('getOrgnanizationView catch exception occured',err);
            this.setState({
                linkList: [],
                loaderIconVisible: false,
            })
        });

    }
    submitAddMedia = (e) => {
        const { formatMessage } = this.props.intl;
        e.preventDefault();
        this.setState({
            loaderIconVisible: true
        });

        let _this = this;
        if (this.state.url != null && this.state.data != null && this.state.srcfile != null) {
            const Finalselecteditems = [...this.state.selectedGroupName];
            let Currentselected = [];
            if (Finalselecteditems != undefined && Finalselecteditems.length > 0) {
                Finalselecteditems.forEach(function (item, index) {
                    Currentselected.push(item.cust_gp_id);
                })
            }else{
                for (let i = 0; i < this.state.checkedUnits.length; i++) {
                    const units = this.state.checkedUnits[i];
                    if (!Currentselected.includes(units.CUSTOMER_GROUP_ID)) {
                        Currentselected.push(units.CUSTOMER_GROUP_ID);
                    }
                }
            }

            this.state.data.cgids = Currentselected;
            uploadMediaToCloud(this.state.url, this.state.data, this.state.srcfile, this.state.imageBuffer).then(response => {
                _this.setState({
                    loaderIconVisible: false,
                    success_modal: {
                        open: true,
                        message: formatMessage({ id: 'KC1515' })
                    },
                });
            }).catch(err => {
                _this.showError(err);
            });
        }
        else {
            this.props.history.push(`/MediaPreparation`);
        }
    }

    getUnitsViaCGID = async (cgid) => {
        this.setState({ loaderIconVisible: true })
        await http({
            method: "GET",
            url: UnitInfo.unitList,
            headers: {
                'Content-Type': 'application/json',
                'cgid': cgid,
                'cid': localStorage.getItem("customerId"),
                'brandid':localStorage.getItem("unitBrandId"),
                "basecountry": BASECOUNTRY_ID,   
                "userid": localStorage.getItem("user"),
            }, data: {},
        }).then(response => {
            if (response && response.data) {
                let unitSelected = this.state.checkedUnits;
                for (let i = 0; i < response.data.units.length; i++) {
                    let { ID, BRAND_ID, BRAND_NAME, CUSTOMER_GROUP_ID } = response.data.units[i];
                    let isExist = unitSelected.filter((i) => i.ID === ID);
                        if (isExist.length === 0) {
                            unitSelected.push({ ID, BRAND_NAME, BRAND_ID,CUSTOMER_GROUP_ID })
                        }
                }
                this.setState({ checkedUnits: unitSelected, loaderIconVisible: false })
            }

        }).catch(err => {
            this.setState({ loaderIconVisible: false })
        });
    }

    unselectCGID = async (cgid) => {
        this.setState({loaderIconVisible: true})

        await http({
            method: "GET",
            url: UnitInfo.unitList,
            headers: {
                'Content-Type': 'application/json',
                'cgid': cgid,
                'cid': localStorage.getItem("customerId"),
                'brandid':localStorage.getItem("unitBrandId"),
                "basecountry": BASECOUNTRY_ID,   
                "userid": localStorage.getItem("user"),
            }, data: {},
        }).then(response => {
            if (response && response.data) {
                let unitSelected = this.state.checkedUnits;
                for (let i = 0; i < response.data.units.length; i++) {
                    let { ID } = response.data.units[i];
                    let index = unitSelected.findIndex((i) => i.ID === ID);
                    unitSelected.splice(index, 1);
                }
                this.setState({ checkedUnits: unitSelected, loaderIconVisible: false })
            }

        }).catch(err => {
        });
    }


    onItemClick = async (e, link, isSelected) => {
        if(!link.sameLevelAccess){
            if(!link.nextLevelAccess){
                return;
            }
        }
        this.setState({
            veryFirstTime: false,
        })
        const copydynasty = [...this.state.selectedDynasty];
        let dynasty = [];
        let checkselectedcopy = true;
        let levelofcountry = 2;
        const previousselected = [...this.state.selectedGroupName];
        let Currentselected = [];
        let matchedornot = false;
        if (previousselected != undefined && previousselected.length > 0) {
            previousselected.forEach(function (item, index) {
                if ((item.category_id == link.category_id) && (item.level_id == levelofcountry && link.level_id == levelofcountry) && (item.cust_gp_id != link.cust_gp_id) && (item.dynasty != link.dynasty) && (item.group_name != link.group_name)) {
                    matchedornot = true;
                }
            })
        }

        if (copydynasty.length) {
            copydynasty.forEach(el => {
                if (el === link.dynasty) {
                    dynasty = copydynasty.filter(el => el !== link.dynasty);
                    Currentselected = previousselected.filter(s => s.dynasty !== link.dynasty);
                    checkselectedcopy = false;
                }
                else if (el && el.includes(link.dynasty)) {
                    dynasty = copydynasty.filter(fl => fl !== el);
                    dynasty = [...dynasty, link.dynasty];

                    Currentselected = previousselected.filter(s => s.dynasty !== el);
                    Currentselected = [...Currentselected, link];
                }
                else if ((el && link.dynasty.includes(el)) && (!matchedornot)) {
                    dynasty = copydynasty.filter(fl => fl !== el);
                    dynasty = [...dynasty, link.dynasty];

                    Currentselected = previousselected.filter(s => s.dynasty !== el);
                    Currentselected = [...Currentselected, link];

                    checkselectedcopy = false;
                }
                else if (checkselectedcopy) {
                    dynasty = [...copydynasty, link.dynasty];
                    Currentselected = [...previousselected, link]
                }
            })
        }
        else {
            dynasty = [...copydynasty, link.dynasty];
            Currentselected = [...previousselected, link]
        }

        let cgid = [],prevCgid=[];
        for(let i=0; i<Currentselected.length; i++){
            cgid.push(Currentselected[i].cust_gp_id)
        }
        for(let i=0; i<previousselected.length; i++){
            if(!cgid.includes(previousselected[i].cust_gp_id)){
                prevCgid.push(previousselected[i].cust_gp_id)
            }
        }

        if(prevCgid.length || !isSelected){
            await this.unselectCGID(prevCgid.length ? prevCgid.join(',') : link.cust_gp_id)
        }
        if(isSelected){
            this.getUnitsViaCGID(cgid.join(','));
        }

        this.setState({
            link: link, isUnitItemClicked: false,
            selectedDynasty: dynasty,
            selectedGroupName: Currentselected,
            initialload: false,
            isFormValidated: dynasty.length > 0 ? true : false
        })
    }

    onUnitItemClick(e, unit) {
        let unitSelected = this.state.checkedUnits;

        let isExist = unitSelected.filter((i) => i.ID === unit.id);

        if (isExist.length > 0) {
            const findIndex = unitSelected.findIndex((i) => i.ID === unit.id);
            unitSelected.splice(findIndex, 1);
        } else {
            unitSelected.push({ ID: unit.id, BRAND_ID: unit.BRAND_ID, BRAND_NAME: unit.BRAND_NAME, CUSTOMER_GROUP_ID: unit.CUSTOMER_GROUP_ID });
        }

        this.setState({
            checkedUnits: unitSelected,
            isFormValidated: unitSelected.length > 0,
            isUnitItemClicked: true,
            link: {},
            isAddressAvailable: false,
            unit: unit,
            isLoactionTypeAvailable: false,
            level: 6
        })
    }
    onOrgCheckBoxChange(e, unit) {
        let unitSelected = this.state.checkedUnits;

        let isExist = unitSelected.filter((i) => i.ID === unit.id);

        if (isExist.length > 0) {
            const findIndex = unitSelected.findIndex((i) => i.ID === unit.id);
            unitSelected.splice(findIndex, 1);
        } else {
            unitSelected.push({ ID: unit.id, BRAND_ID: unit.BRAND_ID, BRAND_NAME: unit.BRAND_NAME, CUSTOMER_GROUP_ID: unit.CUSTOMER_GROUP_ID });
        }

        this.setState({
            isFormValidated: unitSelected.length > 0 ? true : false,
            checkedUnits: unitSelected,
            isUnitItemClicked: true, link: {}, isAddressAvailable: false, unit: unit,
            isLoactionTypeAvailable: false, level: 6,
        })
    }

    showUnits(link) {
        const { formatMessage } = this.props.intl;
        const showCollpaeCount = this.showCollpaseItemCount(link, true);
        if (link.level_id === 5 && link.units && link.units.length > 0) {
            return link.units.map((unit, index) => {
                let treeActive = unit.ACTIVATION_STATUS === "INACTIVE" ? "disabled" : !unit.THING ? "disabled" : ""
                treeActive = "";
                const treetitle = !unit.THING ? 'KC1735' : '';
                // let pickUnit = brandsLogo.find(b => b.name === unit.MODEL_NAME);
                let pickUnit = {};
                const brandImageStorage = localStorage.getItem('modelImage') ? JSON.parse(localStorage.getItem('modelImage')) : [];
                const filteredBrandImage = brandImageStorage && brandImageStorage.length ? brandImageStorage.filter((i) => i.BRAND_NAME === unit.BRAND_NAME && i.MODEL_NAME === unit.MODEL_NAME) : [];
                if (filteredBrandImage && filteredBrandImage.length && filteredBrandImage[0].LOGO_URL && filteredBrandImage[0].LOGO_URL !== "" ) {
                    pickUnit['img'] = filteredBrandImage[0].LOGO_URL;
                    pickUnit['alt'] = filteredBrandImage[0].MODEL_NAME;
                    pickUnit['title'] = filteredBrandImage[0].MODEL_NAME;
                }else{
                    pickUnit = brandsLogo.find(b => b.name === unit.MODEL_NAME);
                }
                let unitInActive = unit.ACTIVATION_STATUS === "INACTIVE" ? "unitDeactive" : !unit.THING ? "unitNotProvisioned" : ""
                unitInActive = "";
                let smartGroupDet = unit.smartGroup ? unit.smartGroup : [];
                let smartGroupName = [];
                if (smartGroupDet.length > 3) {
                    smartGroupName.push({
                        "sgName": "",
                        "sgFirstName": smartGroupDet.length,
                    })
                } else if (smartGroupDet.length > 0) {
                    smartGroupDet.map((item, i) => {
                        smartGroupName.push({
                            "sgName": item.name,
                            "sgFirstName": item.smartGroupKeyName ? item.smartGroupKeyName : item.name.charAt(0),
                        })
                    })
                }
                let ifExist = false;
                let isParentDivActive = "";
                ifExist = this.state.checkedUnits.find(a2 => a2.ID === unit.id);
                ifExist = ifExist ? true : false;
                treeActive = !unit.THING ? 'disabled' : ifExist ? "treeActive" : "";
                isParentDivActive = ifExist ? 'treePrentDivActive' : '';

                return <li>
                    <div className={'orgznSiteTreeLiDiv' + ' ' + isParentDivActive} title={treetitle? formatMessage({ id: treetitle }): treetitle}>
                        {!showCollpaeCount && <div class="customCheckbox">
                            <input id={unit.UNITID} name={unit.UNITID} type="checkbox" checked={ifExist} value={unit.UNITID} onChange={(e) => this.onOrgCheckBoxChange(e, unit)} />
                            <label for={unit.UNITID}></label>
                        </div>}
                        <Link to="#" className={treeActive}
                            onClick={(e) => { this.onUnitItemClick(e, unit) }}>
                            {showCollpaeCount && <div><span className="treeImg">
                                <img src={require("../../../images/unit/CremCarrera.svg").default} />
                            </span>
                                <span className="treeText deviceModel"
                                ><b>{unit.UNITID}</b></span>
                                <span className="treeCountDispaly" >{showCollpaeCount} </span></div>}
                            {!showCollpaeCount && <div><span className="treeImg">
                                <img src={pickUnit ? pickUnit.img : bg} />
                            </span>
                                <span className="treeText deviceModel">{unit.UNITID}<b>{unit.UNIT_NUMBER}</b> <b>{unit.MODEL_NAME}</b></span>
                                <span className={unitInActive}></span>
                                {/* {smartGroupName.map((item, i) => {
                                    return <span className="sgFL">{item.sgFirstName}&nbsp;</span>
                                })} */}
                            </div>}
                        </Link>
                    </div>
                </li>
            });
        }
    }
    checkSamelevel(pDynasty, cDynasty) {
        let pDyn = [];
        let cDyn = [];
        let sameLevel = true;
        if (pDynasty && pDynasty.length > 0) {
            pDyn = pDynasty.split(',')
        } else {
            sameLevel = false;
        }
        if (cDynasty && cDynasty.length > 0) {
            cDyn = cDynasty.split(',')
        } else {
            sameLevel = false;
        }
        pDyn.map((i, index) => {
            if (pDyn[index] !== cDyn[index]) return sameLevel = false;
        })
        return sameLevel;

    }
    onCollapseButtonClick(e, link) {

        let showLink = this.state.showLink;
        let collpaseObj = { dynasty: '', collapsed: false, count: 0 }
        const findIndex = showLink.findIndex(item => item.dynasty === link.dynasty);
        if (e.target['className'].includes('collapsed')) {
            collpaseObj = { dynasty: link.dynasty, collapsed: false, count: link.child_count }
        } else {
            collpaseObj = { dynasty: link.dynasty, collapsed: true, count: link.child_count }
        }
        if (findIndex > -1) {
            showLink[findIndex] = collpaseObj;
        } else {
            showLink.push(collpaseObj)
        }

        this.setState({ showLink: showLink })
    }
    showCollpaseItemCount(link, showUnits = false) {
        const { showLink } = this.state;
        let collapseItem;
        if (showUnits) {
            collapseItem = showLink.find(item => {
                if (link.dynasty && this.checkSamelevel(item.dynasty, link.dynasty) && item.collapsed &&
                    item.dynasty.split(',').length === link.dynasty.split(',').length)
                    return item;
            });
        } else {
            collapseItem = showLink.find(item => {
                if (link.dynasty && this.checkSamelevel(item.dynasty, link.dynasty) && item.collapsed &&
                    item.dynasty.split(',').length + 1 === link.dynasty.split(',').length)
                    return item;
            });
        }
        if (collapseItem) {
            if (link.dynasty && collapseItem) {
                return collapseItem.count;
            }
            return null;
        } else {
            return null;
        }
    }
    showLinks(linkList) {
        let level;
        let groupName = '';
        let picLocation;
        if (!linkList) return null;
        if (linkList && linkList.length > 0) {
            return linkList.map((link, index) => {
                level = link.level_id;
                groupName = link.group_name;
                const nextLevel = level + 1;
                const id = link.cust_gp_id + link.parent_group_id + link.category_id + link.level_id;
                const leveClassName = 'level' + nextLevel + 'Expand0' + id;
                const { selectedDynasty } = this.state;


                const isParentDivActive = (link.dynasty && selectedDynasty.indexOf(link.dynasty) > -1) ? 'treePrentDivActive' : '';
                const treeActive = (link.dynasty && selectedDynasty.indexOf(link.dynasty) > -1) ? 'treeActive' : '';

                const showCollpaeCount = this.showCollpaseItemCount(link);

                const location_type_Select = JSON.parse(link.dynastyAttributes);
                if (location_type_Select && location_type_Select.location_type) {
                    picLocation = brandsLocationLogo.find(b => b.name === location_type_Select.location_type);
                }
                return <li>
                    <div className={'orgznSiteTreeLiDiv' + ' ' + isParentDivActive}>
                        <Link to="#" className={(link.level_id === 5 && link.units && link.units.length > 0)
                            || (link['level' + nextLevel] && link['level' + nextLevel].length > 0)
                            ? 'treeNodeAvailable' + ' ' + treeActive : 'treeNodeAvailableNoLast' + ' ' + treeActive}
                            onClick={(e) => { this.onItemClick(e, link, isParentDivActive === '') }}>
                            {/* {!showCollpaeCount && <div class="customCheckbox" style={{ display: 'list-item' }}>
                                <input id={level} name={groupName} type="checkbox" checked={isParentDivActive ? true : false} value={groupName} onChange={(e) => this.onItemClick(e, link)} />
                                <label for={level}></label>
                            </div>} */}
                            {showCollpaeCount && <div><span className="treeImg">
                                <img src={require("../../../images/unit/CremCarrera.svg").default} />
                            </span>
                                <span className="treeText deviceModel">
                                    <b>{groupName}</b></span>
                                <span className="treeCountDispaly" >{showCollpaeCount} </span></div>}
                            {!showCollpaeCount && <div><span className="treeImg">
                                {/* <img src={require("../../../images/unit/CremCarrera.svg")}/> */}
                                <img src={link.imagePath ? link.imagePath : picLocation ? picLocation.img : bg} />
                            </span>
                                <span className="treeText deviceModel">
                                    <b>{groupName}</b></span></div>}
                        </Link>
                        {((link['level' + nextLevel] && link['level' + nextLevel].length > 0) ||

                            (link.level_id === 5 && link.units && link.units.length > 0)) &&
                            <button className="btn btn-primary expdCollpse  " type="button" data-toggle="collapse"
                                data-target={'#' + leveClassName} aria-expanded="false" aria-controls={leveClassName}
                                onClick={(e) => this.onCollapseButtonClick(e, link)}>
                                &nbsp;</button>}
                        <ul className="collapse show" id={leveClassName}>
                            {this.showLinks(link['level' + nextLevel])
                            }
                            {this.showUnits(link)}
                        </ul>
                    </div>
                </li>

            });
        }
        level = linkList.level_id;
        groupName = linkList.group_name;
        const nextLevel = level + 1;
        const id = linkList.cust_gp_id + linkList.parent_group_id + linkList.category_id + linkList.level_id;
        const leveClassName = 'level' + nextLevel + 'Expand0' + id;
        const isParentDivActive = this.checkSamelevel(this.state.link.dynasty, linkList.dynasty) ? 'treePrentDivActive' : ''
        const treeActive = linkList.dynasty === this.state.link.dynasty ? 'treeActive' : ''
        return <ul>
            <li>
                <div className={'orgznSiteTreeLiDiv' + ' ' + isParentDivActive}>
                    <Link to="#" className={linkList['level' + nextLevel] && linkList['level' + nextLevel].length > 0
                        ? 'treeNodeAvailable' + ' ' + treeActive : 'treeNodeAvailableNoLast' + ' ' + treeActive}
                        onClick={(e) => { this.onItemClick(e, linkList, isParentDivActive === '') }}>
                        <span className="treeImg">
                            <img src={require("../../../images/unit/CremCarrera.svg").default} />
                        </span>
                        <span className="treeText deviceModel"
                        ><b>{groupName}</b></span>
                    </Link>
                    {((linkList['level' + nextLevel] && linkList['level' + nextLevel].length > 0) ||

                        (linkList.level_id === 5 && linkList.units && linkList.units.length > 0)) &&
                        <button className="btn btn-primary expdCollpse " type="button" data-toggle="collapse"
                            data-target={'#' + leveClassName} aria-expanded="false" aria-controls={leveClassName}
                            onClick={(e) => this.onCollapseButtonClick(e, linkList)}>
                            &nbsp;</button>}

                    <ul className="collapse show " id={leveClassName}>
                        {linkList['level' + nextLevel] && this.showLinks(linkList['level' + nextLevel])}
                        {this.showUnits(linkList)}
                    </ul>

                </div>
            </li>
        </ul>
    }
    
    onClickListView = () => {
        let { url, data, srcfile, imageBuffer, previouspath } = this.props.location;

        this.props.history.push({
            pathname: "/assignMediaUpdateList",
            userCheckedData: this.state.checkedUnits,
            previouspath,
            url,
            data,
            srcfile,
            imageBuffer
        });
    }

    render() {
        const { formatMessage } = this.props.intl;
        let { success_modal, info_modal, loaderIconVisible, selectedGroupName, linkList } = this.state;
        const deviceBrandname = localStorage.getItem("unitBrandId");
        let levelHeader = null;
        if (_.isObject(linkList)) {
            const { categoryList } = linkList;
            if (categoryList) {
                levelHeader = (
                    categoryList.filter(el => el.level_id >= 1).map(item => <li>
                        <Link key={item.level_id} to="#" title={formatMessage({ id: 'KC1693' }, { number: item.level_id })}>{item.category_name}</Link>
                    </li>)
                );
            }
        }
        
        return (
            <div id="adminWrapper">
                <div className="treeInfoToggleTransparent"></div>
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <div id="page-content-wrapper">
                    <div className="orgznManagementWrapper">
                        <div className="assignOrgHeader">
                        <ul>
                                <li>&nbsp;</li>
                                <li>
                                    <FormattedMessage id="KC1526"/>
					            </li>
                                <li >
                                    <Link id="#btnfinish" to="/MediaPreparation" className={`btn btn-default-text ${selectedGroupName.length>0 || this.state.checkedUnits.length > 0 ? "activeState" : "disabled"}`} title={formatMessage({ id: 'KC0056' })} onClick={(e) => this.submitAddMedia(e)}><FormattedMessage id="KC0056"/></Link>
                                </li>
                            </ul>
                        </div>
                        <div className="selectedUnitHeader">
                            <ul>
                                 
                                <li>
                                    {
                                    selectedGroupName.map((subitem, j) =>
                                        <span className="selectedVal">
                                        {subitem.group_name} &nbsp; 
                                        </span>
                                    )}
                                </li>
                                <li>
                                    <Link to="#" class="assignunitSite active">&nbsp;</Link>
                                    <a id="btnAssign" href="javascript:void(0)" className="assignunitList" onClick={()=>this.onClickListView()}>&nbsp;</a>
                                </li>
                            </ul>
                        </div>
                         
                            <div className="collapseExpandHeader">
                                <ul>
                                    {levelHeader}
                                </ul>
                            </div>

                            <div className="assignOrgTreeOuter">

                                <div className="orgznSiteTree">
                                    {this.state.linkList && this.state.linkList.level1 &&
                                        this.state.linkList.level1.map((item) => this.showLinks(this.state.findTreeLevel))}
                                </div>

                            </div>
                        
                    </div>
                </div>
                <ErrorMoal open={info_modal.open} stringID={info_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />                                
                <UserManagementHeader headerName={`Media – ${deviceBrandname === "1" ? "Merrychef" : "Frymaster"} Library`} headerNameStringID={formatMessage({ id: 'KC0152' }) + " – " + (deviceBrandname === "1" ? "Merrychef " : "Frymaster ") + formatMessage({ id: 'KC0658' })} activeClass="mediaNav" filterKey={appconstants.smartGroupFilter} history={this.props.history}/>
            </div>

        )
    }
};

function mapStateToProps(state) {
    return {
        userModel: state.userReducer.UserModel,
        filterlistInformtion: state.filterModel,
        mediaSelectedItemsInfo: state.mediaPreparationListInformation.mediaSelectedItems,
        searchData: state.advanceSearch.searchData
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setUserDataModel,
        getSelectedFilterList,
        searchVisible, searchText, clearSearchText
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(assignMedia));