import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { v1 as uuidv1 } from 'uuid';
import { nanoid } from 'nanoid';
import _ from 'lodash';
import $ from 'jquery';
import JSZip from 'jszip';
import ReactDragListView from "react-drag-listview";
import { injectIntl, FormattedMessage } from 'react-intl';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { ModelFamilyList } from '../../../../../components/controls/modelFamilyList';
import ErrorMoal from '../../../../modal/error-model';
import LibraryListPopup from '../../libraryListPopup';
import { UserProfile, MenuUploadAPI, organizationNode, UnitInfo } from '../../../../../service/api';
import { CUSTOMER_ID, BRAND_ID_VALUE, BASECOUNTRY_ID, BRAND_ID, RESTRICTED_CHAR, restrictedCharAvl } from '../../../../../utils/appConstants';
import http from '../../../../../service/httpService';
import uploadLibraryToCloud from '../../../menus/uploadLibrary';
import SuccessMoal from '../../../../modal/success-model';
var AdmZip = require('adm-zip');
var request = require('request');
var cookbookJSON = require('../../Templates/CookbookJSON');
var beautify = require("json-beautify");
var LZString = require('lz-string');
require('jquery-mousewheel')
var todaysDate = new Date();
let todaysDateUTCFormatted = format(todaysDate, 'YYYY-MM-DDTHH:mm:ss.sss');

class CookbookEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(localStorage.getItem("customerId")),
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            selectedModel: 0,
            categoryList: [],
            deleteCategoryVisible: false,
            editEnabled: false,
            isChanged: false,
            isEdit: false,
            isImport: false,
            showDiscardPopup: false,
            isDeleteCategory: false,
            selectedCategory: {},
            cookbook_name: '',
            errorCookbookName: '',
            jsonBuffer: '',
            librarySettingsId: ''
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.redirect = this.redirect.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.onSaveCookbookButtonClick = this.onSaveCookbookButtonClick.bind(this);
        this.addCategory = this.addCategory.bind(this);
        this.onclickEditCookbook = this.onclickEditCookbook.bind(this);
        this.onDeleteCategory = this.onDeleteCategory.bind(this);
        this.onCategorySelect = this.onCategorySelect.bind(this);
        this.onSaveButtonClick = this.onSaveButtonClick.bind(this);
        this.OnDiscard = this.OnDiscard.bind(this);
        this.OnCancel = this.OnCancel.bind(this);
        this.closeModel = this.closeModel.bind(this);
        this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
        this.OnImport = this.OnImport.bind(this);
        this.onCookbookNameChange = this.onCookbookNameChange.bind(this);
        this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
        this.onSettingsNavigation = this.onSettingsNavigation.bind(this);
    }

    componentDidMount = async () => {
		window.addEventListener('resize', this.updateDimensions());
        document.body.style.backgroundColor = "#000000";
        localStorage.removeItem("isEditSubCategory");
        localStorage.removeItem("EditSubCategoryData");
        localStorage.removeItem("isEditCategory");
        localStorage.removeItem("EditCategoryData");
        try {
            if(!localStorage.getItem("isConnexUser")){
                await this.getConnexUnits();
            }
            let categoryDetails = localStorage.getItem("resourceDetails");
            let categoryDetailsObj = JSON.parse(categoryDetails);
			let pressAndGoObj = categoryDetailsObj.filter(c => c.ID === 'Press&Go');
			let category_flag = false;
            let allRecipesFlag = false;
			let pressAndGoFlag = false;
            if(categoryDetailsObj && categoryDetailsObj.length > 0) {
                category_flag = true;
            }
            if(pressAndGoObj && pressAndGoObj.length > 0) {
                pressAndGoFlag = true;
            }
            let allRecipeDetails = LZString.decompress(localStorage.getItem("allRecipeDetails"));
            allRecipeDetails = allRecipeDetails ? JSON.parse(allRecipeDetails) : [];
            if (allRecipeDetails.length > 0) {
                allRecipesFlag = true
            }
			let libraryDetails = JSON.parse(localStorage.getItem("libraryCookbookDetails"));
            let categoryListObj = categoryDetailsObj.filter(c => c.ID !== 'Press&Go');
            this.setState({
                categoryList: categoryListObj,
                isChanged: category_flag || allRecipesFlag || pressAndGoFlag ? true : false,
                isEdit: libraryDetails.isEdit,
                isImport: libraryDetails.Import,
                cookbook_name: libraryDetails.cookbookName ? libraryDetails.cookbookName : localStorage.getItem("MerryChefCookBookFileName"),
                setting_name: libraryDetails.cookbookName,
                selectedModel: parseInt(localStorage.getItem("selectedProductID"))
            });
            if (this.props.history.location.state.getSelectedDynasty) {
                let node = this.props.history.location.state.getSelectedDynasty;
                let selectedUnitDetails = this.props.history.location.state.getSelectedUnitDetails;
                await this.getNodeCaptionDetails(node, selectedUnitDetails);
            }
        } catch (error) {
            //Error Goes Here
        }
    }

    updateDimensions() {
		const windowHeight = $(window).height();
		const $tableBody = $('.pageScrollbar');
		$tableBody.css('height', windowHeight - ($tableBody.offset().top));
	}

    componentDidUpdate = () => {
		this.updateDimensions();
	}

    componentWillMount = () => {
        let { previouspath } = this.props.location;
        let libraryDetails = localStorage.getItem("libraryCookbookDetails");
        let libraryDetailsObj = JSON.parse(libraryDetails);
        let libraryImage = localStorage.getItem("libraryImage");
        this.setState({
            previouspath: previouspath,
            libraryDetails: libraryDetails,
            imageDataBuffer: Buffer.from(libraryImage, "base64"),
            isEdit: libraryDetailsObj.isEdit,
            isImageChanged: libraryDetailsObj.isImageChanged,
            imageDatab64: libraryImage
        });
    }

    onSuccessCloseModal = () => {
        this.setState({
            loaderIconVisible: false,
            success_modal: {
                open: false
            }
        })
        this.props.history.push({
            pathname: "/libraryList",
            previouspath: "/cookbookEditor",
        });
    }

    redirect = () => {
        this.props.history.push({
            pathname: "/recipeCreation",
            isEdit: false,
            previouspath: "/recipeCreation"
        });
    }

    closeModel = () => {
        this.setState({
            error_modal: {
                open: false
            }
        })
    }

    OnCancel = () => {
        this.setState({
            showDiscardPopup: false,
            isDeleteCategory: false,
            errorCookbookName: ""
        })
    }

    onDeleteConfirm = () => {
        this.setState({
            loaderIconVisible: true
        })
        let categoryDetails = localStorage.getItem("resourceDetails");
        let categoryDetailsObj = JSON.parse(categoryDetails);
        let changedCategoryDetailsObj = categoryDetailsObj.filter(c => c.ID !== this.state.selectedCategory.ID);
        let categoryDetailsWithouPressGoObj = changedCategoryDetailsObj.filter(c => c.ID !== 'Press&Go');
        localStorage.setItem("resourceDetails", JSON.stringify(changedCategoryDetailsObj));
        this.setState({
            loaderIconVisible: false,
            categoryList: categoryDetailsWithouPressGoObj,
            isChanged: changedCategoryDetailsObj.length > 0 ? true : false,
            isDeleteCategory: false
        });
    }

    getConnexUnits = () => {
        const { formatMessage } = this.props.intl;
		this.setState({
			loaderIconVisible: true
		});
		let url = `${UnitInfo.list}`;
		let pageNumber = '?pageNum=1';
		let pageLimit = '&pageSize=50';

		let apiUrl = url + pageNumber + pageLimit;

		let searchParam = {
			'modelName': 'connex'
		};
		apiUrl = `${apiUrl}&searchParams=${btoa(JSON.stringify(searchParam))}`;
		return new Promise((resolve, reject) => {
			http.get(apiUrl, {
				headers: {
					'Content-Type': 'application/json',
					'cgid': localStorage.getItem("custGroupID"),
					'cid': CUSTOMER_ID,
					'brandid': BRAND_ID,
					'model': "",
					'machinetype': "",
					'sortby': "",
					'sorttype': ""
				},
				data: {}
			}).then(response => {
				localStorage.setItem("isConnexUser", response &&  response.data && response.data.units ? response.data.units.length > 0 : false)
                this.setState({
                    loaderIconVisible: false
                });
                resolve(response);
			}).catch(err => {
                this.setState({
                    loaderIconVisible: false
                });
				reject(err);
			});
		});
	}

    onSettingsNavigation = () => {
        this.props.history.push({
            pathname: "/settingsEditor",
            isEdit: false,
            previouspath: "/cookbookEditor"
        });
    }

    OnDiscard = () => {
        this.setState({
            loaderIconVisible: true
        })
        let libraryDetails = JSON.parse(localStorage.getItem("libraryCookbookDetails"));
        let path = "/cookbookParameters";
        if (libraryDetails.isEdit) {
            path = "/libraryList";
        }
        this.props.history.push({
            pathname: path,
            isEdit: false,
            previouspath: "/cookbookEditor"
        });
    }

    onclickEditCookbook = () => {
        this.setState({
            deleteCategoryVisible: !this.state.deleteCategoryVisible,
            editEnabled: !this.state.editEnabled,
        });
    }

    editCategory = (e, item) => {
        localStorage.setItem("isEditCategory", true);
        localStorage.setItem("EditCategoryData",JSON.stringify(item));
        this.props.history.push({
            pathname: "/cookbookAddCategory",
            isEdit: true,
            previouspath: "/cookbookEditor"
        });
    }

    addCategory = () => {
        this.props.history.push({
            pathname: "/cookbookAddCategory",
            isEditCategory: false,
            isEdit: false,
            previouspath: "/cookbookEditor"
        });
    }

    onCategorySelect = (e, item) => {
        item.selected = true;
        delete item.imageData;
        delete item.thumbnailData;
        if (item.subCategory) {
            item.subCategory.map((subitem, j) => {
                delete subitem.imageData;
                delete subitem.thumbnailData;
            })
        }
        localStorage.setItem("selectedCategory", JSON.stringify(item));
        this.props.history.push({
            pathname: "/cookbookSubCategoryList",
            isEdit: false,
            previouspath: "/cookbookEditor"
        });
    }

    getNodeCaptionDetails = async (val, val1) => {
        this.setState({ loaderIconVisible: true });
        const url = organizationNode.nodeDetails + "96";
        await http.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'cid': localStorage.getItem("customerId"),
                'dynasty': val,
            }, data: {}
        }).then(response => {
            if (response && response.data) {
                let result = response.data;
                let nodeDetails;
                result.map((item, i) => {
                    if (nodeDetails) {
                        if ((result.length - 1) === i) {
                            nodeDetails = nodeDetails + item.node_name;
                        } else {
                            nodeDetails = nodeDetails + item.node_name + "/";
                        }
                    } else {
                        nodeDetails = item.node_name + "/";
                    }
                })
                this.setState({
                    loaderIconVisible: false,
                    nodeCaptionDetails: nodeDetails,
                    selectedUnitDetails: val1,
                });
            }
        }).catch(err => {
            this.setState({ loaderIconVisible: false });
        });
    }

    onSaveButtonClick = async (action) => {
        $('#saveNameModal').modal('hide');
        if (this.state.isChanged) {
            let cookbookName = this.state.cookbook_name.trim();
            if (cookbookName.length < 1 || cookbookName.length > 20) {
                this.setState({
                    errorCookbookName: "KC1240"
                })
            }
            else {
                let libraryDetails = JSON.parse(localStorage.getItem("libraryCookbookDetails"));
                libraryDetails.cookbookName = cookbookName;
                libraryDetails.settingName = cookbookName;
                localStorage.setItem("libraryCookbookDetails", JSON.stringify(libraryDetails));
                if (action === "Test") {
                    this.props.history.push({
                        pathname: "/recipeAssignTopology",
                        previouspath: "/cookbookEditor",
                    });
                }
                else {
                    let parentCustGrpId = localStorage.getItem("parentCustGroupId") || localStorage.getItem("custGroupID");
                    console.log("Category Settings parentCustGrpId==>",parentCustGrpId);
                    let cgid = [];
                    cgid.push(parentCustGrpId);
                    await this.submitSettingAddLibrary(cgid);
                }
            }
        }
        else {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1241"
                }
            });
        }
    }

    onDeleteCategory = (e, item) => {
        this.setState({
            selectedCategory: item,
            loaderIconVisible: false,
            isDeleteCategory: true
        });
    }

    onBackButtonClick = () => {
        if (!this.state.isChanged) {
            let libraryDetails = JSON.parse(localStorage.getItem("libraryCookbookDetails"));
            let path = "/cookbookParameters";
            if (libraryDetails.isEdit) {
                path = "/libraryList";
            }
            this.props.history.push({
                pathname: path,
                isEdit: false,
                previouspath: "/cookbookEditor"
            });
        }
        else {
            this.setState({
                loaderIconVisible: false,
                showDiscardPopup: true
            });
        }
    }

    onSaveCookbookButtonClick = () => {
        let libraryDetails = JSON.parse(localStorage.getItem("libraryCookbookDetails"));
        $('#saveNameModal').modal('show');
        if(!libraryDetails.isEdit) {
            document.getElementById('cookBookText').value = "";
        }
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    async OnImport(item) {
        this.setState({
            loaderIconVisible: true
        });
        await http.get(MenuUploadAPI.getlibraryDownloadUrl, {
            headers: {
                'Content-Type': 'application/json',
                'library_id': encodeURIComponent(item.LIBRARY_ID),
                'library_isedit': true,
                'encryption' : "true"
            }, data: {}
        }).then(async response => {
            if (response && response.data) {
                let file_path = response.data.presignedURL;
                var libraryFiles = [];
                var b64 = "";
                var imageBuffer = null;
                localStorage.setItem("selectedGroupID", item.CGID);
                let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
                let loggedinUser = loginUser.username;
                    await http.get(MenuUploadAPI.getLibraryList,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'customerid': CUSTOMER_ID,
                            'cgids': localStorage.getItem("custGroupID"),
                            'brandid': localStorage.getItem("selectedbrandID"),
                            'productid': localStorage.getItem("selectedProductID"),
                            'basecountry': BASECOUNTRY_ID,
                            'Type': "Recipe",
                            'createdby': loggedinUser
                        },
                        data: {}
                    }).then(response => {
                        let recipeData = response.data?.libraryList || [];

                        if (response.data?.fetchFromS3) {
                            const data = this.fetchDataFromS3(response.data.presignedS3URL);
                            recipeData = data.libraryList;
                        }

                        try {
                            request.get({ url: file_path, encoding: null }, (err, res, body) => {
                                var zip = new AdmZip(body);
                                var zipEntries = zip.getEntries();

                                zipEntries.forEach((entry, i) => {
                                    if (item.TYPE === "CookBook") {
                                        libraryFiles[i] = {};
                                        libraryFiles[i].fileName = entry.entryName.replace('Resources/Images/', '');
                                        imageBuffer = zip.readFile(entry);

                                        if (!entry.entryName.includes('.json')) {
                                            libraryFiles[i].fileBuffer = imageBuffer.toString('base64');
                                        }
                                        else {
                                            libraryFiles[i].fileBuffer = imageBuffer;
                                            b64 = imageBuffer.toString('base64');
                                        }
                                    }
                                    else {
                                        libraryFiles[i] = zip.readFile(entry);

                                        if (!entry.entryName.includes('.json')) {
                                            imageBuffer = Buffer.from(libraryFiles[i]);
                                            b64 = imageBuffer.toString('base64');
                                        }
                                    }
                                });

                                localStorage.setItem("selectedFamilyID", item.MODEL_FAMILY ? Array.prototype.map.call(item.MODEL_FAMILY, m => m.ID).toString() : '');
                                let UpdatedJSONObj = JSON.parse(libraryFiles[0].fileBuffer);
                                let md5 = require('md5');
                                let libraryDetails = {};
                                libraryDetails.id = UpdatedJSONObj.id;
                                libraryDetails.library_id = item.LIBRARY_ID;
                                libraryDetails.cookbookName = item.LIBRARY_NAME;
                                libraryDetails.crc = md5(b64, "base64");
                                libraryDetails.isEdit = false;
                                libraryDetails.initialMethod = "Add";

                                //CookBook
                                let categoryDetailsObj = [];
                                UpdatedJSONObj && UpdatedJSONObj.menus && UpdatedJSONObj.menus.map((item, i) => {
                                    let category = {};
                                    category.ID = item.id;

                                    let imageData = null;
                                    let thumbnailData = null;
                                    let imageFileFormat = "";
                                    let imageFileName = "";
                                    let imageFileSize = "";

                                    libraryFiles && libraryFiles.map((libraryitem, j) => {
                                        if (libraryitem.fileName.includes('tmb_')) {
                                            if (libraryitem.fileName.replace("Resources/Images/", "").replace("Resource/", "") === item.image.replace("Resources/Images/", "tmb_").replace("Resources/", "tmb_").replace(".jpeg", ".png").replace(".jpg", ".png").replace(".JPEG", ".png").replace(".JPG", ".png").replace(".PNG", ".png")) {
                                                thumbnailData = libraryitem.fileBuffer;
                                            }
                                        }
                                        else if (!libraryitem.fileName.toLowerCase().includes('.json')) {
                                            if (libraryitem.fileName.replace("Resources/Images/", "").replace("Resource/", "") === item.image.replace("Resources/Images/", "").replace("Resources/", "")) {
                                                imageData = libraryitem.fileBuffer;
                                                let fileName = libraryitem.fileName.replace(/[^a-zA-Z 0-9\n\r.]+/g, '_');
                                                let fileextSplit = fileName.split('.');
                                                let fileExt = fileextSplit[1];
                                                imageFileFormat = "." + fileExt;
                                                imageFileName = libraryitem.fileName;
                                                // read json string to Buffer
                                                let fileSize = Buffer.byteLength(libraryitem.fileBuffer);
                                                imageFileSize = (fileSize / 1048576).toFixed(2);
                                            }
                                        }
                                    });

                                    category.imageFileName = imageFileName;
                                    category.imageFileFormat = imageFileFormat;
                                    category.imageFileSize = imageFileSize;
                                    category.imageS3Url = item.imageS3Url ? item.imageS3Url : "";
                                    category.categoryName = item.name ? item.name.en_GB ? item.name.en_GB : "" : "";
                                    category.imageData = "";
                                    category.thumbnailData = thumbnailData;
                                    category.recipe_items = [];
                                    item.recipe_items && item.recipe_items.map((recipeitem, k) => {
                                        let recipe = recipeData.filter(d => d.LIBRARY_ID === recipeitem);
                                        category.recipe_items[k] = recipe && recipe[0] ? recipe[0] : [];
                                    });
                                    category.subCategory = [];
                                    item.menu_items && item.menu_items.map((subitem, l) => {
                                        let subCategory = {};
                                        subCategory.ID = subitem.id;

                                        let imageData = null;
                                        let thumbnailData = null;
                                        let imageFileFormat = "";
                                        let imageFileName = "";
                                        let imageFileSize = "";

                                        libraryFiles && libraryFiles.map((libraryitem, m) => {
                                            if (libraryitem.fileName.includes('tmb_')) {
                                                if (libraryitem.fileName.replace("Resources/Images/", "").replace("Resource/", "") === subitem.image.replace("Resources/Images/", "tmb_").replace("Resources/", "tmb_").replace(".jpeg", ".png").replace(".jpg", ".png").replace(".JPEG", ".png").replace(".JPG", ".png").replace(".PNG", ".png")) {
                                                    thumbnailData = libraryitem.fileBuffer;
                                                }
                                            }
                                            else if (!libraryitem.fileName.toLowerCase().includes('.json')) {
                                                if (libraryitem.fileName.replace("Resources/Images/", "").replace("Resource/", "") === subitem.image.replace("Resources/Images/", "").replace("Resources/", "")) {
                                                    imageData = libraryitem.fileBuffer;
                                                    let fileName = libraryitem.fileName.replace(/[^a-zA-Z 0-9\n\r.]+/g, '_');
                                                    let fileextSplit = fileName.split('.');
                                                    let fileExt = fileextSplit[1];
                                                    imageFileFormat = "." + fileExt;
                                                    imageFileName = libraryitem.fileName;
                                                    // read json string to Buffer
                                                    let fileSize = Buffer.byteLength(libraryitem.fileBuffer);
                                                    imageFileSize = (fileSize / 1048576).toFixed(2);
                                                }
                                            }
                                        });

                                        subCategory.imageFileName = imageFileName;
                                        subCategory.imageFileFormat = imageFileFormat;
                                        subCategory.imageFileSize = imageFileSize;
                                        subCategory.imageS3Url = subitem.imageS3Url ? subitem.imageS3Url : "";
                                        subCategory.categoryName = subitem.name ? subitem.name.en_GB ? subitem.name.en_GB : "" : "";
                                        subCategory.imageData = "";
                                        subCategory.thumbnailData = thumbnailData;
                                        subCategory.recipe_items = [];
                                        subitem.recipe_items && subitem.recipe_items.map((recipeitem, n) => {
                                            let recipe = recipeData.filter(d => d.LIBRARY_ID === recipeitem);
                                            subCategory.recipe_items[n] = recipe && recipe[0] ? recipe[0] : [];
                                        });
                                        category.subCategory.push(subCategory);
                                    });
                                    categoryDetailsObj.push(category);
                                });

                                // localStorage.setItem("defaultLibraryData", JSON.stringify(categoryDetailsObj));
                                categoryDetailsObj.Import = true;
                                localStorage.setItem("resourceDetails", JSON.stringify(categoryDetailsObj));
                                localStorage.setItem("libraryCookbookDetails", JSON.stringify(libraryDetails));

                                let categoryDetails = localStorage.getItem("resourceDetails");
                                categoryDetailsObj = JSON.parse(categoryDetails);
                                this.setState({
                                    categoryList: categoryDetailsObj,
                                    isChanged: true,
                                    loaderIconVisible: false
                                });
                            });
                        }
                        catch (ex) {
                            console.log(ex);
                            this.setState({
                                loaderIconVisible: false
                            });
                        }
                    });
            }
        })
            .catch(err => {
                console.log(err);
                this.setState({
                    loaderIconVisible: false
                });
            });
    }

    async fetchDataFromS3(presignedS3URL) {
		const s3Response = await fetch(presignedS3URL);
		const arrayBuffer = await s3Response.arrayBuffer();

		const zip = await JSZip.loadAsync(arrayBuffer);
		const file = Object.values(zip.files).find(file => !file.dir);
		if (!file) {
			throw new Error('No files found in zip file');
		}
		const content = await file.async('string');
		return JSON.parse(content);
	}

    onCookbookNameChange = (e) => {
        let restrictedChar = RESTRICTED_CHAR.split('').some(i => restrictedCharAvl(i,e.target.value));
        if (restrictedChar || e.nativeEvent.data === '\\' || e.nativeEvent.data === '"'){
            return;
        }
        // if (e.target.value.match("^[a-zA-Z0-9 !@#$%&*?'()_+-]*$")) {
            this.setState({
                cookbook_name: e.target.value,
                errorCookbookName: ""
            });
        // }
    }

    selectTestDeleteButtonClick = () => {
        this.setState({
            nodeCaptionDetails: null,
            selectedUnitDetails: {},
        });
    }

    selectTestUnitButtonClick = async () => {
        let _this = this;
        let categoryDetails = localStorage.getItem("resourceDetails");
        let categoryDetailsObj = JSON.parse(categoryDetails);
        let httpMethod;

        if (!categoryDetailsObj && !categoryDetailsObj.length > 0) {
            _this.props.history.push(`/cookbookEditor`);
        } else {
            _this.setState({
                loaderIconVisible: true
            })
            let brandName = localStorage.getItem("brandName");
            let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
            let loggedinUser = loginUser.username;

            await http({
                method: "GET",
                url: UserProfile.getUserProfileDetails,
                headers: {
                    'customer_id': CUSTOMER_ID,
                    'email': loggedinUser,
                    'Content-Type': 'application/json',
					'base_country': localStorage.getItem("basecountry"),
					'emulation': localStorage.getItem('emulation') ? 'true' : 'false'
                }, data: {}
            }).then(async response => {
                let libraryId;
                let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
                if (libraryDetails.isEdit) {
                    httpMethod = "PUT";
                    libraryId = libraryDetails.library_id;
                } else {
                    httpMethod = "POST";
                    // libraryId = libraryDetails.cookbookName + '_' + Date.now();
                    // libraryId = libraryId.replace(/\s+/g, '');
                    libraryId = nanoid() + '_' + Date.now();
                    libraryDetails.library_id = libraryId;
                    localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));
                }
                // libraryId = encodeURIComponent(libraryId);

                let jsonTemplateFile = cookbookJSON;
                let jsonTemplateObj = jsonTemplateFile.default;

                let categoryDetailsData = localStorage.getItem("resourceDetails");
                let categoryDetailsObj = JSON.parse(categoryDetailsData);
                jsonTemplateObj.id = libraryDetails.isEdit ? libraryDetails.id : uuidv1();
                jsonTemplateObj.edit_date = todaysDateUTCFormatted;
                jsonTemplateObj.author = response.data.name;
                jsonTemplateObj.email = loginUser.username;

                let imageSize = 0.00;
                jsonTemplateObj.menus = [];
                categoryDetailsObj.map((item, i) => {
                    jsonTemplateObj.menus[i] = {};
                    jsonTemplateObj.menus[i].id = item.ID;
                    jsonTemplateObj.menus[i].edit_date = todaysDateUTCFormatted;
                    jsonTemplateObj.menus[i].author = response.data.name;
                    jsonTemplateObj.menus[i].email = loginUser.username;
                    jsonTemplateObj.menus[i].name = {};
                    jsonTemplateObj.menus[i].name.en_GB = item.categoryName;
                    jsonTemplateObj.menus[i].image = "Resources/Images/" + item.imageFileName;
                    jsonTemplateObj.menus[i].recipe_items = item.recipe_items ? item.recipe_items.map(r => r.LIBRARY_ID) : [];
                    jsonTemplateObj.menus[i].imageS3Url = item.imageS3Url;
                    jsonTemplateObj.menus[i].menu_level = "top";
                    if (item.subCategory) {
                        item.subCategory.map((subitem, j) => {
                            if (j === 0) {
                                jsonTemplateObj.menus[i].menu_items = [];
                            }
                            jsonTemplateObj.menus[i].menu_items[j] = {};
                            jsonTemplateObj.menus[i].menu_items[j].id = subitem.ID;
                            jsonTemplateObj.menus[i].menu_items[j].edit_date = todaysDateUTCFormatted;
                            jsonTemplateObj.menus[i].menu_items[j].author = response.data.name;
                            jsonTemplateObj.menus[i].menu_items[j].email = loginUser.username;
                            jsonTemplateObj.menus[i].menu_items[j].name = {};
                            jsonTemplateObj.menus[i].menu_items[j].name.en_GB = subitem.categoryName;
                            jsonTemplateObj.menus[i].menu_items[j].image = "Resources/Images/" + subitem.imageFileName;
                            jsonTemplateObj.menus[i].menu_items[j].recipe_items = subitem.recipe_items ? subitem.recipe_items.map(r => r.LIBRARY_ID) : [];
                            jsonTemplateObj.menus[i].menu_items[j].imageS3Url = subitem.imageS3Url;
                            jsonTemplateObj.menus[i].menu_items[j].menu_level = "sub";
                            imageSize = imageSize + subitem.imageFileSize ? parseFloat(subitem.imageFileSize) : 0.00;
                        })
                    }
                    imageSize = imageSize + item.imageFileSize ? parseFloat(item.imageFileSize) : 0.00;
                })


                // convert JSON object to beautify String
                var jsonStr = beautify(jsonTemplateObj, null, 2, 80)
                // read json string to Buffer
                const jsonBuffer = Buffer.from(jsonStr);

                let fileSize = Buffer.byteLength(jsonBuffer);
                fileSize = (fileSize / 1048576);
                let md5 = require('md5');
                let crc = md5(jsonBuffer, "base64");

                let categorys = categoryDetailsObj;
                delete categorys.Import;
                categorys && categorys.map((item, i) => {
                    delete item.imageData;
                    delete item.thumbnailData;
                    let recipes = item.recipe_items ? item.recipe_items.map(r => r.LIBRARY_ID) : [];
                    delete item.recipe_items;
                    categorys[i].recipe_items = recipes ? recipes : [];
                    if (item.subCategory) {
                        item.subCategory.map((subitem, j) => {
                            delete subitem.imageData;
                            delete subitem.thumbnailData;
                            let recipes = subitem.recipe_items ? subitem.recipe_items.map(r => r.LIBRARY_ID) : [];
                            delete subitem.recipe_items;
                            categorys[i].subCategory[j].recipe_items = recipes ? recipes : [];
                        })
                    }
                });


                categorys && categorys.map((item, i) => {
                    if (item.subCategory) {
                        item.subCategory.map((subitem, j) => {
                            categorys.push(subitem);
                            subitem = null;
                        })
                    }
                });


                let libraryPostData = {
                    "customerId": CUSTOMER_ID,
                    "brandId": BRAND_ID_VALUE[brandName],
                    "modelFamilyIds": localStorage.getItem("selectedFamilyID"),
                    "libraryId": libraryId,
                    "libraryName": libraryDetails.cookbookName,
                    "libraryFileName": libraryId + ".json",
                    "libraryFileFormat": "json",
                    "libraryFileSize": (fileSize + imageSize).toFixed(2),
                    "libraryS3Url": "",
                    "type": "CookBook",
                    "createdUser": response.data.name + ' (' + loggedinUser + ')',
                    "crc": crc,
                    "baseCountry": BASECOUNTRY_ID,
                    "imageFileName": "",
                    "imageFileFormat": "",
                    "imageFileSize": (imageSize).toFixed(2),
                    "imageS3Url": "",
                    "cgids": [parseInt(localStorage.getItem("custGroupID") ? localStorage.getItem("custGroupID") : 0)],
                    "json": JSON.stringify(jsonTemplateObj),
                    "categories": categorys ? categorys : [],
                    "libraryGuid": jsonTemplateObj.id
                };

                if (libraryDetails.isEdit) {
                    delete libraryPostData.customerId;
                    delete libraryPostData.createdUser;
                    libraryPostData.updatedUser = response.data.name + ' (' + loggedinUser + ')';
                }
                
                if (libraryDetails.initialMethod === "Add") {
                    libraryPostData.status = "DRAFT";
                }

                let headerpresigned = {
                    'Content-Type': 'application/json',
                    'customerid': CUSTOMER_ID,
                    'imagefilekey': "",
                    'jsonfilekey': encodeURIComponent(libraryId) + '.json',
                    'audiofilekey': "",
                    'isthumbnail': false,
                    'encryption' : "true"
                }

                await uploadLibraryToCloud(MenuUploadAPI.getlibraryUploadpresignedUrl, httpMethod, headerpresigned, libraryPostData, null, null, jsonBuffer, false).then(async response => {
                    let {selectedUnitDetails} = this.state;

                    const unitList = [];
                    unitList.push(
                        {
                            "thingArn": selectedUnitDetails.THING,
                            "productId": selectedUnitDetails.ProductId,
                            "cloudUnitName": selectedUnitDetails.CLOUD_UNIT_NAME,
                            "unitTimeZone": selectedUnitDetails.TimeZone_Standard
                        }
                    )

                    let postData = {
                        "customer": CUSTOMER_ID,
                        "brand": brandName,
                        "brandId": BRAND_ID_VALUE[brandName],
                        "user": loggedinUser,
                        "libraryIds": [libraryId],
                        "country": BASECOUNTRY_ID,
                        "type": "Recipe",
                        "thingList": unitList,
                        "userName": loggedinUser,
				        "isTestPush": true
                    };
            
                    let url = MenuUploadAPI.getLibraryUpdateStatus;
                       await http.post(url, postData,
                            {
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            }
                        )
                        .then(response => {
                            let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
                            libraryDetails.isEdit = true;
                            localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));
                        _this.setState({
                            loaderIconVisible: false,
                            isEdit: true,
                            success_modal: {
                                open: true,
                                message: "Library test distributed successfully."
                            },
                            nodeCaptionDetails: null,
                            selectedUnitDetails: {},
                        });
                    }).catch(err => {
                        let { response } = err;
                        let { data } = response;
                        let { message } = data;
                        this.setState({
                            error_modal: {
                                open: true,
                                message: message ? message : ""
                            },
                            loaderIconVisible: false
                        });
                    });
                }).catch(err => {
                    this.setState({
                        loaderIconVisible: false
                    });
                    console.log(err);
                });
            }).catch(err => {
                _this.setState({
                    loaderIconVisible: false
                })
                console.log(err);
            });
        }
    }

    addPressandGo = () => {
        let categoryDetails = localStorage.getItem("resourceDetails");
        let categoryDetailsObj = JSON.parse(categoryDetails);

        let selectedCategoryDetailsObj = categoryDetailsObj.find(c => c.ID === 'Press&Go');
        if (!selectedCategoryDetailsObj) {
            let pressGoObj = {};
            pressGoObj.ID = "Press&Go";
            pressGoObj.categoryName = "Press&Go";
            pressGoObj.imageFileFormat = "";
            pressGoObj.imageFileName = "";
            pressGoObj.imageFileSize = "";
            pressGoObj.imageS3Url = "";
            pressGoObj.parentID = "";
            pressGoObj.selected = false;
            pressGoObj.thumbnailData = null;
            pressGoObj.type = "Category";
            categoryDetailsObj.push(pressGoObj);
            localStorage.setItem("resourceDetails", JSON.stringify(categoryDetailsObj));
        }

        this.props.history.push({
            pathname: "/menurcPressAndGo",
            isEdit: false,
            previouspath: "/cookbookEditor",
            renderType: "Press&Go"
        });
    }

    addAllRecipes = () => {
        let categoryDetails = LZString.decompress(localStorage.getItem("allRecipeDetails"));
        categoryDetails = categoryDetails ? JSON.parse(categoryDetails) : [];
		if (!categoryDetails) {
            categoryDetails = [];
            localStorage.setItem("allRecipeDetails", LZString.compress(JSON.stringify(categoryDetails)));
        }

        this.props.history.push({
            pathname: "/menuAllRecipes",
            isEdit: false,
            previouspath: "/cookbookEditor",
            renderType: "Press&Go"
        });
    }
     //Cookbook creation
     submitCookbookAddLibrary = async (_customId) => {
        let _this = this;
        let categoryDetails = localStorage.getItem("resourceDetails");
        let categoryDetailsObj = JSON.parse(categoryDetails);
        let httpMethod;
        
        if (!categoryDetailsObj && !categoryDetailsObj.length > 0) {
            _this.props.history.push(`/cookbookEditor`);
        } else {
            _this.setState({
                loaderIconVisible: true
            })
            let brandName = localStorage.getItem("brandName");
            let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
            let loggedinUser = loginUser.username;
            http({
                method: "GET",
                url: UserProfile.getUserProfileDetails,
                headers: {
                    'customer_id': CUSTOMER_ID,
                    'email': loggedinUser,
                    'Content-Type': 'application/json',
                    'base_country': localStorage.getItem("basecountry"),
                    'emulation': localStorage.getItem('emulation') ? 'true' : 'false'
                }, data: {}
            }).then(async (response) => {
                let libraryId;
                let _selectedCookbook = localStorage.getItem("Selected_cookbook_item");
                let libraryDetails = JSON.parse(localStorage.getItem("libraryCookbookDetails"));
                if (libraryDetails.isEdit) {
                    httpMethod = "PUT";
                    // libraryId = libraryDetails.library_id;
                    libraryId = _selectedCookbook;
                } else {
                    httpMethod = "POST";
                    // libraryId = libraryDetails.cookbookName + '_' + Date.now();
                    // libraryId = libraryId.replace(/\s+/g, '');
                    libraryId = nanoid() + '_' + Date.now();
                }
                // libraryId = encodeURIComponent(libraryId);
                let jsonTemplateFile = cookbookJSON;
                let jsonTemplateObj = jsonTemplateFile.default;

                let allRecipeDetails = LZString.decompress(localStorage.getItem("allRecipeDetails"));
                allRecipeDetails = allRecipeDetails ? JSON.parse(allRecipeDetails) : [];
			    if(allRecipeDetails.length > 0)
                {
                    // jsonTemplateObj.recipes = allRecipeDetails.map(({ ID, JSON, LIBRARY_ID }) => ({ ID, JSON, LIBRARY_ID }));
                    jsonTemplateObj.recipes = allRecipeDetails.map(({ ID, LIBRARY_ID }) => ({ ID, LIBRARY_ID }));
                    let recipeIDs = jsonTemplateObj.recipes.map(obj => obj.ID);
                    this.setState({
                        loaderIconVisible: true
                    });
                    let url = MenuUploadAPI.getLibraryList;
                    url = `${url}?recipeIDs=${recipeIDs}&getRecipeJSONFlag=true`;
                    let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
                    let loggedinUser = loginUser.username;
                    let headerParams = {
                        'Content-Type': 'application/json',
                        'customerid': CUSTOMER_ID,
                        'cgids': localStorage.getItem("custGroupID"),
                        'brandid': localStorage.getItem("selectedbrandID"),
                        'modelFamilyIds': '',
                        'basecountry': BASECOUNTRY_ID
                    }
                    if (localStorage.getItem("brandName") === "MERRYCHEF") {
                        headerParams = {
                            'Content-Type': 'application/json',
                            'customerid': CUSTOMER_ID,
                            'cgids': localStorage.getItem("custGroupID"),
                            'brandid': localStorage.getItem("selectedbrandID"),
                            'modelFamilyIds': '',
                            'basecountry': BASECOUNTRY_ID,
                            'type': 'Recipe',
                            'createdby': loggedinUser
                        }
                    }
                    const response = await http.get(url, { headers: headerParams, data: {}});
                    let { data } = response;
                    let recipeJSONData = data && data.recipeJSONData ? data.recipeJSONData : [];
                    jsonTemplateObj.recipes = recipeJSONData;
                }
                let categoryDetailsData = localStorage.getItem("resourceDetails");
                let categoryDetailsObj = JSON.parse(categoryDetailsData);
                let PressandGoObj = categoryDetailsObj.find(c => c.ID === "Press&Go");
                jsonTemplateObj.id = libraryDetails.isEdit ? libraryDetails.id : uuidv1();
                jsonTemplateObj.edit_date = todaysDateUTCFormatted;
                jsonTemplateObj.author = response.data.name;
                jsonTemplateObj.email = loginUser.username;
                let imageSize = 0.00;
                jsonTemplateObj.menus = [];
                categoryDetailsObj.map((item, i) => {
                    jsonTemplateObj.menus[i] = {};
                    jsonTemplateObj.menus[i].id = item.ID;
                    jsonTemplateObj.menus[i].edit_date = todaysDateUTCFormatted;
                    jsonTemplateObj.menus[i].author = response.data.name;
                    jsonTemplateObj.menus[i].email = loginUser.username;
                    jsonTemplateObj.menus[i].name = {};
                    jsonTemplateObj.menus[i].name.en_GB = item.categoryName;
                    jsonTemplateObj.menus[i].image = item.imageFileName ? "Resources/Images/" + item.imageFileName : "";
                    jsonTemplateObj.menus[i].recipe_items = item.recipe_items ? item.recipe_items.map(r => r.LIBRARY_ID) : [];
                    jsonTemplateObj.menus[i].imageS3Url = item.imageS3Url;
                    jsonTemplateObj.menus[i].menu_level = "top";
                    if (item.subCategory) {
                        item.subCategory.map((subitem, j) => {
                            if (j === 0 ) {
                                jsonTemplateObj.menus[i].menu_items = [];
                            }
                            jsonTemplateObj.menus[i].menu_items[j] = {};
                            jsonTemplateObj.menus[i].menu_items[j].id = subitem.ID;
                            jsonTemplateObj.menus[i].menu_items[j].edit_date = todaysDateUTCFormatted;
                            jsonTemplateObj.menus[i].menu_items[j].author = response.data.name;
                            jsonTemplateObj.menus[i].menu_items[j].email = loginUser.username;
                            jsonTemplateObj.menus[i].menu_items[j].name = {};
                            jsonTemplateObj.menus[i].menu_items[j].name.en_GB = subitem.categoryName;
                            jsonTemplateObj.menus[i].menu_items[j].image = subitem.imageFileName ? "Resources/Images/" + subitem.imageFileName : "";
                            jsonTemplateObj.menus[i].menu_items[j].recipe_items = subitem.recipe_items ? subitem.recipe_items.map(r => r.LIBRARY_ID) : [];
                            jsonTemplateObj.menus[i].menu_items[j].imageS3Url = subitem.imageS3Url;
                            jsonTemplateObj.menus[i].menu_items[j].menu_level = "sub";
                            imageSize = imageSize + (subitem.imageFileSize ? parseFloat(subitem.imageFileSize) : 0.00);
                        })
                    }
                    imageSize = imageSize + (item.imageFileSize ? parseFloat(item.imageFileSize) : 0.00);
                })
                jsonTemplateObj.PressAndGoItems = PressandGoObj && PressandGoObj.recipe_items ? PressandGoObj.recipe_items.map(r => r.LIBRARY_GUID) : [];
                // convert JSON object to beautify String
                var jsonStr = beautify(jsonTemplateObj, null, 2, 80)
                // read json string to Buffer
                const jsonBuffer = Buffer.from(jsonStr);
                let fileSize = Buffer.byteLength(jsonBuffer);
                fileSize = (fileSize / 1048576);
                let md5 = require('md5');
                let crc = md5(jsonBuffer, "base64");
                let categorys = categoryDetailsObj;
                delete categorys.Import;
                categorys && categorys.map((item, i) => {
                    delete item.imageData;
                    delete item.thumbnailData;
                    let recipes = item.recipe_items ? item.recipe_items.map(r => r.LIBRARY_ID) : [];
                    item.recipe_items && item.recipe_items.map((c, i) => {
                        imageSize = imageSize + parseFloat(c.LIBRARY_FILE_SIZE ? c.LIBRARY_FILE_SIZE : 0);
                    })
                    delete item.recipe_items;
                    categorys[i].recipe_items = recipes ? recipes : [];
                    if (item.subCategory) {
                        item.subCategory.map((subitem, j) => {
                            delete subitem.imageData;
                            delete subitem.thumbnailData;
                            let recipes = subitem.recipe_items ? subitem.recipe_items.map(r => r.LIBRARY_ID) : [];
                            subitem.recipe_items && subitem.recipe_items.map((s, i) => {
                                imageSize = imageSize + parseFloat(s.LIBRARY_FILE_SIZE ? s.LIBRARY_FILE_SIZE : 0);
                            })
                            delete subitem.recipe_items;
                            categorys[i].subCategory[j].recipe_items = recipes ? recipes : [];
                        })
                    }
                });
                categorys && categorys.map((item, i) => {
                    if (item.subCategory) {
                        item.subCategory.map((subitem, j) => {
                            categorys.push(subitem);
                            subitem = null;
                        })
                    }
                });

                /*  
                    MCF New flow - Recipe Creation
                    To display cookbook and associated recipes
                    get recipe ids and append with post data
                    jsonTemplateObj.recipes.map(id=> id.ID)
                */
                let recipeItemIds = jsonTemplateObj?.recipes?.map(id=> id.ID) || [];
                console.log(`recipeItemIds-submitCookbookAddLibrary()-${httpMethod}`, recipeItemIds);
                
                let createdRecipeItemId = JSON.parse(localStorage.getItem("newCreatedRecipeId"));
                createdRecipeItemId = createdRecipeItemId?.filter(element => element !== null) || [];
                let libraryPostData = {
                    "customerId": CUSTOMER_ID,
                    "brandId": BRAND_ID_VALUE[brandName],
                    "modelFamilyIds": localStorage.getItem("selectedFamilyID"),
                    "libraryId": libraryId,
                    "libraryName": libraryDetails.cookbookName,
                    "libraryFileName": libraryId + ".json",
                    "libraryFileFormat": "json",
                    "libraryFileSize": (fileSize + imageSize ? fileSize + imageSize : 0).toFixed(2),
                    "libraryS3Url": "",
                    "type": "CookBook",
                    "librarySettingsId": localStorage.getItem('librarySettingsId') ? localStorage.getItem('librarySettingsId') : '',
                    "createdUser": response.data.name + ' (' + loggedinUser + ')',
                    "crc": crc,
                    "baseCountry": BASECOUNTRY_ID,
                    "imageFileName": "",
                    "imageFileFormat": "",
                    "imageFileSize": imageSize ? (imageSize).toFixed(2) : "",
                    "imageS3Url": "",
                    "cgids": _customId,
                    "json": JSON.stringify(jsonTemplateObj),
                    "categories": categorys ? categorys : [],
                    "libraryGuid": jsonTemplateObj.id,
                    "mappingRecipesIds": recipeItemIds, // (ONLY ADDED RECIPE - ALL Recipes list)
                    "createdRecipesIds": createdRecipeItemId?.length ? createdRecipeItemId : [] // recipeIds (created recipe not added in all recipes)
                };
                if (libraryDetails.isEdit) {
                    delete libraryPostData.customerId;
                    delete libraryPostData.createdUser;
                    libraryPostData.updatedUser = response.data.name + ' (' + loggedinUser + ')';
                }
                let headerpresigned = {
                    'Content-Type': 'application/json',
                    'customerid': CUSTOMER_ID,
                    'imagefilekey': "",
                    'jsonfilekey': encodeURIComponent(libraryId) + '.json',
                    'audiofilekey': "",
                    'isthumbnail': false,
                    'encryption' : "true"
                }
                uploadLibraryToCloud(MenuUploadAPI.getlibraryUploadpresignedUrl, httpMethod, headerpresigned, libraryPostData, null, null, jsonBuffer, false).then(response => {
                    _this.setState({
                        loaderIconVisible: false,
                        success_modal: {
                            open: true,
                            message: (httpMethod === "PUT" && libraryDetails.initialMethod === "Edit") ? "KC1263" : "KC1264"
                        },
                    });
                }).catch(err => {
                    _this.showError(err);
                });
            }).catch(err => {
                _this.setState({
                    loaderIconVisible: false
                })
                console.log('catch exception occured');
            });
        }
    }
    //Settings submit
    submitSettingAddLibrary = async (_customId) => {
        let _this = this;
        let httpMethod;
        _this.setState({
            loaderIconVisible: true
        })
        let brandName = localStorage.getItem("brandName");
        let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
        let loggedinUser = loginUser.username;
        http({
            method: "GET",
            url: UserProfile.getUserProfileDetails,
            headers: {
                'customer_id': CUSTOMER_ID,
                'email': loggedinUser,
                'Content-Type': 'application/json',
                'base_country': localStorage.getItem("basecountry"),
                'emulation': localStorage.getItem('emulation') ? 'true' : 'false'
            }, data: {}
        }).then(response => {
            let libraryId;
            let libraryDetails = JSON.parse(localStorage.getItem("libraryCookbookDetails"));
			// ** Need to include this block post validation - for already existing items
            // ** if (libraryDetails.isEdit && libraryDetails.settingsID) {
            if (libraryDetails.isEdit) {
                httpMethod = "PUT";
                libraryId = localStorage.getItem('librarySettingsId') ? localStorage.getItem('librarySettingsId') : '';
            } else {
                httpMethod = "POST";
                // libraryId = libraryDetails.settingName + '_' + Date.now();
                // libraryId = libraryId.replace(/\s+/g, '');
                libraryId = nanoid() + '_' + Date.now();
            }
            // libraryId = encodeURIComponent(libraryId);
            
            let libraryData = localStorage.getItem("librarySettingsData");
            let libraryDataObj = JSON.parse(libraryData);
            libraryDataObj.id = libraryDetails.isEdit ? libraryDetails.id : uuidv1();
            libraryDataObj.edit_date = todaysDateUTCFormatted;
            libraryDataObj.author = response.data.name;
            libraryDataObj.email = loginUser.username;
            delete libraryDataObj.Import;
            libraryDataObj.scheduler && libraryDataObj.scheduler.map((parent, i) => {
                parent.configurations.map((item, j) => {
                    delete item.id;
                })
            })
            if (libraryDataObj.scheduler && libraryDataObj.scheduler.length === 0)  {
                delete libraryDataObj.scheduler;
            }
            if (libraryDataObj.preheat_list && libraryDataObj.preheat_list.length === 0)  {
                delete libraryDataObj.preheat_list;
            }
            if (libraryDataObj.preheat_times && libraryDataObj.preheat_times.length === 0)  {
                delete libraryDataObj.preheat_times;
            }
            // convert JSON object to beautify String
            var jsonStr = beautify(libraryDataObj, null, 2, 80)
            // read json string to Buffer
            const jsonBuffer = Buffer.from(jsonStr);
            let fileSize = Buffer.byteLength(jsonBuffer);
            fileSize = (fileSize / 1048576);
            let settings = libraryDataObj.sounds;
            settings && settings.map((item, i) => {
                fileSize = fileSize + parseFloat(item.fileSize ? item.fileSize : 0);
            })
            
            let md5 = require('md5');
            let crc = md5(jsonBuffer, "base64");
            let imagefile = settings && settings.find(a => a.fileType);
            let convertFloat;
            if(imagefile !== undefined){
                convertFloat = parseFloat(imagefile.fileSize ? imagefile.fileSize : 0);
            }
            let libraryPostData = {
                "customerId": CUSTOMER_ID,
                "brandId": BRAND_ID_VALUE[brandName],
                "modelFamilyIds": localStorage.getItem("selectedFamilyID"),
                "libraryId": libraryId,
                "libraryName": libraryDetails.settingName,
                "libraryFileName": libraryId + ".json",
                "libraryFileFormat": "json",
                "libraryFileSize": fileSize && convertFloat ? (fileSize + convertFloat).toFixed(2) : 0,
                "libraryS3Url": "",
                "type": "Settings",
                "createdUser": response.data.name + ' (' + loggedinUser + ')',
                "crc": crc,
                "baseCountry": BASECOUNTRY_ID,
                "imageFileName": "",
                "imageFileFormat": "",
                "imageFileSize": imagefile ? imagefile.fileSize ? imagefile.fileSize : 0 : 0,
                "imageS3Url": "",
                "cgids": _customId,
                "json": JSON.stringify(libraryDataObj),
                "settings": settings ? settings : [],
                "libraryGuid": libraryDataObj.id
            };
            if (libraryDetails.isEdit) {
                delete libraryPostData.customerId;
                delete libraryPostData.createdUser;
                libraryPostData.updatedUser = response && response.data && response.data.name + ' (' + loggedinUser + ')';
            }
            let headerpresigned = {
                'Content-Type': 'application/json',
                'customerid': CUSTOMER_ID,
                'imagefilekey': "",
                'jsonfilekey': jsonBuffer ?encodeURIComponent(libraryId) + '.json' : "",
                'audiofilekey': '',
                'isthumbnail': false,
                'encryption' : "true"
            }
            uploadLibraryToCloud(MenuUploadAPI.getlibraryUploadpresignedUrl, httpMethod, headerpresigned, libraryPostData, null, null, jsonBuffer, false).then(response => {
                let parentCustGrpId = localStorage.getItem("parentCustGroupId") || localStorage.getItem("custGroupID");
                console.log("Category Cookbook parentCustGrpId==>",parentCustGrpId);
                let cgid = [];
                cgid.push(parentCustGrpId);
                this.submitCookbookAddLibrary(cgid);
            }).catch(err => {
                _this.showError(err);
            });
        }).catch(err => {
            _this.setState({
                loaderIconVisible: false
            })
        });
    }
    showError = (err) => {
        let { response } = err;
        if (response) {
            let { data } = response;
            if (response && response.status === 400) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: data.KC_ERR_400 ? data.KC_ERR_400 : ""
                    }
                });
            } else if (response && response.status === 500) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: data.KC_ERR_500 ? data.KC_ERR_500 : ""
                    }
                });
            }
        } else {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1083"
                }
            });
        }
    }

    render() {
        let { loaderIconVisible, categoryList, deleteCategoryVisible, isChanged, showDiscardPopup, success_modal, error_modal, isDeleteCategory, cookbook_name, errorCookbookName, isEdit, isImport, selectedModel } = this.state;
        categoryList = _.orderBy(categoryList);
        let brandid = localStorage.getItem("selectedbrandID");
        let loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
        const { formatMessage } = this.props.intl;
        let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
		const that = this;
        const dragProps = {
            onDragEnd(fromIndex, toIndex) {
                const data = [...that.state.categoryList];
                const item = data.splice(fromIndex, 1)[0];
                data.splice(toIndex, 0, item);
                that.setState({ categoryList: data });

                localStorage.setItem("resourceDetails", JSON.stringify(data));
            },
            nodeSelector: 'li',
            handleSelector: 'a',
            lineClassName: 'global-drag-line',
        };

        return (
            <React.Fragment>
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: "KC0658"})}><FormattedMessage id='KC0658' /></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button type="button" className="btn btn-default-text menuDownload disabled" data-toggle="modal" data-target="#selectRecipeModal">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                        <div className="pageScrollbar">
                        <div class="rcDeviceWrapper">
                            <div class="rcSelectedBrandLogo"><img src={require("../../../../../images/logos/merrychefW.png")} /></div>
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <FormattedMessage id="KC2009"/>
                                     <button type="button" title={formatMessage({ id: 'KC2426' })} class={(isChanged || isEdit || isImport) ? "btn btn-default-text editButton" : "displaynone"} onClick={this.onclickEditCookbook}>&nbsp;</button>
                                     <Link to={{pathname: '/settingsEditor', state: { prevPath: '/cookbookEditor' }}} title={formatMessage({ id: 'KC1190' })} class="btn rcSettings">&nbsp;</Link>
                                </div>
                                <div class="reBodyWrapper RCScrollbar">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn alignTop">
                                                    <div className="cbdnd">
                                                        <div className="rcListD actionIcon">
                                                            <Link to="#" title={formatMessage({ id: 'KC1242' })} onClick={this.addCategory}>
                                                                <span class="navIcon"><img src={require("../../../../../images/recipecreation/addButton.png")} /></span>
                                                                <span class="navTitle"><FormattedMessage id="KC1242" /></span>
                                                            </Link>
                                                        </div>
                                                        <div className="rcListD actionIcon">
                                                            <Link to="#" title={'Press & Go'} onClick={this.addPressandGo}>
                                                                <span class="navIcon"><img src={require("../../../../../images/recipecreation/pressandgo.svg").default} /></span>
                                                                <span class="navTitle">Press & Go</span>
                                                            </Link>
                                                        </div>
                                                        <div className="rcListD actionIcon">
                                                            <Link to="#" title={formatMessage({ id: 'KC2082' })} onClick={this.addAllRecipes}>
                                                                <span class="navIcon"><img src={require("../../../../../images/recipecreation/allRecipes.png")} /></span>
                                                                <span class="navTitle"><FormattedMessage id="KC2082" /></span>
                                                            </Link>
                                                        </div>
                                                        <ReactDragListView {...dragProps}>
                                                            {this.state.categoryList.map((item, index) => (
                                                                <li>
                                                                    <div className='rcListD'>
                                                                        <div class="cbcategoryBox">
                                                                            <button type="button" class={deleteCategoryVisible ? "btn cancelButton" : "displaynone"} onClick={(e) => this.onDeleteCategory(e, item)}>&nbsp;</button>
                                                                            <div class={deleteCategoryVisible ? "cbcategoryAction" : "displaynone"}>
                                                                                <button type="button" class={deleteCategoryVisible ? "btn cbEditButton" : "displaynone"} onClick={(e) => this.editCategory(e, item)}>&nbsp;</button>
                                                                            </div>
                                                                            <span class="cbCategoryicon"></span>
                                                                            <Link to="#" class="navIcon" onClick={(e) => this.onCategorySelect(e, item)}> <img src={item.thumbnailData ? `data:${'image/png'};base64,${item.thumbnailData}` : require("../../../../../images/recipecreation/photosPictures.png")} /></Link>
                                                                            <Link to="#" class="navTitle" onClick={(e) => this.onCategorySelect(e, item)}>{item.categoryName}</Link>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ReactDragListView>
                                                    </div>
                                                 {/* <DragDropContext onDragEnd={this.onDragEnd}>
                                                     <Droppable droppableId="droppable" direction="horizontal">
                                                         {(provided) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.droppableProps}
                                                                class="cbcategory menuEditActive"
                                                            >
                                                                <ul>
                                                                    <li class="actionIcon"> 
                                                                        <Link to="#" title={formatMessage({ id: 'KC1242' })} onClick={this.addCategory}>
                                                                            <span class="navIcon"><img src={require("../../../../../images/recipecreation/addButton.png")} /></span>
                                                                            <span class="navTitle"><FormattedMessage id="KC1242"/></span>
                                                                        </Link>
                                                                    </li> 
                                                                    <li class="actionIcon"> 
                                                                        <Link to="#" title={'Press & Go'} onClick={this.addPressandGo}>
                                                                            <span class="navIcon"><img src={require("../../../../../images/recipecreation/pressandgo.svg").default} /></span>
                                                                            <span class="navTitle">Press & Go</span>
                                                                        </Link>
                                                                    </li>
                                                                    <li class="actionIcon">
                                                                        <Link to="#" title={'All Recipes'} onClick={this.addAllRecipes}>
                                                                            <span class="navIcon"><img src={require("../../../../../images/recipecreation/allRecipes.png")} /></span>
                                                                            <span class="navTitle">All Recipes</span>
                                                                        </Link>
                                                                    </li>  
                                                                    {categoryList.map((item, index) => (
                                                                        <Draggable key={item.ID} draggableId={item.categoryName} index={index}>
                                                                            {(provided) => (
                                                                                <li key={item.ID} ref={provided.innerRef} {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}>
                                                                                    <div class="cbcategoryBox">
                                                                                        <button type="button" class={deleteCategoryVisible ? "btn cancelButton" : "displaynone"} onClick={(e) => this.onDeleteCategory(e, item)}>&nbsp;</button>
                                                                                        <div class={deleteCategoryVisible ? "cbcategoryAction" : "displaynone"}> 
                                                                                            <button type="button" class={deleteCategoryVisible ? "btn cbEditButton" : "displaynone"} onClick={(e) => this.editCategory(e, item)}>&nbsp;</button>
                                                                                        </div>
                                                                                        <span class="cbCategoryicon"></span>
                                                                                        <Link to="#" class="navIcon" onClick={(e) => this.onCategorySelect(e, item)}> <img src={item.thumbnailData ? `data:${'image/png'};base64,${item.thumbnailData}` : require("../../../../../images/recipecreation/photosPictures.png")} /></Link>
                                                                                        <Link to="#" class="navTitle" onClick={(e) => this.onCategorySelect(e, item)}>{item.categoryName}</Link>
                                                                                    </div>
                                                                                </li>
                                                                            )}
                                                                        </Draggable>
                                                                    ))}
                                                                    {provided.placeholder}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="recipeEditorF">
                                    <button type="button" onClick={() => { this.onBackButtonClick() }} className="btn backButton" title={formatMessage({ id: 'KC1214' })}>&nbsp;</button>
                                    <button type="button" onClick={() => { this.onSaveCookbookButtonClick() }} className={isChanged ? "btn saveButton" : "btn saveButton disabled"} title={formatMessage({ id: 'KC1243' })}>&nbsp;</button>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div className={isChanged && showDiscardPopup ? "modal fade confirmationDeleteModal show" : "modal fade"} style={isChanged && showDiscardPopup ? { display: 'block' } : { display: 'none' }} data-keyboard="true" data-backdrop="static" id="confirmationDeleteModal" tabindex="-1" role="dialog" aria-labelledby="confirmationDeleteModalLabel">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="deleteConfirmation">
                                    <FormattedMessage id="KC1218"/>
					            </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary deleteNoBtn" onClick={() => this.OnCancel()} data-dismiss="modal" type="button"><FormattedMessage id="KC0580"/></button>
                                <button className="btn btn-secondary deleteYesBtn" onClick={() => this.OnDiscard()} data-dismiss="modal" type="button"><FormattedMessage id='KC0611' /></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={isDeleteCategory ? "modal fade confirmationDeleteModal show" : "modal fade"} style={isDeleteCategory ? { display: 'block' } : { display: 'none' }} data-keyboard="true" data-backdrop="static" id="confirmationDeleteModal" tabindex="-1" role="dialog" aria-labelledby="confirmationDeleteModalLabel">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="deleteConfirmation">
                                    <FormattedMessage id="KC1245"/>
					            </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary deleteNoBtn" onClick={() => this.OnCancel()} data-dismiss="modal" type="button"><FormattedMessage id="KC0580"/></button>
                                <button className="btn btn-secondary deleteYesBtn" onClick={() => this.onDeleteConfirm()} data-dismiss="modal" type="button"><FormattedMessage id='KC0611' /></button>
                            </div>
                        </div>
                    </div>
                </div>
                <LibraryListPopup libraryType={'CookBook'} OnImportLibrary={(item) => this.OnImport(item)} modelID={selectedModel} />
                <div class="modal fade confirmationYesNoModal" data-keyboard="true" data-backdrop="static" id="saveNameModal" tabindex="-1" role="dialog" aria-labelledby="saveNameModalLabel">
                <div class="modal-dialog modal-md">
                    <div class="modal-content">  
                        <div class="modal-body"> 
                            <div class="confirmationYesNo"> 
                            <h5><FormattedMessage id='KC0686' /></h5>  
                            <div class="input-group"> 
                                <input class="form-control" type="text" id="cookBookText" maxLength="20" placeholder={formatMessage({ id: "KC0686"})} value={cookbook_name} onChange={(e) => this.onCookbookNameChange(e)} />
                                <div className="errorMsg">{errorCookbookName != '' ? <FormattedMessage id={errorCookbookName}/> : ''}</div>
                            </div>
                            </div> 
                        </div>
                        <div class="modal-footer textAlignCenter">
                        <button onClick={() => { this.OnCancel() }} class="btn btn-secondary noBtn" data-dismiss="modal" type="button"><FormattedMessage id='KC0021' /></button>
                        <button onClick={() => { this.onSaveButtonClick('Save') }} class="btn btn-secondary yesBtn" id="timeZoneAssign" type="button"><FormattedMessage id='KC0123' /></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade confirmationYesNoModal" data-keyboard="true" data-backdrop="static" id="testNameModal" tabindex="-1" role="dialog" aria-labelledby="saveNameModalLabel">
                <div class="modal-dialog modal-md">
                    <div class="modal-content">  
                        <div class="modal-body"> 
                            <div class="confirmationYesNo"> 
                            <h5><FormattedMessage id='KC0686' /></h5>  
                            <div class="input-group"> 
                                <input class="form-control" type="text" placeholder={formatMessage({ id: "KC0686"})} value={cookbook_name} onChange={(e) => this.onCookbookNameChange(e)} />
                                <div className="errorMsg">{errorCookbookName != '' ? <FormattedMessage id={errorCookbookName}/> : ''}</div>
                            </div>
                            </div> 
                        </div>
                        <div class="modal-footer textAlignCenter">
                            <button onClick={() => { this.OnCancel() }} class="btn btn-secondary noBtn" data-dismiss="modal" type="button"><FormattedMessage id='KC0021' /></button>
                            <button onClick={() => { this.onSaveButtonClick('Test') }} class="btn btn-secondary yesBtn" id="timeZoneAssign" type="button"><FormattedMessage id='KC0123' /></button>
                        </div>
                    </div>
                </div>
            </div>
            <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
            <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
            </React.Fragment>
        )
    }
}

export default injectIntl(CookbookEditor)
