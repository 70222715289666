import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import * as moment  from 'moment';
import $ from 'jquery';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import DragAndDrop from '../menus/DragAndDrop';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import DatePicker from 'react-datepicker';
import { BRAND_ID_VALUE, thirty_Mnts_Interval, MENU_EXTENSEIONS, MENU_ERRORS } from '../../../utils/appConstants';
import { injectIntl,FormattedMessage } from 'react-intl';
import { TimePeriodFormatter } from '../../languageDropdown/timePeriodFormatter';
import "react-datepicker/dist/react-datepicker.css";
import { datePickerLocale } from '../../../locale/constant';

var todaysDate = new Date();

$(function () {
	$('#selectTime').keypress(function (e) {
		var keyCode = e.which || e.keyCode;
		if (!(keyCode === 97 || keyCode === 109 || keyCode === 112 || keyCode === 48 || keyCode === 49
			|| keyCode === 50 || keyCode === 51 || keyCode === 52 || keyCode === 53 || keyCode === 54
			|| keyCode === 55 || keyCode === 56 || keyCode === 57 || keyCode === 58 || keyCode === 32)) {
			return false;
		}
	});
	
	$('#ScheduleDate').on('focus', function(e) { 
		e.preventDefault();
		$(this).blur();
	});
});

class menuScheduleUpdateNow extends Component {

	constructor(props) {
		super(props);
		const { formatMessage } = this.props.intl;
		this.state = {
			loaderIconVisible: false,
			Uploadedfile: {},
			files: {},
			error_modal: {
				open: false,
				message: ""
			},
			success_modal: {
				open: false,
				message: ""
			},
			UpdateNow: true,
			ScheduleUpdate: false,
			brandId: 0,
			startDate: "",
			Scheduledtime: "",
			maxdate: todaysDate,
			errormsg: formatMessage({ id: 'KC1163' })
		};
		this.UploadmenuFile = this.UploadmenuFile.bind(this);
		this.MercoMenuAssign = this.MercoMenuAssign.bind(this);
		this.closeModel = this.closeModel.bind(this);
		this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.ResetDatepicker = this.ResetDatepicker.bind(this);
	}

	componentDidMount() {
		document.body.style.backgroundColor = "#F2F2F2";
		$('div').removeClass("modal-backdrop fade show");
		$('.menuBrowse').click(function () { $('#menuUpdate').trigger('click'); });
		let Max_date = new Date(todaysDate);
		Max_date.setMonth(Max_date.getMonth() + 6)
		this.setState({
			maxdate: Max_date
		});

		$(".dropdownTimeOptions").hide();
		$("#selectTime").focus(function () {
			$(".dropdownTimeOptions").show();
		});
		var timeValue;
		$(document).click(function (e) {
			$(".dropdownTimeOptions").hide();
		});
		$('#timeSchedulePanel').click(function (e) {
			e.stopPropagation();
		});
		$(".dropdownTimeOptions > li > a").click(function (e) {
			e.preventDefault();
			timeValue = $(this).text();
			$("#selectTime").val(timeValue);
			$(".dropdownTimeOptions").hide();
		});
		$(".dropdownTimeOptions > li").click((e) => {
			e.preventDefault();
				$("#btnnext").addClass("btn-default-text activeState");
				$("#btnnext").removeClass("disabled");
		});
	}

	settimeselectonmenu = (e) => {
		if ($("#selectedfile").val() !== "" && $("#ScheduleDate").val() !== "" && $("#selectTime").val() !== "") {
			$("#btnnext").addClass("btn-default-text activeState");
			$("#btnnext").removeClass("disabled");
		}
	}

	setscheduleUpdate = (event) => {
		if (event.target.value === "ScheduleUpdate") {
			this.setState({
				ScheduleUpdate: true,
				UpdateNow: false
			});
			$("#Schedulediv").removeClass("displaynone");
			if ($("#selectedfile").val() !== "" && $("#ScheduleDate").val() !== "" && $("#selectTime").val() !== "") {
				$("#btnnext").addClass("btn-default-text activeState");
		    	$("#btnnext").removeClass("disabled");
			}
			else {
				$("#btnnext").removeClass("activeState");
		    	$("#btnnext").addClass("disabled");
			}
		}
		else if (event.target.value === "UpdateNow") {
			this.setState({
				ScheduleUpdate: false,
				UpdateNow: true
			});
			$("#Schedulediv").addClass("displaynone");
			if(this.state.files.length>0){
				$("#btnnext").addClass("btn-default-text activeState");
				$("#btnnext").removeClass("disabled");
			}
			else{
				$("#btnnext").removeClass("activeState");
			  	$("#btnnext").addClass("disabled");
			}
		}
	}

	handleChange = (date) => {
		this.setState({
			startDate: date
		});
		if (this.state.files.length > 0) {
			if (this.state.ScheduleUpdate && $("#selectTime").val() !== "") {
				$("#btnnext").addClass("btn-default-text activeState");
				$("#btnnext").removeClass("disabled");
			}
		}
	}

	onChangeSchedule = (e) => {
		let idx = e.target.selectedIndex;
		let Scheduledtime = e.target.options[idx].text;
		this.setState({ Scheduledtime: Scheduledtime });
	}

	closeModel() {
		this.setState({ error_modal: { open: false } })
	}

	onSuccessCloseModal = () => {
		this.setState({
			success_modal: {
				open: false
			}
		})
	}

	getBrandId = () => {
		let brandName = localStorage.getItem("brandName");
		return BRAND_ID_VALUE[brandName] || 0;
	}

	handleDrop = (files) => {
		let fileName = files[0].name;
		let fileext = fileName.substring(fileName.lastIndexOf('.')+1);
		fileext = fileext.toLowerCase();
		let brandId = 0;
		let fileList = [];
		let message = "";
		fileList.push(files[0])
		brandId = this.getBrandId();

		if (MENU_EXTENSEIONS[brandId].includes(fileext.toLowerCase())) {
			this.setState({
				files: fileList,
				brandId: brandId,
				errormsg: ""
			});
		} else {
			message = MENU_ERRORS[brandId];
		}

		if (message !== "") {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: message
				}
			});
		}
		else {
			if (fileList.length > 0) {
				if (this.state.UpdateNow) {
					$("#btnnext").addClass("btn-default-text activeState");
					$("#btnnext").removeClass("disabled");
				}
				else if (this.state.ScheduleUpdate && ($("#ScheduleDate").val() !== "" && $("#selectTime").val() !== "")) {
					$("#btnnext").addClass("btn-default-text activeState");
					$("#btnnext").removeClass("disabled");
				}
			}
		}
	}

	ResetDatepicker = (e) => {
		e.preventDefault();
		return false;
	}

	UploadmenuFile = (e) => {
		//let _this = this;
		let brandId;
		if (e.target.files[0] !== null) {

			this.setState({
				loaderIconVisible: true
			});

			let fileName = e.target.files[0].name;
			fileName = fileName.replace(/[^a-zA-Z 0-9\n\r.]+/g, '_');
			let fileext = fileName.substring(fileName.lastIndexOf('.')+1);
			fileext = fileext.toLowerCase();
			let fileList = [];
			fileList.push(e.target.files[0])

			let message = "";
			brandId = this.getBrandId();

			if (MENU_EXTENSEIONS[brandId].includes(fileext.toLowerCase())) {
				this.setState({
					files: fileList,
					brandId: brandId,
					errormsg: ""
				});
			} else {
				message = MENU_ERRORS[brandId];
			}

			if (message !== "") {
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: message
					}
				});
			}
			else if (fileList.length > 0) {
				if (this.state.UpdateNow) {
					$("#btnnext").addClass("btn-default-text activeState");
					$("#btnnext").removeClass("disabled");
				}
				else if (this.state.ScheduleUpdate && ($("#ScheduleDate").val() !== "" && $("#selectTime").val() !== "")) {
					$("#btnnext").addClass("btn-default-text activeState");
					$("#btnnext").removeClass("disabled");
				}
			}
		}
		this.setState({
			loaderIconVisible: false,
			brandId: brandId
		});

		$("#menuUpdate").val('');
	}

	MercoMenuAssign = (e) => {

		let brandId = this.getBrandId();
		this.setState({ loaderIconVisible: true });
		let menuScheduleDate = $("#ScheduleDate").val();
		//Checks for Valid Time.
		let selectTime = $("#selectTime").val();
		let checkvalidTime = selectTime.match(/^(0?[1-9]|1[012])(:[0-5]\d) [APap][mM]$/);
		//Checks for Valid Date.
		let rxDatePattern = /^(\d{1,2})(\/|-)(\d{1,2})(\/|-)(\d{4})$/;
		let dtArray = menuScheduleDate.match(rxDatePattern);
		let isValidDate = true;
		if (dtArray !== null) {
			//Checks for mm/dd/yyyy format.
			let dtMonth = dtArray[1];
			let dtDay = dtArray[3];
			let dtYear = dtArray[5];
			if (dtMonth < 1 || dtMonth > 12) {
				isValidDate = false;
			}
			else if (dtDay < 1 || dtDay > 31) {
				isValidDate = false;
			}
			else if ((dtMonth === 4 || dtMonth === 6 || dtMonth === 9 || dtMonth === 11) && dtDay === 31) {
				isValidDate = false;
			}
			else if (dtMonth === 2) {
				let isleap = (dtYear % 4 === 0 && (dtYear % 100 !== 0 || dtYear % 400 === 0));
				if (dtDay > 29 || (dtDay === 29 && !isleap)) {
					isValidDate = false;
				}
			}
		}

		//Checks for Date equal to greater than yesterday
		let TodayDate = new Date();
		TodayDate = TodayDate.getFullYear() + '-' + (TodayDate.getMonth() + 1) + '-' + TodayDate.getDate();
		TodayDate = new Date(TodayDate).getTime();

		let selecteddate = new Date(menuScheduleDate);
		selecteddate = selecteddate.getFullYear() + '-' + (selecteddate.getMonth() + 1) + '-' + selecteddate.getDate();
		selecteddate = new Date(selecteddate).getTime();
		// Merco vhc file selection
		if ((brandId === 2 || brandId === 11) && this.state.files.length === undefined) {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: "KC1133"
				}
			});
		} 
		// cbr file selection
		else if ((brandId === 1 || brandId === 5 || brandId === 7 || brandId === 8 || brandId === 10) && this.state.files.length === undefined) {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: "KC1132"
				}
			});
		}
		// Frymaster cbr or ini file selection
		else if (brandId === 3 && this.state.files.length === undefined) {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: "KC1131"
				}
			});
		}
		// Convotherm sdf or db file selection
		else if (brandId === 6 && this.state.files.length === undefined) {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: "KC1130"
				}
			});
		}
		// Garland db file selection
		else if (brandId === 9 && this.state.files.length === undefined) {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: "KC1129"
				}
			});
		}
		else if (this.state.ScheduleUpdate && (selectTime === "" || menuScheduleDate === "")) {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					startDate: todaysDate,
					message: "KC1125"
				}
			});
		}
		else if (this.state.ScheduleUpdate && !isValidDate) {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					startDate: todaysDate,
					message: "KC1128"
				}
			});
		}
		else if (this.state.ScheduleUpdate && !((TodayDate === selecteddate) || (TodayDate < selecteddate))) {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					startDate: todaysDate,
					message: "KC1127"
				}
			});
		}
		else if (this.state.ScheduleUpdate && !checkvalidTime) {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: "KC1126"
				}
			});
		}
		else {
			if (this.state.ScheduleUpdate) {
				// Current Time start
				let today = new Date();
				let hours = today.getHours();
				let minutes = today.getMinutes();
				let ampm = hours >= 12 ? 'PM' : 'AM';
				hours = hours % 12;
				hours = hours ? hours : 12;
				minutes = minutes < 10 ? '0' + minutes : minutes;
				let strTime = hours + ':' + minutes + ' ' + ampm;
				let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
				let Currentstart = date + ' ' + strTime;
				// Current Time End

				//selected Time start
				let selecteddatetime = this.state.startDate;
				let selecteddate = selecteddatetime.getFullYear() + '-' + (selecteddatetime.getMonth() + 1) + '-' + selecteddatetime.getDate();
				let selectedstart = selecteddate + ' ' + selectTime;
				//selected Time end

				//Six Month Date Validation start
				var pastfuturedate = new Date(menuScheduleDate);
				pastfuturedate = pastfuturedate.getFullYear() + '-' + (pastfuturedate.getMonth() + 1) + '-' + pastfuturedate.getDate();
				pastfuturedate = pastfuturedate + ' ' + selectTime;
				pastfuturedate = new Date(pastfuturedate).getTime();

				let max_sixmont = this.state.maxdate;
				max_sixmont = max_sixmont.getFullYear() + '-' + (max_sixmont.getMonth() + 1) + '-' + max_sixmont.getDate();
				max_sixmont = max_sixmont + ' ' + selectTime;
				max_sixmont = new Date(max_sixmont).getTime();
				//Six Month Date Validation end

				let Currenttime = new Date(Currentstart).getTime();
				let selectedtime = new Date(selectedstart).getTime();

				if (this.state.ScheduleUpdate && ((pastfuturedate > max_sixmont) || (pastfuturedate < Currenttime))) {
					this.setState({
						loaderIconVisible: false,
						error_modal: {
							open: true,
							startDate: todaysDate,
							message: "KC1125"
						}
					});
				}
				else if (this.state.ScheduleUpdate && ((Currenttime > selectedtime) || (Currenttime === selectedtime))) {
					this.setState({
						loaderIconVisible: false,
						error_modal: {
							open: true,
							message: `KC1124`
						}
					});
				}
				else if (this.state.files.length > 0) {
					let scheduleDateTimeUtcFormat;
					if (this.state.ScheduleUpdate) {
						let Scheduledtime = selectTime;
						let sceduleDatewithoutString = new Date(this.state.startDate);
							const formatDate = moment(sceduleDatewithoutString, 'L').format('YYYY-MM-DD');
							const  concatDateTime = `${formatDate} ${Scheduledtime}`;
							scheduleDateTimeUtcFormat = moment(concatDateTime, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DD HH:mm');
					}
					this.props.history.push({
						pathname: "/menuAssignUpdateList",
						srcfile: this.state.files,
						scheduleDateTimeUtc: scheduleDateTimeUtcFormat,
						Type: this.state.ScheduleUpdate,
						previouspath: "/menuScheduleUpdateNow"
					});
				}
			}
			else {
				this.props.history.push({
					pathname: "/menuAssignUpdateList",
					srcfile: this.state.files,
					Type: this.state.ScheduleUpdate,
					previouspath: "/menuScheduleUpdateNow"
				});
			}

		}
		this.setState({ loaderIconVisible: false });
	}


	render() {

		const { loaderIconVisible, errormsg } = this.state;
		let { success_modal, error_modal } = this.state;
		const { formatMessage } = this.props.intl;
		return (

			<div id="adminWrapper">

				<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
					<div className="loadingProgressIn"></div>
				</div>

				<div id="page-content-wrapper">

					<div class="softwareUpdateWrapper">
						<div class="adminBgHeader">
							<ul>
								<li>
									<Link to={localStorage.getItem("brandName") === "GARLAND" ? "/libraryList" : "/menuBrands"} title={formatMessage({ id: 'KC0989' })} class="backIcon">&nbsp;</Link>
								</li>
								<li>
									<FormattedMessage id="KC0665"/>
				            	</li>
								<li>
									<button id="btnnext" className="disabled border0" onClick={(e) => this.MercoMenuAssign(e)} title={formatMessage({ id: 'KC0543' })}><FormattedMessage id="KC0543"/></button>
								</li>
							</ul>
						</div>

						<div class="scheduleUpdateWrapper">
							<div class="scheduleUpdateWrapperL">								
								<DragAndDrop handleDrop={this.handleDrop} errormsg={errormsg ? formatMessage({ id: 'KC1163' }) : ""} >
									{this.state.files.length > 0 && this.state.files.map((file, i) =>
										<div id="selectedfile" key={i}>{file.name}</div>
									)}

									<span class="menuDrogDroporBrowse"><FormattedMessage id="KC2061"/></span>
									<button id="btnBrowse" class="menuBrowse border0" title=""><FormattedMessage id="KC0664"/></button>
									<input id="menuUpdate" type="file" className="menuUpdateFile" onChange={(e) => this.UploadmenuFile(e)} />
								</DragAndDrop>
							</div>
							<div class="scheduleUpdateWrapperR">
								<div class="scheduleUpdateOption" onChange={this.setscheduleUpdate.bind(this)}>
									<div class="customRadioBtn">
										<input id="UpdateNow" checked={this.state.UpdateNow} type="radio" name="ScheduleUpdateOption" value="UpdateNow" />
										<label for="UpdateNow"><FormattedMessage id="KC0666"/></label>
									</div>
									<div class="customRadioBtn">
										<input id="ScheduleUpdate" checked={this.state.ScheduleUpdate} type="radio" name="ScheduleUpdateOption" value="ScheduleUpdate" />
										<label for="ScheduleUpdate"><FormattedMessage id="KC0665"/></label>
									</div>
								</div>
								<div id="Schedulediv" class="scheduleUpdateForm displaynone">
									<ul>
										<li>
											<label><FormattedMessage id="KC0749"/></label>
											<div class="input-group">
												<DatePicker locale={datePickerLocale} disabledKeyboardNavigation id="ScheduleDate" onKeyDown={(e) => this.ResetDatepicker(e)} autoComplete="off" showMonthDropdown showYearDropdown dropdownMode="select" selected={this.state.startDate} placeholderText={formatMessage({ id: 'KC0835' })} onChange={this.handleChange} minDate={new Date()} maxDate={this.state.maxdate} />
											</div>
										</li>
										<li>
											<label><FormattedMessage id="KC0750"/></label>
											<div class="input-group" id="timeSchedulePanel" onChange={(e) => this.settimeselectonmenu(e)}>
												<input type="text" autoComplete="off" placeholder={formatMessage({ id: 'KC0836' })} name="selectTime" id="selectTime" maxlength="8" onkeydown="myFunction(event)" />
												<ul class="dropdownTimeOptions">
													{thirty_Mnts_Interval.map((value) =>
														<li><a href="javascript:void(0)" data-value={value}><TimePeriodFormatter text={value}/></a></li>
													)}
												</ul>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
				<SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
				<UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
			</div>
		)
	}
};
export default injectIntl(menuScheduleUpdateNow);
