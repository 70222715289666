import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import ReactDragListView from "react-drag-listview";
import { injectIntl, FormattedMessage } from 'react-intl';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { ModelFamilyList } from '../../../../controls/modelFamilyList';
import recipeJSON from '../../Templates/RecipeJSON';

require('jquery-mousewheel')
const LZString = require('lz-string');

class MenuRCPressAndGo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(localStorage.getItem("customerId")),
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            selectedModel: 0,
            selectedCategoryName: "Press & Go",
            recipeList: []
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.redirect = this.redirect.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.reciperedirect = this.reciperedirect.bind(this);
    }

    componentDidMount = async () => {
        window.addEventListener('resize', this.updateDimensions());
        document.body.style.backgroundColor = "#000000";
        try {
            let recipeDetails = '';
            let categoryDetails = localStorage.getItem("resourceDetails");
            let categoryDetailsObj = JSON.parse(categoryDetails);
            recipeDetails = categoryDetailsObj.filter(c => c.ID === 'Press&Go');
            if (recipeDetails && recipeDetails[0].recipe_items) {
                recipeDetails = recipeDetails[0].recipe_items.filter(item => item.length == undefined);
            } else recipeDetails = []
            const uniqueIds = [];
            const uniqueItems = recipeDetails.filter(element => {
                const isDuplicate = uniqueIds.includes(element.ID);
                if (!isDuplicate) {
                    uniqueIds.push(element.ID);
                    return true;
                }
                return false;
            });
            this.setState({
                recipeList: uniqueItems
            });
        } catch (error) {
            //Error Goes Here
        }
    }

    updateDimensions = () => {
        const windowHeight = $(window).height();
        const $tableBody = $('.pageScrollbar');
        $tableBody.css('height', windowHeight - ($tableBody.offset().top));
    }

    componentDidUpdate = () => {
        this.updateDimensions();
    }

    redirect = () => {
        this.props.history.push({
            pathname: "/recipeCreation",
            isEdit: false,
            previouspath: "/recipeCreation"
        });
    }

    onBackButtonClick = () => {
        const categoryDetails = LZString.decompress(localStorage.getItem("allRecipeDetails"));
        const recipeDetailsFromARD = categoryDetails ? JSON.parse(categoryDetails) : [];
        const resourceDetails = JSON.parse(localStorage.getItem("resourceDetails"));

        const allRecipesFromRD = resourceDetails.reduce((acc, obj) => {
            if (obj.recipe_items) {
                acc.push(...obj.recipe_items);
            }
            if (obj.subCategory) {
                obj.subCategory.forEach(obj2 => {
                    if (obj2.recipe_items) {
                        acc.push(...obj2.recipe_items);
                    }
                });
            }
            return acc;
        }, []);

        const uniqueRecipesFromRD = [...new Map(allRecipesFromRD.map(item => [item.ID, item])).values()];

        const allRecipeTypeItems = recipeDetailsFromARD.filter(obj => obj?.allRecipeType === 'allrecipe');

        const combinedRecipes = [...uniqueRecipesFromRD, ...allRecipeTypeItems];

        const uniqueItems = [...new Map(combinedRecipes.map(item => [item.ID, item])).values()];

        localStorage.setItem("allRecipeDetails", LZString.compress(JSON.stringify(uniqueItems)));

        this.props.history.push({
            pathname: "/cookbookEditor",
            isEdit: false,
            previouspath: "/menurcPressAndGo"
        });
    }

    addSubCategory = () => {
        this.props.history.push({
            pathname: "/cookbookPressAndGo",
            isEdit: false,
            previouspath: "/menurcPressAndGo"
        });
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    reciperedirect = (e, type) => {
        localStorage.setItem("selectedGroupID", 0);
        let libraryDetails = {}
        libraryDetails.isEdit = false;
        libraryDetails.initialMethod = "Add";
        libraryDetails.recipeType = type;
        localStorage.setItem("libraryImage", "");
        localStorage.setItem("libraryData", "{}");
        localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));
        localStorage.setItem("defaultLibraryData", JSON.stringify(recipeJSON.default));
        localStorage.setItem("RecipesListPage", "/menurcPressAndGo");
        this.props.history.push({
            pathname: "/recipeCreation",
            isEdit: false,
            previouspath: "/menurcPressAndGo"
        });
    }

    render() {
        let { recipeList, selectedCategoryName } = this.state;
        recipeList = recipeList && recipeList.length > 0 ? recipeList : [];
        let brandid = localStorage.getItem("selectedbrandID");
        const { formatMessage } = this.props.intl;
		const that = this;
        const dragProps = {
            onDragEnd(fromIndex, toIndex) {
                const data = [...that.state.recipeList];
                const item = data.splice(fromIndex, 1)[0];
                data.splice(toIndex, 0, item);
                that.setState({ recipeList: data });

                let categoryDetails = localStorage.getItem("resourceDetails");
                let categoryDetailsObj = JSON.parse(categoryDetails);
                let index = categoryDetailsObj.findIndex(item => item.ID === "Press&Go");
                categoryDetailsObj[index].recipe_items = data;

                localStorage.setItem("resourceDetails", JSON.stringify(categoryDetailsObj));
            },
            nodeSelector: 'li',
            handleSelector: 'div .cbcategoryBox',
            lineClassName: 'global-drag-line',
        };

        return (
            <React.Fragment>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: "KC0658" })}><FormattedMessage id='KC0658' /></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180" /></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                        <div className="pageScrollbar">
                            <div class="rcDeviceWrapper">
                                <div class="rcSelectedBrandLogo"><img src={require("../../../../../images/logos/merrychefW.png")} alt="" /></div>
                                <div class="recipeEditorWrapper">
                                    <div class="recipeEditorH selectedH">
                                        {selectedCategoryName}
                                    </div>
                                    <div class="reBodyWrapper RCScrollbar">
                                        <div class="reWrapperTable">
                                            <div class="reWrapperRow">
                                                <div class="reWrapperColmn alignTop">
                                                    <div className="cbdnd">
                                                        <div className="rcListD actionIcon">
                                                            <Link to={{ pathname: `/cookbookPressAndGo`, state: `menurcPressAndGo` }} title={formatMessage({ id: 'KC2425' })} onClick={this.addSubCategory}>
                                                                <span class="navIcon"><img src={require("../../../../../images/recipecreation/addButton.png")} /></span>
                                                                <span class="navTitle"><FormattedMessage id="KC2425" /></span>
                                                            </Link>
                                                        </div>
                                                        <ReactDragListView {...dragProps}>
                                                            {this.state.recipeList.map((item, index) => (
                                                                <li>
                                                                    <div className='rcListD'>
                                                                        <div class="cbcategoryBox">
                                                                            <span class="cbRCicon"></span>
                                                                            <span class="navIcon"><img src={item.IMAGE_S3_SIGNED_URL ? item.IMAGE_S3_SIGNED_URL : require("../../../../../images/recipecreation/photosPictures.png")} alt="" /></span>
                                                                            <span class="navTitle">{item.LIBRARY_NAME}</span>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ReactDragListView>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="recipeEditorF">
                                        <a href="#" class="btn rcChefHat" onClick={(e) => { this.reciperedirect(e, 'Recipe') }} title={formatMessage({ id: "KC1211" })}>&nbsp;</a>
                                        <button type="button" onClick={() => { this.onBackButtonClick() }} className={recipeList.length > 0 ? "btn saveCButton" : "btn saveCButton"} title={formatMessage({ id: 'KC1252' })}>&nbsp;</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
            </React.Fragment >
        )
    }
}

export default injectIntl(MenuRCPressAndGo);
