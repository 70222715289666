import commonFunctions from './common.function';
import mercoFunctions from './merco.function';
import xpresschfFunctions from './xpresschef.function';
import iomFunctions from './iom.function';

const clientFunctions = {
    COMMON: commonFunctions,
    MERCO: mercoFunctions,
    XPRESSCHEF: xpresschfFunctions,
    IOM: iomFunctions
};

export default clientFunctions;
